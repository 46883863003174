import React, { useCallback, useContext, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  IconButton,
  Tooltip,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import moment from "moment";
import API from "API/API";
import { AppContext } from "Context/AppContext";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import PromptModal from "components/promptModal/PromptModal";

const WarehouseGrid = (props) => {
  const { userData } = useContext(AppContext);
  const [tableData, setTableData] = useState(props.warehouseList);
  const [vehicles, setVehicles] = useState(props.vehicleList);
  const [items, setItems] = useState(props.itemList);
  const [deleteUserDetails, setDeleteUserDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [dataToEdit, setDataToEdit] = useState({});
  const [successModal, setSuccessModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [showError, setShowError] = useState(false);

  const handleSaveRowEdits = async () => {
    handleEditDialogClose();
    try {
      const res = await API().UpdateWarehouse(
        dataToEdit.WAREACTIVITY_ID,
        dataToEdit.WAREHOUSE_STATUS,
        parseInt(dataToEdit.SERIALFROM),
        parseInt(dataToEdit.SERIALTO),
        dataToEdit.FK_ITEMID,
        parseInt(dataToEdit.ITEM_QUANTITY),
        dataToEdit.DESCRIPTION,
        dataToEdit.FK_VEHICLEID,
        dataToEdit.ISSUED_DATE,
        dataToEdit.RECIEVED_DATE,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      if (res.data.status) {
        setFeedbackMessage("Entry Updated");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error While Updating Data");
        setSuccessModal(true);
      }
    } catch (err) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const editConfirmation = ({ exitEditingMode }) => {
    setEditOpen(true);
  };

  const handleCancelRowEdits = () => {};

  const handleDeleteRow = async (row) => {
    try {
      const res = await API().DeleteWarehouse(
        row.DESCRIPTION,
        row.FK_ITEMID,
        row.FK_VEHICLEID,
        row.WAREACTIVITY_ID,
        row.ISSUED_DATE,
        row.ITEM_QUANTITY,
        row.RECIEVED_DATE,
        row.SERIALFROM,
        row.SERIALTO,
        row.WAREHOUSE_STATUS,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      handleClose();
      if (res.data.status) {
        setFeedbackMessage("Item Deleted");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error While Deleting Data");
        setSuccessModal(true);
      }
      handleClose();
    } catch (err) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const inputCustomStyle = {
    "& .MuiInputBase-root": {
      "&:after": {
        borderBottom: "2px solid #24388a",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
  };
  const inputCustomStyleHidden = {
    ".MuiFormControl-root": {
      display: "none",
    },
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditDialogClose = () => {
    setEditOpen(false);
  };

  const handleEditDataChange = (name, value) => {
    setDataToEdit({ ...dataToEdit, [name]: value });
  };

  const columns = useMemo(() => [
    {
      accessorKey: "FK_WAREHOUSEID",
      header: "Warehouse ID",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="FK_WAREHOUSEID"
          sx={inputCustomStyleHidden}
          fullWidth
          name="FK_WAREHOUSEID"
          autoFocus
          label="FK_WAREHOUSEID"
          value={dataToEdit.FK_WAREHOUSEID}
          variant="standard"
        />
      ),
    },
    {
      accessorKey: "WAREHOUSE_STATUS",
      header: "Vehicle Status",
      size: 80,
      Edit: ({ cell }) => (
        <FormControl
          fullWidth
          variant="standard"
          size="small"
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#1b3bbb",
              },
              "& fieldset": {
                borderColor: "#1b3bbb",
              },
              "&:hover fieldset": {
                borderColor: "#1b3bbb",
              },
            },
            "& label.Mui-focused": {
              color: "#24388a",
            },
            background: "#fff",
          }}
        >
          <InputLabel id="warehouseStatus">Status</InputLabel>
          <Select
            labelId="warehouseStatus"
            id="warehouseStatus"
            name="WAREHOUSE_STATUS"
            value={dataToEdit.WAREHOUSE_STATUS}
            onChange={(e) =>
              handleEditDataChange(e.target.name, e.target.value)
            }
            fullWidth
            label="Status"
            inputProps={{
              "aria-label": "Without label",
            }}
            displayEmpty
            size="small"
          >
            <MenuItem value="in">In</MenuItem>
            <MenuItem value="out">Out</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      accessorKey: "SERIALFROM",
      header: "Serial From",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="SERIALFROM"
          sx={inputCustomStyle}
          fullWidth
          name="SERIALFROM"
          autoFocus
          label="SERIALFROM"
          value={dataToEdit.SERIALFROM}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      accessorKey: "SERIALTO",
      header: "Serial To",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="SERIALTO"
          sx={inputCustomStyle}
          fullWidth
          name="SERIALTO"
          autoFocus
          label="SERIALTO"
          value={dataToEdit.SERIALTO}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      accessorKey: "FK_ITEMID",
      header: "Item ID",
      size: 80,
      Edit: ({ cell }) => (
        <FormControl
          fullWidth
          variant="standard"
          size="small"
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#1b3bbb",
              },
              "& fieldset": {
                borderColor: "#1b3bbb",
              },
              "&:hover fieldset": {
                borderColor: "#1b3bbb",
              },
            },
            "& label.Mui-focused": {
              color: "#24388a",
            },
            background: "#fff",
          }}
        >
          <InputLabel id="FK_ITEMID">Item ID</InputLabel>
          <Select
            labelId="FK_ITEMID"
            id="FK_ITEMID"
            name="FK_ITEMID"
            value={dataToEdit.FK_ITEMID}
            onChange={(e) => {
              handleEditDataChange(e.target.name, e.target.value);
            }}
            fullWidth
            label="Status"
            inputProps={{
              "aria-label": "Without label",
            }}
            displayEmpty
            size="small"
          >
            {items.map((item, index) => (
              <MenuItem key={index} value={item.ITEMID}>
                {item.ITEMID}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
    },
    {
      accessorKey: "ITEM_QUANTITY",
      header: "Item Quantity",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="ITEM_QUANTITY"
          sx={inputCustomStyle}
          fullWidth
          name="ITEM_QUANTITY"
          autoFocus
          label="ITEM_QUANTITY"
          value={dataToEdit.ITEM_QUANTITY}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      accessorKey: "DESCRIPTION",
      header: "Description",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="DESCRIPTION"
          sx={inputCustomStyle}
          fullWidth
          name="DESCRIPTION"
          autoFocus
          label="DESCRIPTION"
          value={dataToEdit.DESCRIPTION}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      accessorFn: (row) =>
        vehicles.filter((elem) => elem.ID == row.FK_VEHICLEID)[0]
          .REGISTRATION_NO,
      accessorKey: "FK_VEHICLEID",
      header: "Vehicle",
      size: 80,
      Edit: ({ cell }) => (
        <FormControl
          fullWidth
          variant="standard"
          size="small"
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#1b3bbb",
              },
              "& fieldset": {
                borderColor: "#1b3bbb",
              },
              "&:hover fieldset": {
                borderColor: "#1b3bbb",
              },
            },
            "& label.Mui-focused": {
              color: "#24388a",
            },
            background: "#fff",
          }}
        >
          <InputLabel id="FK_VEHICLEID">Vehicle</InputLabel>
          <Select
            labelId="FK_VEHICLEID"
            id="FK_VEHICLEID"
            name="FK_VEHICLEID"
            value={dataToEdit.FK_VEHICLEID}
            onChange={(e) =>
              handleEditDataChange(e.target.name, e.target.value)
            }
            fullWidth
            label="Vehicle"
            inputProps={{
              "aria-label": "Without label",
            }}
            size="small"
          >
            {vehicles.map((item, index) => (
              <MenuItem key={index} value={item.ID}>
                {item.REGISTRATION_NO}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
    },
    {
      accessorFn: (row) => moment(row.ISSUED_DATE).format("DD-MM-YYYY"),
      accessorKey: "ISSUED_DATE",
      header: "Issued Date",
      size: 80,
      Edit: ({ cell }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            format="DD-MM-YYYY"
            label="issuedAt"
            value={moment(dataToEdit.issuedAt)}
            onChange={(value) => handleEditDataChange("issuedAt", value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#1b3bbb",
                },
                "& fieldset": {
                  borderColor: "#1b3bbb",
                },
                "&:hover fieldset": {
                  borderColor: "#1b3bbb",
                },
              },
              "& label.Mui-focused": {
                color: "#24388a",
              },
              background: "#fff",
            }}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: true,
                variant: "standard",
                sx: inputCustomStyle,
              },
            }}
          />
        </LocalizationProvider>
      ),
    },
    {
      accessorFn: (row) => moment(row.RECIEVED_DATE).format("DD-MM-YYYY"),
      accessorKey: "RECIEVED_DATE",
      header: "Recieved Date",
      size: 80,
      Edit: ({ cell }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            format="DD-MM-YYYY"
            label="receivedAt"
            name="receivedAt"
            value={moment(dataToEdit.recievedAt)}
            onChange={(value) => handleEditDataChange("recievedAt", value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#1b3bbb",
                },
                "& fieldset": {
                  borderColor: "#1b3bbb",
                },
                "&:hover fieldset": {
                  borderColor: "#1b3bbb",
                },
              },
              "& label.Mui-focused": {
                color: "#24388a",
              },
              background: "#fff",
            }}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: true,
                variant: "standard",
                sx: inputCustomStyle,
              },
            }}
          />
        </LocalizationProvider>
      ),
    },
  ]);

  return (
    <>
      <PromptModal
        ModalState={successModal}
        feedbackMessage={feedbackMessage}
        onClick={() => {
          setSuccessModal(false);
          props.callWarehouseDB();
        }}
      />
      {/* DELETE DIALOG */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Remove this Entry
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteRow(deleteUserDetails);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* UPDATE DIALOG */}
      <Dialog
        open={editOpen}
        onClose={handleEditDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Save/Update?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Update this Entry
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleEditDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSaveRowEdits(dataToEdit);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <MaterialReactTable
        muiTableBodyRowProps={{ sx: { background: "#EBECF0" } }}
        muiTopToolbarProps={{ sx: { background: "#EBECF0" } }}
        muiBottomToolbarProps={{ sx: { background: "#EBECF0" } }}
        muiTableHeadRowProps={{ sx: { background: "#EBECF0" } }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal"
        enableColumnOrdering
        enableEditing
        onEditingRowSave={editConfirmation}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton
                onClick={() => {
                  setDataToEdit(row.original);
                  table.setEditingRow(row);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton
                color="error"
                onClick={() => {
                  setDeleteUserDetails(row.original);
                  setOpen(true);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
    </>
  );
};

export default WarehouseGrid;
