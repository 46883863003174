import { Box } from "@mui/material";

export default function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className="w-full"
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            padding: "1rem",
            display: "flex",
            width: "100%",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}
