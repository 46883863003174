import { Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
const Details = ({ paramsData }) => {
  const getParams = () => {
    let data = Object.keys(paramsData);
    let halfArr = data.splice(0, Math.ceil(data.length / 2));
    let reportRows = [];
    for (let i = 0; i <= 1; i++) {
      if (i == 0) {
        reportRows.push(
          <View key={i} style={styles.half}>
            <View style={[styles.full, styles.start, styles.size]}>
              {halfArr.map((ele, i) => (
                <View key={i} style={styles.row}>
                  <Text>{ele}</Text>
                  <View style={styles.gap}>
                    <Text>:</Text>
                  </View>
                  <Text>
                    {ele.toLowerCase().includes("date")
                      ? moment(paramsData[ele]).format("ll") ?? "--"
                      : paramsData[ele] ?? "--"}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        );
      } else {
        reportRows.push(
          <View key={i} style={styles.half}>
            <View style={[styles.full, styles.end, styles.size]}>
              {data.map((ele, i) => (
                <View key={i} style={styles.row}>
                  <Text>{ele}</Text>
                  <View style={styles.gap}>
                    <Text>:</Text>
                  </View>
                  <Text>
                    {ele.toLowerCase().includes("date")
                      ? moment(paramsData[ele]).format("ll")
                      : paramsData[ele]}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        );
      }
    }
    return reportRows;
  };

  return (
    <>
      <View style={styles.reportDetailsCont}>{getParams()}</View>
    </>
  );
};

export default Details;

const styles = StyleSheet.create({
  reportDetailsCont: {
    width: "100%",
    flexDirection: "row",
    marginBottom: 20,
  },
  half: { width: "50%" },
  full: { width: "100%" },
  row: { flexDirection: "row", marginVertical: 4 },
  start: { justifyContent: "flex-start" },
  end: { alignItems: "flex-end" },
  gap: { width: "10px", alignItems: "center", justifyContent: "center" },
  size: { fontSize: 12 },
  // reportName: {
  //   fontSize: 20,
  // },
  // reportPeriod: {
  //   fontSize: 12,
  //   paddingVertical: 10,
  // },
  // seprator: {
  //   height: 1,
  //   backgroundColor: "#000",
  //   marginVertical: 8,
  // },
  // reportParams: {
  //   width: "70%",
  //   fontSize: 12,
  // },
});
