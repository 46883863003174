import {
  Select,
  MenuItem,
  InputLabel,
  TextField,
  FormControl,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { useState, useContext, useRef } from "react";
import { QRCode } from "react-qrcode-logo";
import ImageLogo from "../../../../assets/img/layout/cyltraxlogo.png";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import { AppContext } from "Context/AppContext";
import API from "API/API";
import Loading from "components/loading";
import PromptModal from "components/promptModal/PromptModal";

const WarehouseForm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState(props.itemList);
  const [vehicles, setVehicles] = useState(props.vehicleList);
  const [dataToAdd, setDatatoAdd] = useState({});
  const [successModal, setSuccessModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [open, setOpen] = useState(false);
  const { userData } = useContext(AppContext);

  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById("qrCode")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  const inputCustomStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#1b3bbb",
      },
      "& fieldset": {
        borderColor: "#1b3bbb",
      },
      "&:hover fieldset": {
        borderColor: "#1b3bbb",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
    background: "#fff",
  };

  const serialToRef = useRef(null);
  const itemQuantityRef = useRef(null);
  const recievedAtRef = useRef(null);
  const issuedAtRef = useRef(null);

  const addWarehouse = async (formData) => {
    setIsLoading(true);
    handleDialogClose();
    try {
      const res = await API().AddWarehouse(
        formData.warehouseStatus,
        formData.serialFrom,
        formData.serialTo,
        formData.itemId.ITEMID,
        formData.itemAbbreviation,
        formData.itemDescription,
        formData.itemQuantity,
        formData.vehicleId,
        formData.issuedAt,
        formData.recievedAt,
        formData.remarks,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      if (res.data.status) {
        setFeedbackMessage("Entry Successful");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error While Entering Data");
        setSuccessModal(true);
      }
    } catch (err) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const warehouseValidationSchema = yup.object({
    serialFrom: yup
      .number()
      .required("Serial is Required")
      .min(0, "Min 1 Value")
      .max(999999, "Max 999999 Characters"),
    serialTo: yup
      .number()
      .required("Serial is Required")
      .min(0, "Min 0 Value")
      .max(999999, "Max 999999 Value"),
    itemQuantity: yup
      .string()
      .required("Item Quantity is Required")
      .min(0, "Min 0 Value")
      .max(99999999, "Max 99999999 Value"),
    remarks: yup
      .string()
      .required("Remarks is Required")
      .min(0, "Min 0 Characters")
      .max(300, "Max 300 Characters"),
  });

  const formik = useFormik({
    initialValues: {
      warehouseStatus: "in",
      serialFrom: "",
      serialTo: "",
      itemId: items[0],
      itemAbbreviation: items[0].ITEMID,
      itemDescription: items[0].DESCRIPTION,
      itemQuantity: "",
      vehicleId: vehicles[0].ID,
      issuedAt: moment(new Date()),
      recievedAt: moment(new Date()),
      remarks: "",
      created_by: `${userData.USERNAME} | ${moment(new Date()).format("lll")}`,
    },
    validationSchema: warehouseValidationSchema,
    onSubmit: (values) => {
      setDatatoAdd(values);
      setOpen(true);
    },
  });

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        className={`absolute w-full px-10 ${
          isLoading ? "flex" : "hidden"
        } z-50 h-full flex-col items-center justify-center backdrop-blur-sm`}
      >
        <Loading />
      </div>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Save/Update ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Add this Entry
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              addWarehouse(dataToAdd);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <PromptModal
        ModalState={successModal}
        feedbackMessage={feedbackMessage}
        onClick={() => {
          setSuccessModal(false);
          props.callWarehouseDB();
        }}
      />
      <section className="w-full max-w-5xl rounded-t-2xl border border-gray-300 bg-[#EBECF0] p-6 shadow-2xl dark:bg-navy-800 md:p-16">
        <div className="flex w-full justify-center rounded-xl bg-[#f6f6f6] py-4 px-1 shadow-xl sm:px-4 md:py-10 md:px-10 lg:px-16">
          <form className="w-full" onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-1 gap-10 px-4 py-4 sm:grid-cols-2 lg:mt-4 lg:grid-cols-3 lg:gap-y-14 lg:px-10">
              <FormControl
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#1b3bbb",
                    },
                    "& fieldset": {
                      borderColor: "#1b3bbb",
                    },
                    "&:hover fieldset": {
                      borderColor: "#1b3bbb",
                    },
                  },
                  "& label.Mui-focused": {
                    color: "#24388a",
                  },
                  background: "#fff",
                }}
              >
                <InputLabel id="warehouseStatus">Status</InputLabel>
                <Select
                  labelId="warehouseStatus"
                  id="warehouseStatus"
                  name="warehouseStatus"
                  // value={formData.warehouseStatus}
                  // onChange={(e) =>
                  //   handleFormDataChange(e.target.name, e.target.value)
                  // }
                  value={formik.values.warehouseStatus}
                  onChange={formik.handleChange}
                  fullWidth
                  label="Status"
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  size="small"
                >
                  <MenuItem value="in">In</MenuItem>
                  <MenuItem value="out">Out</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#1b3bbb",
                    },
                    "& fieldset": {
                      borderColor: "#1b3bbb",
                    },
                    "&:hover fieldset": {
                      borderColor: "#1b3bbb",
                    },
                  },
                  "& label.Mui-focused": {
                    color: "#24388a",
                  },
                  background: "#fff",
                }}
              >
                <InputLabel id="vehicleId">Vehicle</InputLabel>
                <Select
                  labelId="vehicleId"
                  id="vehicleId"
                  name="vehicleId"
                  value={formik.values.vehicleId}
                  onChange={formik.handleChange}
                  fullWidth
                  label="Vehicle"
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  size="small"
                >
                  <MenuItem value="Import">Import</MenuItem>
                  {vehicles.map((item, index) => (
                    <MenuItem key={index} value={item.ID}>
                      {item.REGISTRATION_NO}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#1b3bbb",
                    },
                    "& fieldset": {
                      borderColor: "#1b3bbb",
                    },
                    "&:hover fieldset": {
                      borderColor: "#1b3bbb",
                    },
                  },
                  "& label.Mui-focused": {
                    color: "#24388a",
                  },
                  background: "#fff",
                }}
              >
                <InputLabel id="itemId">Item Abbreviation</InputLabel>
                <Select
                  labelId="itemId"
                  id="itemId"
                  name="itemId"
                  value={formik.values.itemId}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldValue(
                      "itemAbbreviation",
                      e.target.value.ITEMID
                    );
                    formik.setFieldValue(
                      "itemDescription",
                      e.target.value.DESCRIPTION
                    );
                  }}
                  fullWidth
                  label="Item Abbreviation"
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  size="small"
                >
                  {items.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item.ABBREVIATION}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div>
                <TextField
                  fullWidth
                  id="itemAbbreviation"
                  name="itemAbbreviation"
                  label="Item ID"
                  value={formik.values.itemAbbreviation}
                  variant="outlined"
                  size="small"
                  sx={inputCustomStyle}
                  disabled
                />
              </div>
              <div>
                <TextField
                  fullWidth
                  id="itemDescription"
                  name="itemDescription"
                  label="Item Description"
                  value={formik.values.itemDescription}
                  disabled
                  variant="outlined"
                  size="small"
                  sx={inputCustomStyle}
                />
              </div>
              <div>
                <TextField
                  fullWidth
                  type="number"
                  id="serialFrom"
                  label="Serial From"
                  autoFocus
                  name="serialFrom"
                  value={formik.values.serialFrom}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.serialFrom &&
                    Boolean(formik.errors.serialFrom)
                  }
                  helperText={
                    formik.touched.serialFrom && formik.errors.serialFrom
                  }
                  variant="outlined"
                  size="small"
                  sx={inputCustomStyle}
                  inputProps={{
                    maxLength: 6,
                    onKeyPress: (event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.preventDefault();
                        serialToRef.current.focus();
                      }
                    },
                  }}
                />
              </div>
              <div>
                <TextField
                  type="number"
                  fullWidth
                  id="serialTo"
                  label="Serial To"
                  name="serialTo"
                  inputRef={serialToRef}
                  value={formik.values.serialTo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.serialTo && Boolean(formik.errors.serialTo)
                  }
                  helperText={formik.touched.serialTo && formik.errors.serialTo}
                  variant="outlined"
                  size="small"
                  sx={inputCustomStyle}
                  inputProps={{
                    maxLength: 6,
                    onKeyPress: (event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.preventDefault();
                        itemQuantityRef.current.focus();
                      }
                    },
                  }}
                />
              </div>
              <div>
                <TextField
                  type="number"
                  fullWidth
                  inputRef={itemQuantityRef}
                  id="itemQuantity"
                  label="Item Quantity"
                  name="itemQuantity"
                  value={formik.values.itemQuantity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.itemQuantity &&
                    Boolean(formik.errors.itemQuantity)
                  }
                  helperText={
                    formik.touched.itemQuantity && formik.errors.itemQuantity
                  }
                  variant="outlined"
                  size="small"
                  sx={inputCustomStyle}
                  inputProps={{
                    onKeyPress: (event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.preventDefault();
                        formik.values.warehouseStatus === "in"
                          ? recievedAtRef.current.focus()
                          : issuedAtRef.current.focus();
                      }
                    },
                  }}
                />
              </div>
              {formik.values.warehouseStatus === "out" ? (
                <div>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      inputRef={issuedAtRef}
                      format="DD-MM-YYYY"
                      label="issuedAt"
                      value={formik.values.issuedAt}
                      onChange={(value) =>
                        formik.setFieldValue("issuedAt", value, false)
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#1b3bbb",
                          },
                          "& fieldset": {
                            borderColor: "#1b3bbb",
                          },
                          "&:hover fieldset": {
                            borderColor: "#1b3bbb",
                          },
                        },
                        "& label.Mui-focused": {
                          color: "#24388a",
                        },
                        background: "#fff",
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              ) : (
                <div>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      inputRef={recievedAtRef}
                      format="DD-MM-YYYY"
                      label="receivedAt"
                      name="receivedAt"
                      value={formik.values.recievedAt}
                      onChange={(value) =>
                        formik.setFieldValue("recievedAt", value, false)
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#1b3bbb",
                          },
                          "& fieldset": {
                            borderColor: "#1b3bbb",
                          },
                          "&:hover fieldset": {
                            borderColor: "#1b3bbb",
                          },
                        },
                        "& label.Mui-focused": {
                          color: "#24388a",
                        },
                        background: "#fff",
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              )}
              <div className="col-span-3">
                <div>
                  <TextField
                    fullWidth
                    inputRef={itemQuantityRef}
                    id="remarks"
                    multiline
                    label="Remarks"
                    name="remarks"
                    value={formik.values.remarks}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.remarks && Boolean(formik.errors.remarks)
                    }
                    helperText={formik.touched.remarks && formik.errors.remarks}
                    variant="outlined"
                    size="small"
                    sx={inputCustomStyle}
                    inputProps={{
                      maxLength: 300,
                    }}
                  />
                </div>
              </div>
              <div className="hidden">
                <QRCode
                  id="qrCode"
                  value="Cyltrax.com/Home/Login"
                  size={250}
                  logoImage={ImageLogo}
                  logoHeight={35}
                  logoPadding={5}
                  removeQrCodeBehindLogo={true}
                  logoWidth={85}
                />
              </div>
            </div>
            <div className="mt-6 flex justify-end">
              <button
                type="submit"
                className="mr-2 transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none sm:mr-0"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </Box>
  );
};

export default WarehouseForm;
