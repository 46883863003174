import {
  Select,
  MenuItem,
  InputLabel,
  TextField,
  FormControl,
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Modal,
  Dialog,
  Typography,
} from "@mui/material";
import { useRef, useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import API from "../../../../API/API";
import { AppContext } from "Context/AppContext";
import moment from "moment";
import Loading from "components/loading";
import PromptModal from "components/promptModal/PromptModal";

const UserForm = (props) => {
  const { vehicleList, callUserDB } = props;
  const { userData } = useContext(AppContext);
  const [vehicles, setVehicles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataToAdd, setDatatoAdd] = useState({});
  const [successModal, setSuccessModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [showVehicle, setShowVehicle] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const inputCustomStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#1b3bbb",
      },
      "& fieldset": {
        borderColor: "#1b3bbb",
      },
      "&:hover fieldset": {
        borderColor: "#1b3bbb",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
    background: "#fff",
  };

  useEffect(() => {
    setVehicles([...vehicleList]);
  }, [vehicleList]);

  const addUser = async (values) => {
    setIsLoading(true);
    handleDialogClose();
    try {
      const res = await API().AddUser(
        values.email,
        values.password,
        values.portal,
        values.role,
        values.userId,
        values.username,
        values.vehicleId,
        values.created_by
      );
      if (res.data.status) {
        setFeedbackMessage("User Added Succesfully");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error Adding Data");
        setSuccessModal(true);
      }
    } catch (err) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const userValidationSchema = yup.object({
    username: yup
      .string()
      .required("Username is Required")
      .min(2, "Min 2 Characters")
      .max(30, "Max 30 Characters"),
    email: yup
      .string()
      .email("Field Should be an Email Address")
      .required("Email is Required")
      .min(2, "Min 2 Characters")
      .max(30, "Max 30 Characters"),
    password: yup
      .string()
      .required("Password is Required")
      .min(6, "Min 6 Numbers")
      .max(100, "Max 100 Numbers"),
    portal: yup.string().required("Portal is Required"),
    role: yup.string().required("Role is Required"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
      portal: "",
      vehicleId: "",
      role: "",
      userId: 0,
      created_by: `${userData.USERNAME} | ${moment(new Date()).format("lll")}`,
    },
    validationSchema: userValidationSchema,
    onSubmit: (values) => {
      setDatatoAdd(values);
      setOpen(true);
    },
  });

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        className={`absolute w-full px-10 ${
          isLoading ? "flex" : "hidden"
        } z-50 h-full flex-col items-center justify-center backdrop-blur-sm`}
      >
        <Loading />
      </div>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Save/Update ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Add this User
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              addUser(dataToAdd);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <PromptModal
        ModalState={successModal}
        feedbackMessage={feedbackMessage}
        onClick={() => {
          setSuccessModal(false);
          props.callUserDB();
        }}
      />
      <section className="w-full max-w-5xl rounded-t-2xl border border-gray-300 bg-[#EBECF0] p-6 shadow-2xl dark:bg-navy-800 md:p-16">
        <div className="flex w-full justify-center rounded-xl bg-[#f6f6f6] py-4 px-1 shadow-xl sm:px-4 md:py-10 md:px-10 lg:px-16">
          <form className="w-full" onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-1 gap-10 px-4 py-4 sm:grid-cols-2 lg:mt-4 lg:grid-cols-3 lg:gap-y-14 lg:px-10">
              <div>
                <TextField
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoFocus
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  helperText={formik.touched.username && formik.errors.username}
                  variant="outlined"
                  size="small"
                  sx={inputCustomStyle}
                  inputProps={{
                    maxLength: 30,
                    onKeyPress: (event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.preventDefault();
                        emailRef.current.focus();
                      }
                    },
                  }}
                />
              </div>

              <div>
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  inputRef={emailRef}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  variant="outlined"
                  size="small"
                  sx={inputCustomStyle}
                  inputProps={{
                    maxLength: 30,
                    onKeyPress: (event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.preventDefault();
                        passwordRef.current.focus();
                      }
                    },
                  }}
                />
              </div>

              <div>
                <TextField
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  inputRef={passwordRef}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  variant="outlined"
                  size="small"
                  sx={inputCustomStyle}
                  inputProps={{
                    maxLength: 100,
                  }}
                />
              </div>

              <div>
                <FormControl fullWidth size="small" sx={inputCustomStyle}>
                  <InputLabel id="portal">Portal</InputLabel>
                  <Select
                    labelId="portal"
                    id="portal"
                    name="portal"
                    value={formik.values.portal}
                    onChange={formik.handleChange}
                    fullWidth
                    label="Portal"
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    size="small"
                  >
                    <MenuItem value="web">Web</MenuItem>
                    <MenuItem value="mobile">Mobile</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div>
                <FormControl fullWidth size="small" sx={inputCustomStyle}>
                  <InputLabel id="role">Role</InputLabel>
                  <Select
                    labelId="role"
                    id="role"
                    name="role"
                    value={formik.values.role}
                    onChange={(e) => {
                      formik.handleChange(e);
                      e.target.value == "Driver" || e.target.value == "Helper"
                        ? setShowVehicle(true)
                        : setShowVehicle(false);
                    }}
                    fullWidth
                    label="Role"
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    size="small"
                  >
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="Driver">Driver</MenuItem>
                    <MenuItem value="Helper">Helper</MenuItem>
                    <MenuItem value="Manager">Manager</MenuItem>
                    <MenuItem value="Sales Man">Sales Man</MenuItem>
                  </Select>
                </FormControl>
              </div>

              {showVehicle && (
                <div>
                  <FormControl fullWidth size="small" sx={inputCustomStyle}>
                    <InputLabel id="vehicleId">Vehicle ID</InputLabel>
                    <Select
                      labelId="vehicleId"
                      id="vehicleId"
                      name="vehicleId"
                      value={formik.values.vehicleId}
                      onChange={formik.handleChange}
                      fullWidth
                      label="Vehicle ID"
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      size="small"
                    >
                      {vehicles.map((item, i) => (
                        <MenuItem key={i} value={item.ID}>
                          {item.REGISTRATION_NO}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
            </div>
            <div className="mt-6 flex justify-end">
              <button
                type="submit"
                className="mr-2 transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none sm:mr-0"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </Box>
  );
};

export default UserForm;
