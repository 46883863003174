import { useMemo, useState, useContext } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  IconButton,
  Tooltip,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import API from "API/API";
import { AppContext } from "Context/AppContext";
import moment from "moment";
import PromptModal from "components/promptModal/PromptModal";

const VendorGrid = (props) => {
  const [tableData, setTableData] = useState(props.vendorList);
  const [dataToEdit, setDataToEdit] = useState({});
  const [deleteUserDetails, setDeleteUserDetails] = useState({});
  const [successModal, setSuccessModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const { userData } = useContext(AppContext);

  const handleSaveRowEdits = async () => {
    handleEditDialogClose();
    try {
      const res = await API().UpdateVendor(
        dataToEdit.ADDRESS,
        dataToEdit.COMPANY_NAME,
        dataToEdit.CR_NO,
        dataToEdit.EMAIL,
        dataToEdit.PHONE_NO,
        dataToEdit.VAT_NO,
        dataToEdit.VENDORID,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      if (res.data.status) {
        setFeedbackMessage("Vendor Updated");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error While Updating Data");
        setSuccessModal(true);
      }
    } catch (err) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const inputCustomStyle = {
    "& .MuiInputBase-root": {
      "&:after": {
        borderBottom: "2px solid #24388a",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
  };

  const editConfirmation = ({ exitEditingMode }) => {
    setEditOpen(true);
  };

  const handleCancelRowEdits = () => {};

  const handleDeleteRow = async (row) => {
    setFeedbackMessage("Vendor Deleted");
    setSuccessModal(true);
    try {
      const res = await API().DeleteVendor(
        row.ADDRESS,
        row.COMPANY_NAME,
        row.CR_NO,
        row.EMAIL,
        row.PHONE_NO,
        row.VAT_NO,
        row.VENDORID,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      handleClose();
      if (res.data.status) {
        setFeedbackMessage("Vendor Deleted");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error While Deleting Data");
        setSuccessModal(true);
      }
    } catch (err) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditDialogClose = () => {
    setEditOpen(false);
  };

  const handleEditDataChange = (name, value) => {
    const tempVal = dataToEdit;
    tempVal[name] = value;
    setDataToEdit((prevState) => ({ ...prevState, [name]: value }));
  };

  const columns = useMemo(() => [
    {
      accessorKey: "VENDORID",
      header: "ID",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="VENDORID"
          sx={inputCustomStyle}
          fullWidth
          name="VENDORID"
          autoFocus
          disabled
          label="Vendor ID"
          value={dataToEdit.VENDORID}
          variant="standard"
        />
      ),
    },
    {
      accessorKey: "CR_NO",
      header: "CR Number",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="CR_NO"
          sx={inputCustomStyle}
          fullWidth
          name="CR_NO"
          autoFocus
          label="CR Number"
          value={dataToEdit.CR_NO}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      accessorKey: "COMPANY_NAME",
      header: "Company Name",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="COMPANY_NAME"
          sx={inputCustomStyle}
          fullWidth
          name="COMPANY_NAME"
          label="Company Name"
          value={dataToEdit.COMPANY_NAME}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      accessorKey: "ADDRESS",
      header: "Address",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="ADDRESS"
          sx={inputCustomStyle}
          fullWidth
          name="ADDRESS"
          label="Address"
          value={dataToEdit.ADDRESS}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      accessorKey: "PHONE_NO",
      header: "Phone No.",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="PHONE_NO"
          sx={inputCustomStyle}
          fullWidth
          name="PHONE_NO"
          label="Phone Number"
          value={dataToEdit.PHONE_NO}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      accessorKey: "EMAIL",
      header: "Email",
      size: 140,
      Edit: ({ cell }) => (
        <TextField
          id="EMAIL"
          sx={inputCustomStyle}
          fullWidth
          name="EMAIL"
          label="Email"
          value={dataToEdit.EMAIL}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      accessorKey: "VAT_NO",
      header: "VAT No.",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="VAT_NO"
          sx={inputCustomStyle}
          fullWidth
          name="VAT_NO"
          label="VAT Number"
          value={dataToEdit.VAT_NO}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
  ]);

  return (
    <>
      <PromptModal
        ModalState={successModal}
        feedbackMessage={feedbackMessage}
        onClick={() => {
          setSuccessModal(false);
          props.callVendorDB();
        }}
      />
      {/* DELETE DIALOG */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Remove this Vendor
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteRow(deleteUserDetails);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* UPDATE DIALOG */}

      <Dialog
        open={editOpen}
        onClose={handleEditDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Save/Update?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Update this Vendor
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleEditDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSaveRowEdits(dataToEdit);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <MaterialReactTable
        muiTableBodyRowProps={{ sx: { background: "#EBECF0" } }}
        muiTopToolbarProps={{ sx: { background: "#EBECF0" } }}
        muiBottomToolbarProps={{ sx: { background: "#EBECF0" } }}
        muiTableHeadRowProps={{ sx: { background: "#EBECF0" } }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal"
        enableColumnOrdering
        enableEditing
        onEditingRowSave={editConfirmation}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton
                onClick={() => {
                  setDataToEdit(row.original);
                  table.setEditingRow(row);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton
                color="error"
                onClick={() => {
                  setDeleteUserDetails(row.original);
                  setOpen(true);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
    </>
  );
};

export default VendorGrid;
