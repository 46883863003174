import {
  TextField,
  Box,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";
import { useRef, useContext, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
import { AppContext } from "Context/AppContext";
import API from "API/API";
import PromptModal from "components/promptModal/PromptModal";
import Loading from "components/loading";

const CylinderForm = (props) => {
  const { userData } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataToAdd, setDatatoAdd] = useState({});
  const [successModal, setSuccessModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const unitRef = useRef(null);
  const itemDescriptionRef = useRef(null);

  const inputCustomStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#1b3bbb",
      },
      "& fieldset": {
        borderColor: "#1b3bbb",
      },
      "&:hover fieldset": {
        borderColor: "#1b3bbb",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
      borderRadius: "5px",
    },
    background: "#fff",
    borderRadius: "5px",
  };

  const addItem = async (values) => {
    setIsLoading(true);
    handleDialogClose();
    try {
      const res = await API().AddItem(
        values.itemId,
        values.abbreviation,
        values.unit,
        values.itemDescription,
        values.created_by
      );
      if (res.data.status) {
        setFeedbackMessage("Item Added Succesfully");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error Adding Data");
        setSuccessModal(true);
      }
    } catch (error) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const itemValidationSchema = yup.object({
    abbreviation: yup
      .string()
      .required("Abbreviation is Required")
      .min(1, "Min 1 Characters")
      .max(20, "Max 20 Characters"),
    unit: yup
      .number()
      .required("Unit is Required")
      .min(0.01, "Min 0.01 Value")
      .max(999999, "Max 999999 Value"),
    itemDescription: yup
      .string()
      .required("Item Description is Required")
      .min(1, "Min 1 Characters")
      .max(50, "Max 50 Characters"),
  });

  const formik = useFormik({
    initialValues: {
      itemId: 0,
      abbreviation: "",
      unit: "",
      itemDescription: "",
      created_by: `${userData.USERNAME} | ${moment(new Date()).format("lll")}`,
    },
    validationSchema: itemValidationSchema,
    onSubmit: (values) => {
      setDatatoAdd(values);
      setOpen(true);
    },
  });

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div
        className={`absolute w-full px-10 ${
          isLoading ? "flex" : "hidden"
        } z-50 h-full flex-col items-center justify-center backdrop-blur-sm`}
      >
        <Loading />
      </div>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Save/Update ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Save this Item
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              addItem(dataToAdd);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <PromptModal
        ModalState={successModal}
        feedbackMessage={feedbackMessage}
        onClick={() => {
          setSuccessModal(false);
          props.callItemDB();
        }}
      />
      <section className="w-full max-w-5xl rounded-t-2xl border border-gray-300 bg-[#EBECF0] p-6 shadow-2xl dark:bg-navy-800 md:p-16">
        <div className="flex w-full justify-center rounded-xl bg-[#f6f6f6] py-4 px-1 shadow-xl dark:border dark:border-gray-300 dark:!bg-navy-800 sm:px-4 md:py-10 md:px-10 lg:px-16">
          <form className="w-full" onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-1 gap-10 px-4 py-4 sm:grid-cols-2 lg:mt-4 lg:grid-cols-3 lg:gap-y-14 lg:px-10">
              <div>
                <TextField
                  fullWidth
                  id="abbreviation"
                  label="Abbreviation"
                  autoFocus
                  name="abbreviation"
                  value={formik.values.abbreviation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.abbreviation &&
                    Boolean(formik.errors.abbreviation)
                  }
                  helperText={
                    formik.touched.abbreviation && formik.errors.abbreviation
                  }
                  variant="outlined"
                  size="small"
                  sx={inputCustomStyle}
                  inputProps={{
                    maxLength: 20,
                    onKeyPress: (event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.preventDefault();
                        unitRef.current.focus();
                      }
                    },
                  }}
                />
              </div>

              <div>
                <TextField
                  fullWidth
                  id="unit"
                  label="Unit Price"
                  type="number"
                  name="unit"
                  inputRef={unitRef}
                  value={formik.values.unit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.unit && Boolean(formik.errors.unit)}
                  helperText={formik.touched.unit && formik.errors.unit}
                  variant="outlined"
                  size="small"
                  sx={inputCustomStyle}
                  inputProps={{
                    maxLength: 6,
                    onKeyPress: (event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.preventDefault();
                        itemDescriptionRef.current.focus();
                      }
                    },
                  }}
                />
              </div>

              <div>
                <TextField
                  fullWidth
                  id="itemDescription"
                  label="Item Description"
                  name="itemDescription"
                  inputRef={itemDescriptionRef}
                  value={formik.values.itemDescription}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.itemDescription &&
                    Boolean(formik.errors.itemDescription)
                  }
                  helperText={
                    formik.touched.itemDescription &&
                    formik.errors.itemDescription
                  }
                  variant="outlined"
                  size="small"
                  sx={inputCustomStyle}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
            </div>
            <div className="mt-6 flex justify-between">
              <div className="flex w-full justify-end">
                <button
                  type="submit"
                  className="mr-2 transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none sm:mr-0"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </Box>
  );
};

export default CylinderForm;
