import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import AdminLayout from "layouts/admin";
import DashboardLayout from "layouts/dashboard";
import AuthLayout from "layouts/auth";
//import ReportLayout from "layouts/reports";
import { AppContext } from "Context/AppContext";
import cyltraxLogo from "assets/img/layout/cyltraxlogo.png";

const App = () => {
  const [isLogin, setIsLogin] = useState(null);
  const [PDFComponent, setPDFComponent] = useState(<div></div>);
  const [customerAddress, setCustomerAddress] = useState([]);
  const [orders, setOrders] = useState([]);
  const [userData, setUserData] = useState({});
  // const [testAmount, setTestAmount] = useState("");
  const StateContainer = useMemo(
    () => ({
      PDFComponent,
      setPDFComponent,
      isLogin,
      setIsLogin,
      userData,
      setUserData,
      customerAddress,
      setCustomerAddress,
    }),
    [
      PDFComponent,
      setPDFComponent,
      isLogin,
      setIsLogin,
      userData,
      setUserData,
      customerAddress,
      setCustomerAddress,
    ]
  );

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      let token = JSON.parse(sessionStorage.getItem("token"));
      if (token) {
        setUserData(token);
        navigate(location.pathname || "/dashboard");
        setIsLogin(true);
      } else {
        setIsLogin(false);
        navigate("/auth");
      }
    }, 1000);
  }, []);
  if (isLogin === null) {
    return (
      <div className="flex h-screen items-center justify-center">
        <img src={cyltraxLogo} width={200} />
      </div>
    );
  } else {
    return (
      <AppContext.Provider value={StateContainer}>
        <Routes>
          {isLogin === true ? (
            <>
              <Route path="admin/*" element={<AdminLayout />} />
              <Route path="dashboard/*" element={<DashboardLayout />} />
              {/*<Route path="reports/*" element={<ReportLayout />} /> */}
              <Route
                path="/*"
                element={<Navigate to="/dashboard" replace={true} />}
              />
            </>
          ) : (
            <>
              <Route
                path="/"
                element={<Navigate to="/auth" replace={true} />}
              />
              <Route path="auth/" element={<AuthLayout />} />
            </>
          )}
        </Routes>
      </AppContext.Provider>
    );
  }
};

export default App;
