import { useContext, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  IconButton,
  Tooltip,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import moment from "moment";
import API from "API/API";
import { AppContext } from "Context/AppContext";
import PromptModal from "components/promptModal/PromptModal";

const CylinderGrid = (props) => {
  const [tableData, setTableData] = useState(props.itemList);
  const [dataToEdit, setDataToEdit] = useState({});
  const [deleteUserDetails, setDeleteUserDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const { userData } = useContext(AppContext);

  const handleSaveRowEdits = async () => {
    handleEditDialogClose();
    try {
      const res = await API().UpdateItem(
        dataToEdit.ITEMID,
        dataToEdit.ABBREVIATION,
        dataToEdit.UNIT_PRICE,
        dataToEdit.DESCRIPTION,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      if (res.data.status) {
        setFeedbackMessage("Item Updated");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error While Editing Data");
        setSuccessModal(true);
      }
    } catch (err) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const editConfirmation = ({ exitEditingMode }) => {
    setEditOpen(true);
  };

  const handleCancelRowEdits = () => {};

  const inputCustomStyle = {
    "& .MuiInputBase-root": {
      "&:after": {
        borderBottom: "2px solid #24388a",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#fff",
      margin: 0,
      paddingTop: 0.5,
    },
  };

  const handleDeleteRow = async (row) => {
    try {
      const res = await API().DeleteItem(
        row.ITEMID,
        row.ABBREVIATION,
        row.DESCRIPTION,
        row.UNIT_PRICE,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      handleClose();
      if (res.data.status) {
        setFeedbackMessage("Item Deleted");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error While Deleting Data");
        setSuccessModal(true);
      }
    } catch (err) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditDialogClose = () => {
    setEditOpen(false);
  };

  const handleEditDataChange = (name, value) => {
    if (name == "ABBREVIATION") {
      setShowError(false);
      if (
        tableData.filter((e) => {
          return e.ABBREVIATION == value && e.ITEMID !== dataToEdit.ITEMID;
        }).length == 0
      ) {
        setDataToEdit({ ...dataToEdit, [name]: value });
      } else {
        setDataToEdit({ ...dataToEdit, [name]: value });
        //---ENTER CODE HERE---
        setShowError(true);
      }
    } else {
      setDataToEdit({ ...dataToEdit, [name]: value });
    }
  };

  const columns = useMemo(() => [
    {
      accessorKey: "ITEMID",
      header: "Item ID",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="ITEMID"
          sx={inputCustomStyle}
          fullWidth
          disabled
          name="ITEMID"
          autoFocus
          label="Item ID"
          value={dataToEdit.ITEMID}
          variant="standard"
        />
      ),
    },
    {
      accessorKey: "ABBREVIATION",
      header: "Abbreviation",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="ABBREVIATION"
          sx={inputCustomStyle}
          fullWidth
          name="ABBREVIATION"
          autoFocus
          label="Abbreviation"
          error={showError}
          helperText={showError && "Item Already Exist"}
          value={dataToEdit.ABBREVIATION}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      accessorKey: "UNIT_PRICE",
      header: "Unit Price",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="UNIT_PRICE"
          sx={inputCustomStyle}
          fullWidth
          name="UNIT_PRICE"
          label="Unit Price"
          value={dataToEdit.UNIT_PRICE}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      accessorKey: "DESCRIPTION",
      header: "Item Decription",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="DESCRIPTION"
          sx={inputCustomStyle}
          fullWidth
          name="DESCRIPTION"
          label="Item Description"
          value={dataToEdit.DESCRIPTION}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
  ]);

  return (
    <>
      <PromptModal
        ModalState={successModal}
        feedbackMessage={feedbackMessage}
        onClick={() => {
          setSuccessModal(false);
          props.callItemDB();
        }}
      />
      {/* DELETE DIALOG */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Remove this Item
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteRow(deleteUserDetails);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* UPDATE DIALOG */}
      <Dialog
        open={editOpen}
        onClose={handleEditDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Save/Update?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Update this Item
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleEditDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSaveRowEdits(dataToEdit);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <MaterialReactTable
        muiTableBodyRowProps={{ sx: { background: "#EBECF0" } }}
        muiTopToolbarProps={{ sx: { background: "#EBECF0" } }}
        muiBottomToolbarProps={{ sx: { background: "#EBECF0" } }}
        muiTableHeadRowProps={{ sx: { background: "#EBECF0" } }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        onEditingRowSave={editConfirmation}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton
                onClick={() => {
                  setDataToEdit(row.original);
                  table.setEditingRow(row);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton
                color="error"
                onClick={() => {
                  setDeleteUserDetails(row.original);
                  setOpen(true);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
    </>
  );
};

export default CylinderGrid;
