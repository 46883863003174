import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import ReportHeader from "components/report/header";
import ReportFooter from "components/report/footer";
import ReportDetails from "components/report/details";
import ReportBody from "./HOCReport";
import moment from "moment";

function Report(props) {
  return (
    <Document
      title={`${moment(new Date()).format("lll")} - ${props.reportName}`}
    >
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.topSection}>
            <ReportHeader reportName={props.reportName} />
            <View style={styles.bodySection}>
              <ReportDetails paramsData={props.paramsData} />
              <View style={styles.bodyCont}>
                <ReportBody
                  apiData={props.apiData}
                  reportName={props.reportName}
                />
              </View>
            </View>
          </View>
          <View style={styles.bottomSection}>
            <ReportFooter />
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default Report;

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
  },
  section: {
    flexGrow: 1,
    flex: 1,
  },
  main: {
    height: "100%",
    width: "100%",
  },
  topSection: {
    flex: 1,
    width: "100%",
  },
  bottomSection: {
    flex: 0.15,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  bodySection: {
    paddingHorizontal: 20,
    marginVertical: 20,
    width: "100%",
  },
  bodyCont: {
    marginTop: 25,
  },
});
