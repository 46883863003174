import Card from "components/card";
import Tooltip from "@mui/material/Tooltip";

const InfoCard = () => {
  return (
    <div className="h-10 w-24 p-5 text-navy-700">
      <div className="flex h-full w-full items-center justify-start">
        Pending
      </div>
    </div>
  );
};

const Widget = ({
  icon,
  route,
  cylinderQuantity,
  lastDelivery,
  cylinderQty,
  cylinderName,
  extra,
  vehicleColor,
  vehicleNum,
}) => {
  return (
    <Card
      extra={`${extra} overflow-hidden justify-center items-center flex-grow h-52 rounded-[20px]`}
    >
      <div className="flex w-full flex-col items-center justify-center px-1 text-center">
        <div className="grid w-full grid-cols-2 px-4">
          <div className=" my-1 flex text-left text-xs font-semibold text-navy-700 dark:text-white">
            <div className="h-50 flex rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
              <span
                style={{ color: vehicleColor }}
                className={`flex items-center dark:text-white`}
              >
                {icon}
              </span>
            </div>
          </div>
          <span
            className={`tex-navy-700 flex items-center text-[15px] font-bold  dark:text-white`}
          >
            {vehicleNum}
          </span>
          <div className=" my-1 text-left text-[12px] font-semibold text-navy-700 dark:text-white">
            Route :
          </div>
          <div className="my-1 text-left text-[12px] text-navy-700 dark:text-white">
            {route}
          </div>
          <div className=" my-1 text-left text-[12px] font-semibold text-navy-700 dark:text-white">
            Total Cyl :
          </div>
          <div className="my-1 text-left text-[12px] text-navy-700 dark:text-white">
            {cylinderQuantity}
          </div>
          <div className="  text-left text-[10px] text-navy-700 dark:text-white">
            {cylinderName}
          </div>
          <div className=" flex items-center text-left text-[10px] text-navy-700 dark:text-white">
            {cylinderQty}
            <Tooltip
              className="ml-2 bg-white"
              size="sm"
              title={<InfoCard />}
              PopperProps={{
                sx: {
                  "& .MuiTooltip-tooltip": {
                    background: "white",
                    borderRadius: "16px",
                    boxShadow: "0 1px 20px rgb(0 0 0 / 0.2);",
                  },
                  "& .MuiTooltip-arrow": {
                    color: "white",
                    boxShadow: "0 2px 20px rgb(0 0 0 / 0.2);",
                  },
                },
              }}
              placement="right"
              color="red"
              arrow={true}
            >
              <span className="bg-pink-100-100 ml-2 inline-flex h-3 w-3 animate-pulse cursor-pointer items-center justify-center rounded-full text-sm font-semibold text-pink-500 dark:bg-pink-700 dark:text-pink-400">
                <svg
                  className="h-3 w-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
              </span>
            </Tooltip>
          </div>
          {/* <div className=" text-left text-[10px] text-navy-700 dark:text-white">
            40 Lbs :
          </div>
          <div className=" text-left text-[10px] text-navy-700 dark:text-white">
            {used40lbsCylinder} out of {total40lbsCylinder}
          </div>
          <div className=" text-left text-[10px] text-navy-700 dark:text-white">
            100 Lbs :
          </div>
          <div className=" text-left text-[10px] text-navy-700 dark:text-white">
            {used100lbsCylinder} out of {total100lbsCylinder}
          </div> */}
          <div className="mt-1 text-left text-[10px] font-semibold text-navy-700 dark:text-white">
            Last Del. Time :
          </div>
          <div className="mt-1 text-left text-[10px] text-navy-700 dark:text-white">
            {lastDelivery}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Widget;
