import React, { useCallback, useContext, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Button,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import API from "API/API";
import moment from "moment";
import { AppContext } from "Context/AppContext";
import PromptModal from "components/promptModal/PromptModal";

const CustomerGrid = (props) => {
  const { userData } = useContext(AppContext);
  const [tableData, setTableData] = useState(props.gridData);
  const [deleteUserDetails, setDeleteUserDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteRow = async (row) => {
    try {
      const res = await API().DeleteCustomer(
        row.address,
        row.CUSTOMERID,
        row.CPR_NUMBER,
        row.CURRENY,
        row.CUSTOMER_TYPE,
        row.EMAIL,
        row.ENABLE_PORTAL,
        row.FIRSTNAME,
        row.LASTNAME,
        row.MOBILE_NUMBER,
        row.MODE_OF_PAYMENT,
        row.PORTAL_LANGUAGE,
        row.REFERRED_BY,
        row.REMARKS,
        row.REPORTING_TAGS,
        row.TAX_TREATMENT,
        row.TRN,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      handleClose();
      if (res.data.status) {
        setFeedbackMessage("Customer Deleted");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error While Deleting Data");
        setSuccessModal(true);
      }
    } catch (err) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const columns = useMemo(() => [
    {
      accessorKey: "CUSTOMERID",
      header: "Customer ID",
      size: 80,
    },
    {
      accessorKey: "CUSTOMER_TYPE",
      header: "Customer Type",
      size: 80,
    },
    {
      accessorKey: "FIRSTNAME",
      header: "Comapny/First Name",
      size: 80,
    },
    {
      accessorKey: "LASTNAME",
      header: "Last Name",
      size: 140,
    },
    {
      accessorKey: "EMAIL",
      header: "Email",
      size: 80,
    },
    {
      accessorKey: "MOBILE_NUMBER",
      header: "Mobile Number",
      size: 80,
    },
    {
      accessorKey: "CPR_NUMBER",
      header: "CPR/CR Number",
      size: 80,
    },
    {
      accessorKey: "TAX_TREATMENT",
      header: "Tax Treatment",
      size: 80,
    },
    {
      accessorKey: "TRN",
      header: "VAT No.",
      size: 80,
    },
    {
      accessorKey: "CURRENY",
      header: "App Status",
      size: 80,
    },
    {
      accessorKey: "MODE_OF_PAYMENT",
      header: "Mode Of Payment",
      size: 80,
    },
    {
      accessorKey: "ENABLE_PORTAL",
      header: "Enable Portal",
      size: 80,
    },
    {
      accessorKey: "PORTAL_LANGUAGE",
      header: "Portal Language",
      size: 80,
    },
    {
      accessorKey: "REFERRED_BY",
      header: "Referred By",
      size: 80,
    },
    {
      accessorKey: "REPORTING_TAGS",
      header: "Reporting Tags",
      size: 80,
    },
    {
      accessorKey: "REMARKS",
      header: "Remarks",
      size: 80,
    },
    // {
    //   accessorKey: "address",
    //   header: "Address",
    //   size: 80,
    // },
  ]);
  return (
    <>
      <PromptModal
        ModalState={successModal}
        feedbackMessage={feedbackMessage}
        onClick={() => {
          setSuccessModal(false);
          props.callCustomerDB();
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you Sure ?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Remove this Vendor
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Disagree
          </Button>
          <Button
            onClick={() => {
              handleDeleteRow(deleteUserDetails);
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <MaterialReactTable
        muiTableBodyRowProps={{ sx: { background: "#EBECF0" } }}
        muiTopToolbarProps={{ sx: { background: "#EBECF0" } }}
        muiBottomToolbarProps={{ sx: { background: "#EBECF0" } }}
        muiTableHeadRowProps={{ sx: { background: "#EBECF0" } }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData} //default
        enableColumnOrdering
        enableEditing
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton
                onClick={() => {
                  props.handleRowEdit(row.original);
                  props.openEditingMode(true);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton
                color="error"
                onClick={() => {
                  setDeleteUserDetails(row.original);
                  setOpen(true);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
    </>
  );
};

export default CustomerGrid;
