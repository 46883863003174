import { useState, useMemo, useEffect, useContext, Fragment } from "react";
import { TextField, FormControl, Select, MenuItem } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { AppContext } from "Context/AppContext";

const BillingShippingAddress = (props) => {
  const { customerAddress, setCustomerAddress, userData } =
    useContext(AppContext);
  const data = {
    addressId: 0,
    flat_shop: "",
    building: "",
    road: "",
    block: "",
    area: "",
    pobox: "",
    city: "",
    state: "",
    preferred_times: moment(new Date()),
    contact_person: "",
    billTo: "",
    createdby: `${userData.USERNAME} | ${moment(new Date()).format("lll")}`,
    editby: null,
    deleteby: null,
  };
  const [tableData, setTableData] = useState([{ ...data }]);
  const [rowIndex, setRowIndex] = useState(0);

  const handleSaveCell = (cell, value) => {
    tableData[cell.row.index][cell.column.id] = value;
    setCustomerAddress(tableData);
  };

  const columns = useMemo(() => [
    {
      accessorKey: "flat_shop",
      header: "Flat No./Shop",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          variant="standard"
          sx={inputCustomStyle}
          id="flat_shop"
          value={cell.row.original.flat_shop}
          onChange={(e) => handleSaveCell(cell, e.target.value)}
          onBlur={(e) => {
            if (cell.row.index == rowIndex && e.target.value.length > 0) {
              setTableData([...tableData, { ...data }]);
              setRowIndex(rowIndex + 1);
            }
            //handleSaveCell(cell, e.target.value)
          }}
        />
      ),
    },
    {
      accessorKey: "building",
      header: "Building",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          variant="standard"
          sx={inputCustomStyle}
          id="building"
          value={cell.row.original.building}
          onChange={(e) => handleSaveCell(cell, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "road",
      header: "Road/Street",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          variant="standard"
          sx={inputCustomStyle}
          id="road"
          value={cell.row.original.road}
          onChange={(e) => handleSaveCell(cell, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "block",
      header: "Block",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          variant="standard"
          sx={inputCustomStyle}
          id="block"
          value={cell.row.original.block}
          onChange={(e) => handleSaveCell(cell, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "area",
      header: "Area",
      size: 80,
      Edit: ({ cell }) => (
        // <TextField
        //   sx={inputCustomStyle}
        //   variant="standard"
        //   id="area"
        //   value={cell.row.original.area}
        //   onChange={(e) => handleSaveCell(cell, e.target.value)}
        // />
        <FormControl
          fullWidth
          variant="standard"
          size="small"
          sx={inputCustomStyle}
        >
          <Select
            id="area"
            value={cell.row.original.area}
            onChange={(e) => handleSaveCell(cell, e.target.value)}
            fullWidth
            inputProps={{
              "aria-label": "Without label",
            }}
            size="small"
          >
            {props.logisticData.map((list, i) =>
              list.DELIVERY_AREA.map((item, index) => {
                return (
                  <MenuItem Key={index} value={item.DELIVERY_AREA}>
                    {item.DELIVERY_AREA}
                  </MenuItem>
                );
              })
            )}
          </Select>
        </FormControl>
      ),
    },
    {
      accessorKey: "pobox",
      header: "P.O Box",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          sx={inputCustomStyle}
          variant="standard"
          id="pobox"
          value={cell.row.original.pobox}
          onChange={(e) => handleSaveCell(cell, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "city",
      header: "City",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          sx={inputCustomStyle}
          variant="standard"
          id="city"
          value={cell.row.original.city}
          onChange={(e) => handleSaveCell(cell, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "state",
      header: "State",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          variant="standard"
          sx={inputCustomStyle}
          id="state"
          value={cell.row.original.state}
          onChange={(e) => handleSaveCell(cell, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "preferred_times",
      header: "Preffered Time",
      size: 80,
      Edit: ({ cell }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <TimePicker
            label="Time"
            format="hh:mm a"
            value={moment(cell.row.original.preferred_times)}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: false,
                variant: "standard",
                sx: inputCustomStyle,
              },
            }}
            onChange={(newValue) => {
              handleSaveCell(cell, moment(newValue));
            }}
          />
        </LocalizationProvider>
      ),
    },
    {
      accessorKey: "contact_person",
      header: "Contact Person",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          variant="standard"
          sx={inputCustomStyle}
          id="contact_person"
          value={cell.row.original.contact_person}
          onChange={(e) => handleSaveCell(cell, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "billTo",
      header: "Bill To",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          variant="standard"
          sx={inputCustomStyle}
          id="billTo"
          value={cell.row.original.billTo}
          onChange={(e) => handleSaveCell(cell, e.target.value)}
        />
      ),
    },
  ]);

  useEffect(() => {
    if (props.formData.address.length !== 0) {
      setTableData([...props.formData.address]);
      setRowIndex(props.formData.address.length - 1);
    }
  }, [props.formData]);

  const inputCustomStyle = {
    "& .MuiInputBase-root": {
      "&:after": {
        borderBottom: "2px solid #24388a",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
  };

  return (
    <div className="flex w-full flex-col px-4">
      <h4 className="mt-2 pb-3 text-2xl font-bold text-navy-500">
        Billing / Shipping Address
      </h4>
      <div className="mt-5 grid h-48 max-w-4xl grid-cols-1 overflow-y-scroll rounded-xl bg-[#f6f6f6] p-5 shadow-xl scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 scrollbar-thumb-rounded">
        <MaterialReactTable
          columns={columns}
          enableTopToolbar={false}
          data={tableData}
          muiTablePaperProps={{ sx: { borderRadius: "10px" } }}
          muiTableContainerProps={{ sx: { borderRadius: "10px" } }}
          editingMode="table"
          muiTableBodyCellProps={{
            sx: { padding: "0.5rem" },
          }}
          autoResetPageIndex={false}
          enableEditing
        />
      </div>
      <div className="my-6 flex w-full justify-between">
        <button
          onClick={props.onClickPrev}
          className="transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none"
        >
          Previous
        </button>
        <button
          onClick={props.onClick}
          className="transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default BillingShippingAddress;
