import { useContext, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  IconButton,
  Tooltip,
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import API from "API/API";
import moment from "moment";
import PromptModal from "components/promptModal/PromptModal";
import { AppContext } from "Context/AppContext";

const PurchaseGrid = (props) => {
  const { userData } = useContext(AppContext);
  const [tableData, setTableData] = useState(props.gridData);
  const [vendors, setVendors] = useState(props.vendorList);
  const [vehicles, setVehicles] = useState(props.vehicleList);
  const [deleteUserDetails, setDeleteUserDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const handleSaveRowEdits = () => {};

  const handleCancelRowEdits = () => {};

  const handleDeleteRow = async (userDetails) => {
    userDetails.items = userDetails.items.map((i) => {
      return { ...i, itemId: i.itemId.ITEMID };
    });

    try {
      const res = await API().DeleteRefill(
        userDetails.deliveryDocketNo,
        userDetails.vendorId,
        userDetails.lpoRef,
        userDetails.vehicleNo,
        userDetails.date,
        userDetails.items,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      handleClose();
      if (res.data.status) {
        setFeedbackMessage("Entry Deleted");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error While Deleting Data");
        setSuccessModal(true);
      }
    } catch (err) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = useMemo(() => [
    {
      accessorKey: "deliveryDocketNo",
      header: "Delivery Docket No.",
      size: 80,
    },
    {
      accessorFn: (row) =>
        vendors.filter((e) => e.VENDORID == row.vendorId)[0]?.COMPANY_NAME
          ? vendors.filter((e) => e.VENDORID == row.vendorId)[0]?.COMPANY_NAME
          : "",
      accessorKey: "vendorId",
      header: "Vendor Name",
      size: 80,
    },
    {
      accessorFn: (row) =>
        vehicles.filter((e) => e.ID == row.vehicleNo)[0]?.REGISTRATION_NO
          ? vehicles.filter((e) => e.ID == row.vehicleNo)[0]?.REGISTRATION_NO
          : "",
      accessorKey: "vehicleNo",
      header: "Vehicle Number",
      size: 80,
    },
    {
      accessorFn: (row) => moment(row.date).format("DD-MM-YYYY"),
      accessorKey: "date",
      header: "Date",
      size: 80,
    },
  ]);

  return (
    <>
      <PromptModal
        ModalState={successModal}
        feedbackMessage={feedbackMessage}
        onClick={() => {
          props.callREfillDB();
          setSuccessModal(false);
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Remove this Order
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteRow(deleteUserDetails);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <MaterialReactTable
        muiTableBodyRowProps={{ sx: { background: "#EBECF0" } }}
        muiTopToolbarProps={{ sx: { background: "#EBECF0" } }}
        muiBottomToolbarProps={{ sx: { background: "#EBECF0" } }}
        muiTableHeadRowProps={{ sx: { background: "#EBECF0" } }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal"
        enableColumnOrdering
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => props.handleRowEdit(row.original)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton
                color="error"
                onClick={() => {
                  setDeleteUserDetails(row.original);
                  setOpen(true);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
    </>
  );
};

export default PurchaseGrid;
