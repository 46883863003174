import React, { useState } from "react";

const OfflineCustomerCard = (props) => {
  return (
    <>
      {props?.data?.map((data, index) => (
        <div
          key={index}
          className="grid w-full grid-cols-3 items-start bg-white px-2 py-3 hover:shadow-2xl dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700"
        >
          <div className="flex items-center gap-3">
            <h5 className="text-xs font-bold text-navy-700 dark:text-white">
              {data.SALEID}
            </h5>
          </div>
          <div className=" flex items-center  text-gray-700 dark:text-white">
            <div className=" flex items-center text-xs font-normal ">
              <p>{data.NAME}</p>
            </div>
          </div>
          <div className=" flex items-center justify-end text-navy-700 dark:text-white">
            <div className=" flex items-center text-xs font-normal ">
              <p>{data.MOBILE_NUMBER}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default OfflineCustomerCard;
