import { useEffect, useState } from "react";
import LogisticForm from "./components/LogisticForm";
import { Backdrop } from "@mui/material";
import Loading from "components/loading";
import LogisticGrid from "./components/LogisticGrid";
import API from "API/API";
import { MdOutlineRefresh } from "react-icons/md";

const LogisticManagement = () => {
  const [vehicles, setVehicles] = useState([]);
  const [logistic, setLogistic] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editingMode, setEditingMode] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [formData, setFormData] = useState({
    FK_VEHICLEID: 1,
    DELIVERY_AREA: [],
  });

  const FetchLogistic = async () => {
    setIsLoading(true);
    try {
      const result = await API().GetLogistic();
      if (result.data.status) {
        let data = result.data.data.map((val, i) => {
          let area = "";
          val.DELIVERY_AREA.forEach((e) =>
            area ? (area += " , " + e.DELIVERY_AREA) : (area += e.DELIVERY_AREA)
          );
          return { ...val, ["area"]: area };
        });
        setLogistic(data);
        GetVehicle();
      } else {
        setErrorState(true);
        setIsLoading(false);
      }
    } catch (err) {
      setErrorState(true);
      setIsLoading(false);
    }
  };

  const GetVehicle = async () => {
    try {
      setIsLoading(true);
      const result = await API().GetVehicle();
      if (result.data.status) {
        setVehicles(result.data.data);
        setFormData({ ...formData, FK_VEHICLEID: result.data.data[0].ID });
        setIsLoading(false);
      } else {
        setErrorState(true);
        setIsLoading(false);
      }
    } catch (err) {
      setErrorState(true);
      setIsLoading(false);
    }
  };

  const handleRowEdit = (row) => {
    setFormData(row);
    setEditingMode(true);
  };

  useEffect(() => {
    FetchLogistic();
  }, []);

  const emptyFieldsHandler = () => {
    setIsLoading(true);
    setFormData({
      FK_VEHICLEID: 1,
      DELIVERY_AREA: [],
    });
    setEditingMode(false);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
  const emptyFields = () => {
    setFormData({
      FK_VEHICLEID: 1,
      DELIVERY_AREA: [],
    });
    setEditingMode(false);
  };

  return (
    <>
      {isLoading ? (
        <>
          <Backdrop
            sx={{
              color: "#fff",
              background: "#F4F7FE",
              position: "relative",
              zIndex: 5,
              height: "100vh",
            }}
            open={isLoading}
          >
            <Loading />
          </Backdrop>
        </>
      ) : (
        <>
          {errorState ? (
            <div className="flex h-[84vh] flex-col items-center justify-center">
              <span
                className="text-navy-600"
                onClick={() => {
                  setIsLoading(true);
                  setErrorState(false);
                  FetchLogistic();
                }}
              >
                <MdOutlineRefresh className="h-28 w-28 -rotate-90 cursor-pointer hover:animate-spin" />
              </span>
              <p className="text-2xl font-bold text-navy-600">
                Server Error, Please Refresh
              </p>
            </div>
          ) : (
            <div className="-mt-5 flex w-full flex-col items-center rounded-b-2xl bg-white px-4">
              {/* <Form /> */}
              <h2 className="text-black flex w-full max-w-5xl py-5 text-3xl font-bold capitalize dark:text-white">
                Logistic Management
              </h2>
              <LogisticForm
                vehicleList={vehicles}
                formData={formData}
                setFormData={setFormData}
                editingMode={editingMode}
                emptyFields={emptyFieldsHandler}
                emptyFieldsTrue={emptyFields}
                callLogisticDB={FetchLogistic}
              />
              <div className="mt-2 w-full max-w-5xl overflow-hidden rounded-b-2xl border border-navy-500  shadow-xl">
                <LogisticGrid
                  handleRowEdit={handleRowEdit}
                  gridData={logistic}
                  callLogisticDB={FetchLogistic}
                  openEditingMode={() => setEditingMode(true)}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default LogisticManagement;
