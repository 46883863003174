import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
const Footer = () => {
  return (
    <>
      <View style={styles.footerCont}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Image
            source={require("assets/img/layout/webLogo.png")}
            style={{ width: 20 }}
          />
          <Text style={{ marginLeft: 5 }}>www.cyltrax.com</Text>
        </View>
        <Image
          style={styles.img}
          source={require("assets/img/layout/CYLTRAXDark.png")}
        />
        <Text
          render={({ pageNumber, totalPages }) =>
            `Pages : ${pageNumber} / ${totalPages}`
          }
        />
      </View>
    </>
  );
};
export default Footer;
const styles = StyleSheet.create({
  footerCont: {
    color: "#fff",
    fontSize: 10,
    backgroundColor: "#0067A5",
    width: "100%",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 20,
  },
  // seprator: {
  //   width: "40%",
  //   height: 1,
  //   backgroundColor: "#000",
  //   marginVertical: 5,
  //},
  img: {
    width: 100,
    marginRight: 55,
    marginTop: 5,
  },
});
