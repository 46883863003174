import MainDashboard from "views/admin/dashboard";
import Items from "views/admin/assetsTracking/Items";
import Vehicle from "views/admin/assetsTracking/Vehicle";
import LogisticManagement from "views/admin/logisticManagement";
import Warehouse from "views/admin/warehouse";
import VendorManagement from "views/admin/vendorManagement";
import Refill from "views/admin/sales-purchase/Refill";
import Sales from "views/admin/sales-purchase/Sales";
import Customer from "views/admin/customer";
import Users from "views/admin/users";
import Purchase from "views/admin/sales-purchase/Purchase";
import CustomerReport from "views/admin/reports/CustomerReport";
import VehicleReport from "views/admin/reports/VehicleReport";
import OrderReport from "views/admin/reports/OrderReport";
import SaleGrowthReport from "views/admin/reports/SaleGrowthReport";
// import CustomerRep from "views/reports/customerReport";
// import Payment from "views/admin/accounts/paymentVoucher/Payment";
// import Receipt from "views/admin/accounts/reciept/Reciept";
// import Reconcile from "views/admin/accounts/bankReconcilation/Reconcile";
// import { CgCalendarDates } from "react-icons/cg";
// import Journal from "views/admin/accounts/journal/Journal";
// import Bank from "views/admin/accounts/Bank/Bank";
// import Transaction from "views/admin/accounts/Transaction/Transaction";
// import COA from "views/admin/accounts/COA/COA";
import {
  MdHome,
  MdBarChart,
  MdOutlineLogout,
  MdPerson,
  MdShareLocation,
  MdAddShoppingCart,
  MdWarehouse,
  MdManageAccounts,
  MdOutlineAddShoppingCart,
} from "react-icons/md";
import {
  BsPersonFillGear,
  BsFillBoxSeamFill,
  BsQrCode,
  BsFillCalendar2DateFill,
} from "react-icons/bs";
import {
  FaTruck,
  FaExchangeAlt,
  FaUserFriends,
  FaHandshake,
  FaReceipt,
} from "react-icons/fa";
import { AiOutlineLineChart, AiFillCreditCard } from "react-icons/ai";
import { HiDocumentReport } from "react-icons/hi";
import { PiPaperclipDuotone } from "react-icons/pi";
import { TbDropletHalf2Filled } from "react-icons/tb";
import { RiBankFill } from "react-icons/ri";
import { FaRegNewspaper, FaCoins, FaMoneyBillTransfer } from "react-icons/fa6";
import { IoIosPaper } from "react-icons/io";
import { BiSolidBank } from "react-icons/bi";

const routes = [
  {
    name: "Dashboard ",
    layout: "/dashboard",
    path: "panel",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Assets Tracking",
    layout: "/admin",
    path: "assets-tracking",
    icon: <MdShareLocation className="h-6 w-6" />,
    subRoutes: [
      {
        name: "Items",
        layout: "/admin",
        path: "items",
        icon: <BsFillBoxSeamFill className="h-5 w-5" />,
        component: <Items />,
      },
      {
        name: "Vehicle",
        layout: "/admin",
        path: "vehicle",
        icon: <FaTruck className="h-6 w-6" />,
        component: <Vehicle />,
      },
    ],
  },
  {
    name: "Vendor Management",
    layout: "/admin",
    icon: <BsPersonFillGear className="h-6 w-6" />,
    path: "vendor-management",
    component: <VendorManagement />,
  },
  {
    name: "Logistic Management",
    layout: "/admin",
    path: "logistic-management",
    icon: <MdBarChart className="h-6 w-6" />,
    component: <LogisticManagement />,
  },
  {
    name: "Sales/Refill",
    layout: "/admin",
    path: "trades",
    icon: <FaExchangeAlt className="h-5 w-5" />,
    subRoutes: [
      {
        name: "Sales",
        layout: "/admin",
        path: "sale",
        icon: <AiOutlineLineChart className="h-6 w-6" />,
        component: <Sales />,
      },
      {
        name: "Purchase",
        layout: "/admin",
        path: "purchase",
        icon: <MdAddShoppingCart className="h-6 w-6" />,
        component: <Purchase />,
      },
      {
        name: "Docket",
        layout: "/admin",
        path: "docket",
        icon: <FaReceipt className="h-5 w-5" />,
        component: <Refill />,
      },
    ],
  },
  {
    name: "Customer",
    layout: "/admin",
    path: "customer",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Customer />,
  },
  {
    name: "Warehouse",
    layout: "/admin",
    path: "warehouse",
    icon: <MdWarehouse className="h-6 w-6" />,
    component: <Warehouse />,
  },
  {
    name: "User",
    layout: "/admin",
    path: "user",
    icon: <FaUserFriends className="h-6 w-6" />,
    component: <Users />,
  },
  {
    name: "QR Code",
    layout: "/admin",
    path: "qr-code",
    icon: <BsQrCode className="h-5 w-5" />,
    component: <Warehouse />,
    disabled: true,
  },
  {
    name: "Reports",
    layout: "/admin",
    path: "reports",
    icon: <HiDocumentReport className="h-6 w-6" />,
    subRoutes: [
      {
        name: "Customer Report",
        layout: "/admin",
        path: "cr-report",
        icon: <PiPaperclipDuotone className="h-6 w-6" />,
        component: <CustomerReport />,
      },
      {
        name: "Vehicle Report",
        layout: "/admin",
        path: "vehicle-report",
        icon: <PiPaperclipDuotone className="h-6 w-6" />,
        component: <VehicleReport />,
      },
      {
        name: "Orders Report",
        layout: "/admin",
        path: "orders-report",
        icon: <PiPaperclipDuotone className="h-5 w-5" />,
        component: <OrderReport />,
      },
      {
        name: "Sale Growth Report",
        layout: "/admin",
        path: "sale-growth-report",
        icon: <PiPaperclipDuotone className="h-5 w-5" />,
        component: <SaleGrowthReport />,
      },
    ],
  },
  // {
  //   name: "Agreement",
  //   layout: "/admin",
  //   path: "agreement",
  //   icon: <HiDocumentReport className="h-6 w-6" />,
  //   component: <Customer />,
  //   disabled: true,
  // },
  // {
  //   name: "Customer Report",
  //   layout: "/reports",
  //   path: "customer-report",
  //   component: <CustomerRep />,
  //   disabled: true,
  // },
  // {
  //   name: "Accounts",
  //   layout: "/admin",
  //   path: "accounts",
  //   icon: <MdManageAccounts className="h-6 w-6" />,
  //   subRoutes: [
  //     {
  //       name: "Receipt Voucher",
  //       layout: "/admin",
  //       path: "receipt",
  //       icon: <FaRegNewspaper className="h-5 w-5" />,
  //       component: <Receipt />,
  //     },
  //     {
  //       name: "Payment Voucher",
  //       layout: "/admin",
  //       path: "payment",
  //       icon: <IoIosPaper className="h-6 w-6" />,
  //       component: <Payment />,
  //     },
  //     {
  //       name: "Bank Reconcilation",
  //       layout: "/admin",
  //       path: "reconcilation",
  //       icon: <CgCalendarDates className="h-6 w-6" />,
  //       component: <Reconcile />,
  //     },
  //     {
  //       name: "Journal Voucher",
  //       layout: "/admin",
  //       path: "jd",
  //       icon: <AiFillCreditCard className="h-6 w-6" />,
  //       component: <Journal />,
  //     },
  //     {
  //       name: "Bank Index",
  //       layout: "/admin",
  //       path: "bank",
  //       icon: <BiSolidBank className="h-6 w-6" />,
  //       component: <Bank />,
  //     },
  //     {
  //       name: "Transaction Index",
  //       layout: "/admin",
  //       path: "transaction",
  //       icon: <FaMoneyBillTransfer className="h-6 w-6" />,
  //       component: <Transaction />,
  //     },
  //     {
  //       name: "Charts of Accounts",
  //       layout: "/admin",
  //       path: "ChartsofAccounts",
  //       icon: <FaMoneyBillTransfer className="h-6 w-6" />,
  //       component: <COA />,
  //     },
  //   ],
  // },
];
export default routes;
