/* eslint-disable */
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import { useState } from "react";
import { AiOutlineDown } from "react-icons/ai";

// chakra imports

export function SidebarLinks(props) {
  const navigate = useNavigate();
  // const [openNestedRoutes, setOpenNestedRoutes] = useState(true);
  const [openNestedRoutes, setOpenNestedRoutes] = useState({
    "Assets Tracking": false,
    "Sales/Refill": false,
    Reports: false,
  });
  // Chakra color mode
  let location = useLocation();

  window.addEventListener("keydown", (e) => {
    if (e.key == "F5") {
      e.preventDefault();
      navigate("/admin/trades/sale");
    }
  });
  window.addEventListener("keydown", (e) => {
    if (e.key == "F4") {
      e.preventDefault();
      navigate("/admin/trades/purchase");
    }
  });
  window.addEventListener("keydown", (e) => {
    if (e.key == "F8") {
      e.preventDefault();
      navigate("/admin/customer");
    }
  });

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const DynamicTransition = (routeName) => {
    setOpenNestedRoutes({
      ...openNestedRoutes,
      [routeName.name]: !openNestedRoutes[routeName.name],
    });
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (
        route.layout === "/admin" ||
        route.layout === "/dashboard" ||
        route.layout === "/auth"
      ) {
        if (route.subRoutes) {
          return (
            <div
              className="relative flex w-full flex-col hover:cursor-pointer"
              key={index}
            >
              <div
                onClick={() => {
                  // setOpenNestedRoutes(!openNestedRoutes),
                  DynamicTransition(route);
                }}
                className="relative mb-3 flex w-full items-center justify-between hover:cursor-pointer"
              >
                <li className="my-[3px] flex cursor-pointer items-center px-8">
                  <span
                    className={`${
                      activeRoute(route.path) === true
                        ? "font-bold text-brand-500 dark:text-white"
                        : "font-medium text-gray-600"
                    }`}
                  >
                    {route.icon ? route.icon : <DashIcon />}{" "}
                  </span>
                  <p
                    className={`leading-1 ml-4 flex ${
                      activeRoute(route.path) === true
                        ? "font-bold text-navy-700 dark:text-white"
                        : "font-medium text-gray-600"
                    }`}
                  >
                    {route.name}
                  </p>
                </li>
                <span
                  onClick={() => {
                    // setOpenNestedRoutes(!openNestedRoutes)

                    DynamicTransition(route);
                  }}
                  className={`transition ${
                    openNestedRoutes[route.name] ? "rotate-180" : null
                  } px-3  text-gray-600 duration-300`}
                >
                  <AiOutlineDown className="h-3 w-3" />
                </span>
                {activeRoute(route.path) ? (
                  <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                ) : null}
              </div>
              <div
                className={`relative ml-3 justify-between ${
                  openNestedRoutes[route.name] ? "h-full" : "h-0"
                }  hover:cursor-pointe overflow-hidden transition-[height] duration-300 ease-in-out`}
              >
                {route.subRoutes.map((subRoute, index) => {
                  return (
                    <div key={index}>
                      <Link
                        key={index}
                        to={`${route.layout}/${route.path}/${subRoute.path}`}
                      >
                        <div className="relative mb-3 flex hover:cursor-pointer">
                          <li
                            key={index}
                            className="my-[3px] flex cursor-pointer items-center px-8"
                          >
                            <span
                              className={`${
                                activeRoute(subRoute.path) === true
                                  ? "font-bold text-brand-500 dark:text-white"
                                  : "font-medium text-gray-600"
                              }`}
                            >
                              {subRoute.icon ? subRoute.icon : <DashIcon />}{" "}
                            </span>
                            <p
                              className={`leading-1 ml-4 flex ${
                                activeRoute(subRoute.path) === true
                                  ? "font-bold text-navy-700 dark:text-white"
                                  : "font-medium text-gray-600"
                              }`}
                            >
                              {subRoute.name}
                            </p>
                          </li>
                          {activeRoute(subRoute.path) ? (
                            <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                          ) : null}
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        } else if (route.disabled) {
          return (
            <Link key={index} to={""} style={{ pointerEvents: "none" }}>
              <div className="relative mb-3 flex hover:cursor-pointer">
                <li
                  className="my-[3px] flex cursor-pointer items-center px-8"
                  key={index}
                >
                  <span
                    className={`${
                      activeRoute(route.path) === true
                        ? "font-bold text-brand-500 dark:text-white"
                        : "font-medium text-gray-600"
                    }`}
                  >
                    {route.icon ? route.icon : <DashIcon />}{" "}
                  </span>
                  <p
                    className={`leading-1 ml-4 flex ${
                      activeRoute(route.path) === true
                        ? "font-bold text-navy-700 dark:text-white"
                        : "font-medium text-gray-600"
                    }`}
                  >
                    {route.name}
                  </p>
                </li>
                {activeRoute(route.path) ? (
                  <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                ) : null}
              </div>
            </Link>
          );
        } else {
          return (
            <Link key={index} to={route.layout + "/" + route.path}>
              <div className="relative mb-3 flex hover:cursor-pointer">
                <li
                  className="my-[3px] flex cursor-pointer items-center px-8"
                  key={index}
                >
                  <span
                    className={`${
                      activeRoute(route.path) === true
                        ? "font-bold text-brand-500 dark:text-white"
                        : "font-medium text-gray-600"
                    }`}
                  >
                    {route.icon ? route.icon : <DashIcon />}{" "}
                  </span>
                  <p
                    className={`leading-1 ml-4 flex ${
                      activeRoute(route.path) === true
                        ? "font-bold text-navy-700 dark:text-white"
                        : "font-medium text-gray-600"
                    }`}
                  >
                    {route.name}
                  </p>
                </li>
                {activeRoute(route.path) ? (
                  <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                ) : null}
              </div>
            </Link>
          );
        }
      }
    });
  };
  return createLinks(routes);
}
export default SidebarLinks;
