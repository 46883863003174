import { useContext, useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  IconButton,
  Tooltip,
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import moment from "moment";
import { AppContext } from "Context/AppContext";
import API from "API/API";
import PromptModal from "components/promptModal/PromptModal";

const LogisticGrid = (props) => {
  const { userData } = useContext(AppContext);
  const [tableData, setTableData] = useState(props.gridData);
  const [deleteUserDetails, setDeleteUserDetails] = useState({});
  const [successModal, setSuccessModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteRow = async (row) => {
    try {
      const res = await API().DeleteLogistic(
        row.DELIVERY_AREA,
        row.FK_VEHICLEID,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      handleClose();
      if (res.data.status) {
        setFeedbackMessage("Entry Deleted");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error While Deleting Data");
        setSuccessModal(true);
      }
    } catch (err) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const columns = useMemo(() => [
    {
      accessorKey: "REGISTRATION_NO",
      header: "Vehicle",
      size: 80,
    },
    {
      accessorKey: "area",
      header: "Delivery Area",
      size: 80,
    },
  ]);

  return (
    <>
      <PromptModal
        ModalState={successModal}
        feedbackMessage={feedbackMessage}
        onClick={() => {
          props.callLogisticDB();
          setSuccessModal(false);
        }}
      />
      {/* DELETE DIALOG */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Remove this Entry
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteRow(deleteUserDetails);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <MaterialReactTable
        muiTableBodyRowProps={{ sx: { background: "#EBECF0" } }}
        muiTopToolbarProps={{ sx: { background: "#EBECF0" } }}
        muiBottomToolbarProps={{ sx: { background: "#EBECF0" } }}
        muiTableHeadRowProps={{ sx: { background: "#EBECF0" } }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "left",
              sx: { padding: 0, paddingLeft: 2 },
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        enableColumnOrdering
        enableEditing
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton
                onClick={() => {
                  props.handleRowEdit(row.original);
                  props.openEditingMode(true);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton
                color="error"
                onClick={() => {
                  setDeleteUserDetails(row.original);
                  setOpen(true);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
    </>
  );
};

export default LogisticGrid;
