import moment from "moment";

const orderComment = ({ username, time, text, extraCss }) => {
  return (
    <>
      <div
        className={`my-2 flex h-16 w-full flex-col items-start justify-around overflow-hidden rounded-md border-b-[1px] border-b-gray-500 ${extraCss} px-2`}
      >
        <div className="flex w-full justify-between ">
          <span className="text-black text-sm dark:text-navy-700">
            {username}
          </span>
          <span className="text-black text-[9px] dark:text-navy-700">
            {moment(time).format("lll")}
          </span>
        </div>
        <div className="text-[10px] text-gray-500">{text}</div>
      </div>
    </>
  );
};

export default orderComment;
