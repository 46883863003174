import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Sidebar from "components/sidebar";
import routes from "routes.js";
import { FiAlignJustify } from "react-icons/fi";

export default function Admin() {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin" || prop.layout === "/dashboard") {
        if (prop.subRoutes) {
          return prop.subRoutes.map((subRoute, index) => {
            return (
              <Route
                path={`/${prop.path}/${subRoute.path}`}
                element={subRoute.component}
                key={index}
              />
            );
          });
        } else {
          return (
            <Route path={`/${prop.path}`} element={prop.component} key={key} />
          );
        }
      } else {
        return null;
      }
    });
  };

  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main className={` h-full flex-none transition-all  xl:ml-[314px]`}>
          <div className="absolute top-5 right-6 rounded-xl bg-lightPrimary p-3 transition duration-300 dark:!bg-navy-900 xl:hidden">
            <span
              className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
              onClick={() => setOpen(true)}
            >
              <FiAlignJustify className="h-5 w-5" />
            </span>
          </div>
          {/* Routes */}
          <div className="h-full">
            <div className="mx-auto mb-auto h-full p-2 pt-5 md:pr-2 lg:min-h-[84vh]">
              <Routes>
                {getRoutes(routes)}
                <Route
                  path="/"
                  element={<Navigate to="/dashboard/panel" replace />}
                />
              </Routes>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
