import { useContext, useState } from "react";
import image from "../../assets/img/layout/cyltraxlogo.png";
import { useFormik } from "formik";
import * as yup from "yup";
import API from "../../API/API";
import { AppContext } from "../../Context/AppContext";
import { useNavigate } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import PromptModal from "components/promptModal/PromptModal";

const SignIn = () => {
  const [authError, setAuthError] = useState(false);
  const [authErrorMessage, setAuthErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { setIsLogin, setUserData } = useContext(AppContext);
  const signIn = async (values) => {
    setIsLoading(true);
    try {
      const res = await API().Signin(values.email, values.password);
      if (!res.data.status) {
        setAuthError(true);
        setAuthErrorMessage(res.data.message);
        setIsLoading(false);
        setTimeout(() => {
          setAuthError(false);
        }, 5000);
      } else {
        sessionStorage.setItem("token", JSON.stringify(res.data.data[0]));
        setIsLogin(true);
        setUserData(res.data.data[0]);
        setIsLoading(false);
        navigate("/");
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const vehicleValidationSchema = yup.object({
    email: yup
      .string()
      .required("Make is Required")
      .min(2, "Min 2 Characters")
      .max(30, "Max 30 Characters"),
    password: yup
      .string()
      .required("Model is Required")
      .min(2, "Min 2 Characters")
      .max(100, "Max 100 Characters"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: vehicleValidationSchema,
    onSubmit: (values) => {
      signIn(values);
    },
  });
  return (
    <div className="flex min-h-full w-full max-w-3xl flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto" width={200} src={image} alt="Your Company" />
      </div>
      <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={formik.handleSubmit}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-navy-600"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                autoFocus
                name="email"
                type="email"
                autoComplete="email"
                onChange={formik.handleChange}
                required
                className="block w-full rounded-md border-0 py-1.5 px-1 text-navy-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-navy-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-navy-600"
              >
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                onChange={formik.handleChange}
                required
                className="block w-full rounded-md border-0 py-1.5 px-1 text-navy-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-navy-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-navy-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-navy-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-navy-600"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
      <Snackbar open={authError}>
        <Alert className="mx-auto mt-5 w-full max-w-sm" severity="error">
          {authErrorMessage}
        </Alert>
      </Snackbar>
      <PromptModal
        ModalState={isLoading}
        feedbackMessage={"Please Wait ! Your Request Is In Process"}
      />
    </div>
  );
};

export default SignIn;
