import { useContext, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  IconButton,
  Tooltip,
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent,
  TextField,
  DialogContentText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from "@mui/material";
import moment from "moment";
import { Delete, Edit } from "@mui/icons-material";
import API from "API/API";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AppContext } from "Context/AppContext";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import PromptModal from "components/promptModal/PromptModal";

const PurchaseGrid = (props) => {
  const { userData } = useContext(AppContext);
  const [tableData, setTableData] = useState(props.gridData);
  const [dataToEdit, setDataToEdit] = useState({});
  const [deleteUserDetails, setDeleteUserDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const handleSaveRowEdits = async () => {
    handleEditDialogClose();
    try {
      const res = await API().UpdatePurchase(
        dataToEdit.CURRENCY,
        dataToEdit.DELIVERY_FROM,
        dataToEdit.DELIVERY_TO,
        dataToEdit.DUE_DATE,
        dataToEdit.FK_VENDORID,
        dataToEdit.INVOICE_DATE,
        dataToEdit.INVOICE_NO,
        dataToEdit.LPO_REF,
        dataToEdit.PURCHASEID,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      if (res.data.status) {
        setFeedbackMessage("Bill Updated");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error While Editing Data");
        setSuccessModal(true);
      }
    } catch (err) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const editConfirmation = () => {
    setEditOpen(true);
  };

  const fetchBill = async (data) => {
    try {
      const res = await API().FetchBill(
        data.CURRENCY,
        data.DELIVERY_FROM,
        data.DELIVERY_TO,
        data.DUE_DATE,
        data.FK_VENDORID,
        data.INVOICE_DATE,
        data.INVOICE_NO,
        data.LPO_REF,
        data.PURCHASEID,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      if (res.data.status) {
        console.log("Purchase Added Succesfully");
      } else {
        console.log("Error Adding Data");
      }
    } catch (error) {
      console.log("Please Check your Internet");
    }
  };

  const handleCancelRowEdits = () => {};

  const inputCustomStyle = {
    "& .MuiInputBase-root": {
      "&:after": {
        borderBottom: "2px solid #24388a",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#fff",
      margin: 0,
      paddingTop: 0.5,
    },
  };

  const handleDeleteRow = async (row) => {
    try {
      const res = await API().DeletePurchase(
        row.CURRENCY,
        row.DELIVERY_FROM,
        row.DELIVERY_TO,
        row.DUE_DATE,
        row.FK_VENDORID,
        row.INVOICE_DATE,
        row.INVOICE_NO,
        row.LPO_REF,
        row.PURCHASEID,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      handleClose();
      if (res.data.status) {
        setFeedbackMessage("Bill Deleted");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error While Deleting Data");
        setSuccessModal(true);
      }
    } catch (err) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditDialogClose = () => {
    setEditOpen(false);
  };
  const handleEditDataChange = (name, value) => {
    setDataToEdit({ ...dataToEdit, [name]: value });
  };

  const columns = useMemo(() => [
    {
      accessorKey: "INVOICE_NO",
      header: "Invoice No.",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="INVOICE_NO"
          sx={inputCustomStyle}
          fullWidth
          name="INVOICE_NO"
          autoFocus
          label="Invoice No."
          value={dataToEdit.INVOICE_NO}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      accessorFn: (row) => moment(row.INVOICE_DATE).format("DD-MM-YYYY"),
      accessorKey: "INVOICE_DATE",
      header: "Invoice Date",
      size: 80,
      Edit: ({ cell }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Invoice Date"
            format="DD-MM-YYYY"
            value={moment(dataToEdit.INVOICE_DATE)}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: false,
                variant: "standard",
                sx: inputCustomStyle,
              },
            }}
            onChange={(newValue) =>
              handleEditDataChange(
                "INVOICE_DATE",
                moment(newValue).format("lll")
              )
            }
          />
        </LocalizationProvider>
      ),
    },
    {
      accessorFn: (row) => moment(row.DUE_DATE).format("DD-MM-YYYY"),
      accessorKey: "DUE_DATE",
      header: "Due Date",
      size: 80,
      Edit: ({ cell }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Due Date"
            format="DD-MM-YYYY"
            value={moment(dataToEdit.DUE_DATE)}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: false,
                variant: "standard",
                sx: inputCustomStyle,
              },
            }}
            onChange={(newValue) =>
              handleEditDataChange("DUE_DATE", moment(newValue).format("lll"))
            }
          />
        </LocalizationProvider>
      ),
    },
    {
      accessorKey: "FK_VENDORID",
      header: "Vendor ID",
      size: 80,
      Edit: ({ cell }) => (
        <FormControl
          fullWidth
          variant="standard"
          size="small"
          sx={inputCustomStyle}
        >
          <InputLabel id="fkVendorId">Vendor ID</InputLabel>
          <Select
            labelId="fkVendorId"
            id="FK_VENDORID"
            name="FK_VENDORID"
            value={dataToEdit.FK_VENDORID}
            onChange={(e) => {
              handleEditDataChange(e.target.name, e.target.value);
            }}
            fullWidth
            label="Vendor ID"
            inputProps={{
              "aria-label": "Without label",
            }}
            size="small"
          >
            {props.vendorList.map((e, i) => (
              <MenuItem key={i} value={e.VENDORID}>
                {e.COMPANY_NAME}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
    },
    {
      accessorFn: (row) => moment(row.DELIVERY_FROM).format("DD-MM-YYYY"),
      accessorKey: "DELIVERY_FROM",
      header: "Delivery Date From",
      size: 80,
      Edit: ({ cell }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Delivery Date From"
            format="DD-MM-YYYY"
            value={moment(dataToEdit.DELIVERY_FROM)}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: false,
                variant: "standard",
                sx: inputCustomStyle,
              },
            }}
            onChange={(newValue) =>
              handleEditDataChange(
                "DELIVERY_FROM",
                moment(newValue).format("lll")
              )
            }
          />
        </LocalizationProvider>
      ),
    },
    {
      accessorFn: (row) => moment(row.DELIVERY_TO).format("DD-MM-YYYY"),
      accessorKey: "DELIVERY_TO",
      header: "Delivery Date To",
      size: 80,
      Edit: ({ cell }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Delivery Date To"
            format="DD-MM-YYYY"
            value={moment(dataToEdit.DELIVERY_TO)}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: false,
                variant: "standard",
                sx: inputCustomStyle,
              },
            }}
            onChange={(newValue) =>
              handleEditDataChange(
                "DELIVERY_TO",
                moment(newValue).format("lll")
              )
            }
          />
        </LocalizationProvider>
      ),
    },
    {
      accessorKey: "CURRENCY",
      header: "Currency",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="CURRENCY"
          sx={inputCustomStyle}
          fullWidth
          name="CURRENCY"
          autoFocus
          label="Currency"
          value={dataToEdit.CURRENCY}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      accessorKey: "LPO_REF",
      header: "LPO Ref",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="LPO_REF"
          sx={inputCustomStyle}
          fullWidth
          name="LPO_REF"
          autoFocus
          label="LPO Ref"
          value={dataToEdit.LPO_REF}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      accessorFn: (row) => (
        <button
          onClick={() => fetchBill(row)}
          className="mr-2 transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none sm:mr-0"
        >
          Generate Bill
        </button>
      ),
      accessorKey: "PURCHASEID",
      header: "Generate Bill",
      size: 80,
      // Edit: ({ cell }) => (
      //   <TextField
      //     id="LPO_REF"
      //     sx={inputCustomStyle}
      //     fullWidth
      //     name="LPO_REF"
      //     autoFocus
      //     label="LPO Ref"
      //     value={dataToEdit.LPO_REF}
      //     onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
      //     variant="standard"
      //   />
      // ),
    },
  ]);

  return (
    <>
      <PromptModal
        ModalState={successModal}
        feedbackMessage={feedbackMessage}
        onClick={() => {
          setSuccessModal(false);
          props.callPurchaseDB();
        }}
      />
      {/* DELETE DIALOG */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Remove this Item
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteRow(deleteUserDetails);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* UPDATE DIALOG */}
      <Dialog
        open={editOpen}
        onClose={handleEditDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Save/Update?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Update this Item
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleEditDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSaveRowEdits(dataToEdit);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <MaterialReactTable
        muiTableBodyRowProps={{ sx: { background: "#EBECF0" } }}
        muiTopToolbarProps={{ sx: { background: "#EBECF0" } }}
        muiBottomToolbarProps={{ sx: { background: "#EBECF0" } }}
        muiTableHeadRowProps={{ sx: { background: "#EBECF0" } }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        onEditingRowSave={editConfirmation}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton
                onClick={() => {
                  setDataToEdit(row.original);
                  table.setEditingRow(row);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton
                color="error"
                onClick={() => {
                  setDeleteUserDetails(row.original);
                  setOpen(true);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
    </>
  );
};

export default PurchaseGrid;
