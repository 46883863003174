import {
  Select,
  MenuItem,
  InputLabel,
  TextField,
  FormControl,
  Box,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";
import { useState, useMemo, useContext, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import API from "API/API";
import moment from "moment";
import { AppContext } from "Context/AppContext";
import Loading from "components/loading";
import PromptModal from "components/promptModal/PromptModal";

const LogisticForm = (props) => {
  const { userData } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [vehicles, setVehicles] = useState(props.vehicleList);
  const [tableData, setTableData] = useState([{ DELIVERY_AREA: "" }]);
  const [rowIndex, setRowIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  useEffect(() => {
    if (props.editingMode) {
      setTableData(props.formData.DELIVERY_AREA);
      setRowIndex(props.formData.DELIVERY_AREA.length - 1);
    }
  }, [props.editingMode]);

  // useEffect(() => {
  //   setTableData([{ DELIVERY_AREA: "" }]);
  // }, [props.formData]);

  const inputCustomStyle = {
    "& .MuiInputBase-root": {
      "&:after": {
        borderBottom: "2px solid #24388a",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
  };

  const handleSubmit = async (formData) => {
    setIsLoading(true);
    handleDialogClose();
    if (formData.DELIVERY_AREA)
      formData.DELIVERY_AREA = formData.DELIVERY_AREA.filter(
        (e) => e.DELIVERY_AREA
      );
    try {
      const res = await API().AddLogistic(
        formData.FK_VEHICLEID,
        formData.DELIVERY_AREA,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      if (res.data.status) {
        setFeedbackMessage("Logistic Added Succesfully");
        setSuccessModal(true);
        props.emptyFieldsTrue();
      } else {
        setFeedbackMessage("Error Adding Data");
        setSuccessModal(true);
      }
    } catch (error) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const handleSaveCell = (cell, value) => {
    tableData[cell.row.index][cell.column.id] = value;
    props.setFormData({ ...props.formData, DELIVERY_AREA: tableData });
  };

  const handleCancelEdit = () => {
    props.emptyFields();
  };

  const handleEditSubmit = async (formData) => {
    handleEditDialogClose();
    formData.DELIVERY_AREA = formData.DELIVERY_AREA.map((element, i) => {
      return element.DELIVERY_AREA == "" && element.LOGISTICID == 0
        ? { ...element, STATUS: 9 }
        : element.DELIVERY_AREA == ""
        ? { ...element, STATUS: 9 }
        : { ...element, STATUS: 1 };
    });
    try {
      const res = await API().UpdateLogistic(
        formData.FK_VEHICLEID,
        formData.DELIVERY_AREA,
        formData.STATUS,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      if (res.data.status) {
        setFeedbackMessage("Logistic Updated");
        setSuccessModal(true);
        props.emptyFieldsTrue();
      } else {
        setFeedbackMessage("Error While Editing Data");
        setSuccessModal(true);
      }
    } catch (error) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const editConfirmation = ({ exitEditingMode }) => {
    setEditOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleEditDialogClose = () => {
    setEditOpen(false);
  };

  const columns = useMemo(() => [
    {
      accessorKey: "DELIVERY_AREA",
      header: "Delivery Area",
      Edit: ({ cell }) => (
        <TextField
          size="small"
          variant="standard"
          fullWidth
          sx={inputCustomStyle}
          id="DELIVERY_AREA"
          value={cell.row.original.DELIVERY_AREA}
          onChange={(e) => {
            if (
              cell.row.index == rowIndex &&
              e.target.value.length > 0 &&
              !props.editingMode
            ) {
              setTableData([...tableData, { DELIVERY_AREA: "" }]);
              setRowIndex(rowIndex + 1);
            } else if (
              cell.row.index == rowIndex &&
              e.target.value.length > 0 &&
              props.editingMode
            ) {
              setTableData([
                ...tableData,
                { LOGISTICID: 0, DELIVERY_AREA: "" },
              ]);
              setRowIndex(rowIndex + 1);
            }
            handleSaveCell(cell, e.target.value);
          }}
        />
      ),
    },
  ]);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        className={`absolute w-full px-10 ${
          isLoading ? "flex" : "hidden"
        } z-50 h-full flex-col items-center justify-center backdrop-blur-sm`}
      >
        <Loading />
      </div>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your Intention to Save/Update ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Add this Vendor
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSubmit(props.formData);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={editOpen}
        onClose={handleEditDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your Intention to Save/Update ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Update this Vendor
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleEditDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleEditSubmit(props.formData);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <PromptModal
        ModalState={successModal}
        feedbackMessage={feedbackMessage}
        onClick={() => {
          props.callLogisticDB();
          setSuccessModal(false);
        }}
      />
      <section className="w-full max-w-5xl rounded-t-2xl border border-gray-300 bg-[#EBECF0] p-6 shadow-2xl dark:bg-navy-800 md:p-16">
        <form className="w-full" onSubmit={(e) => e.preventDefault()}>
          <div className="flex w-full flex-col">
            <div className="flex w-full justify-center rounded-xl bg-[#f6f6f6] py-4 px-1 shadow-xl sm:px-4 md:py-10 ">
              <div className="grid w-full grid-cols-1 gap-5 px-4 py-4 lg:mt-4 lg:px-10">
                <div>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#1b3bbb",
                        },
                        "& fieldset": {
                          borderColor: "#1b3bbb",
                        },
                        "&:hover fieldset": {
                          borderColor: "#1b3bbb",
                        },
                      },
                      "& label.Mui-focused": {
                        color: "#24388a",
                      },
                      background: "#fff",
                    }}
                    slotprops={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                      },
                    }}
                  >
                    <InputLabel id="vehicle-id">Vehicle Number</InputLabel>
                    <Select
                      labelId="vehicle-id"
                      id="FK_VEHICLEID"
                      label="Vehicle Number"
                      value={props.formData.FK_VEHICLEID}
                      onChange={(e) => {
                        props.setFormData({
                          ...props.formData,
                          FK_VEHICLEID: e.target.value,
                        });
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      size="small"
                    >
                      {vehicles.map((item, i) => (
                        <MenuItem key={i} value={item.ID}>
                          {item.MAKE} - {item.MODAL} - {item.REGISTRATION_NO}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="my-10">
              <div className="mt-10 flex w-full justify-center rounded-xl bg-[#f6f6f6] py-4 px-4 shadow-xl sm:px-8 md:py-10 md:px-10 lg:mx-1 lg:mt-0 lg:px-10">
                <div className="grid h-48 w-full grid-cols-1 overflow-y-scroll scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 scrollbar-thumb-rounded ">
                  <MaterialReactTable
                    columns={columns}
                    enableTopToolbar={false}
                    data={tableData}
                    muiTablePaperProps={{ sx: { borderRadius: "10px" } }}
                    muiTableContainerProps={{ sx: { borderRadius: "10px" } }}
                    editingMode="table"
                    autoResetPageIndex={false}
                    enableEditing
                  />
                </div>
              </div>
            </div>
          </div>
          <div className=" flex justify-end">
            {props.editingMode ? (
              <div>
                <button
                  onClick={() => handleCancelEdit()}
                  className="mr-2 transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none"
                >
                  Cancel
                </button>
                <button
                  onClick={editConfirmation}
                  className="transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none"
                >
                  Done
                </button>
              </div>
            ) : (
              <button
                onClick={() => setOpen(true)}
                className="mr-2 transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none sm:mr-0"
              >
                Submit
              </button>
            )}
          </div>
        </form>
      </section>
    </Box>
  );
};

export default LogisticForm;
