import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
const Header = (props) => {
  return (
    <>
      <View style={styles.header}>
        <View style={styles.headerBody}>
          <View style={styles.headerHalf}>
            <View style={styles.headerLeft}>
              <View style={styles.sideDesign} />
              <View style={styles.companyDetailsCont}>
                <Image
                  style={styles.img}
                  source={require("assets/img/layout/ABUSAADLOGO.png")}
                />
                <Text style={styles.headerText}>6HJR+6Q3, Manama, Bahrain</Text>
              </View>
            </View>
          </View>
          <View style={styles.headerHalf}>
            <View style={styles.headerRight}>
              <View style={styles.headerRightOuter}>
                <Text style={styles.headerRightInner}>{props.reportName}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};
export default Header;
const styles = StyleSheet.create({
  header: {
    marginVertical: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  headerBody: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  headerHalf: { width: "50%" },
  headerLeft: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  sideDesign: {
    height: "100px",
    backgroundColor: "#0067A5",
    width: "5%",
  },
  headerText: { marginTop: 10 },
  companyDetailsCont: {
    fontSize: 10,
    marginLeft: 5,
  },
  img: {
    width: 150,
    justifyContent: "flex-start",
  },
  headerRight: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  headerRightOuter: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#0067A5",
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
  },
  headerRightInner: { fontSize: 25, fontWeight: "extrabold", color: "#fff" },
});
