import WeeklyRevenue from "views/admin/dashboard/components/WeeklyRevenue";
import TotalSpent from "views/admin/dashboard/components/TotalSpent";
import { FaTruck } from "react-icons/fa";
import { BsCartCheckFill, BsCartXFill } from "react-icons/bs";
import Widget from "components/widget/Widget";
import OrderCard from "./components/OrderCard";
import FirstCard from "./components/DashboardCard";
import OfflineCustomerCard from "views/admin/dashboard/components/OfflineCustomerCard";
import Card from "components/card";
import OrderComment from "./components/OrderComment";
import AdvanceOrderCard from "./components/AdvanceOrder";
import { useEffect, useMemo, useState, useLayoutEffect, Suspense } from "react";
import API from "API/API";
import { Skeleton } from "@mui/material";

const Dashboard = () => {
  const [vehicleCards, setVehicleCards] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const [todayOrders, setTodayOrders] = useState([]);
  const [advanceOrders, setAdvanceOrders] = useState([]);
  const [offlineCustomers, setOfflineCustomers] = useState([]);
  const [dailySales, setDailySales] = useState([]);
  const [salesGrowth, setSalesGrowth] = useState([]);
  const [cardSkeleton, setCardSkeleton] = useState(true);
  const [chartCardSkeleton, setChartCardSkeleton] = useState(true);
  const [barChartCardSkeleton, setBarChartCardSkeleton] = useState(true);
  const [pendingDeliverySkeleton, setPendingDeliverySkeleton] = useState(true);
  const [confirmDeliverySkeleton, setConfirmDeliverySkeleton] = useState(true);
  const [todayOrderSkeleton, setTodayOrderSkeleton] = useState(true);
  const [offlineCustomerSkeleton, setOfflineCustomerSkeleton] = useState(true);
  const [advanceOrderSkeleton, setAdvanceOrderSkeleton] = useState(true);

  // const vehiclesCard = [
  //   {
  //     vehicleNumber: "C827",
  //     cylinderQuantity: "234",
  //     route: "Abc Road, Nyc",
  //     lastDelivery: "32mins ago",
  //     vehicleColor: "text-red-400",
  //     total20lbsCylinder: 20,
  //     used20lbsCylinder: 10,
  //     total40lbsCylinder: 25,
  //     used40lbsCylinder: 12,
  //     total100lbsCylinder: 30,
  //     used100lbsCylinder: 15,
  //   },
  //   {
  //     vehicleNumber: "AD285",
  //     cylinderQuantity: "24",
  //     lastDelivery: "32mins ago",
  //     route: "Abc Road, Nyc",
  //     vehicleColor: "text-green-400",
  //     total20lbsCylinder: 20,
  //     used20lbsCylinder: 10,
  //     total40lbsCylinder: 25,
  //     used40lbsCylinder: 12,
  //     total100lbsCylinder: 30,
  //     used100lbsCylinder: 15,
  //   },
  //   {
  //     vehicleNumber: "AD235",
  //     cylinderQuantity: "254",
  //     lastDelivery: "32mins ago",
  //     route: "Abc Road, Nyc",
  //     vehicleColor: "text-lime-400",
  //     total20lbsCylinder: 20,
  //     used20lbsCylinder: 10,
  //     total40lbsCylinder: 25,
  //     used40lbsCylinder: 12,
  //     total100lbsCylinder: 30,
  //     used100lbsCylinder: 15,
  //   },
  //   {
  //     vehicleNumber: "S5464",
  //     cylinderQuantity: "224",
  //     lastDelivery: "32mins ago",
  //     route: "Abc Road, Nyc",
  //     vehicleColor: "text-blue-400",
  //     total20lbsCylinder: 20,
  //     used20lbsCylinder: 10,
  //     total40lbsCylinder: 25,
  //     used40lbsCylinder: 12,
  //     total100lbsCylinder: 30,
  //     used100lbsCylinder: 15,
  //   },
  //   {
  //     vehicleNumber: "V89234",
  //     cylinderQuantity: "264",
  //     lastDelivery: "32mins ago",
  //     route: "Abc Road, Nyc",
  //     vehicleColor: "text-lime-400",
  //     total20lbsCylinder: 20,
  //     used20lbsCylinder: 10,
  //     total40lbsCylinder: 25,
  //     used40lbsCylinder: 12,
  //     total100lbsCylinder: 30,
  //     used100lbsCylinder: 15,
  //   },
  //   {
  //     vehicleNumber: "C827",
  //     cylinderQuantity: "234",
  //     lastDelivery: "32mins ago",
  //     route: "Abc Road, Nyc",
  //     vehicleColor: "text-green-400",
  //     total20lbsCylinder: 20,
  //     used20lbsCylinder: 10,
  //     total40lbsCylinder: 25,
  //     used40lbsCylinder: 12,
  //     total100lbsCylinder: 30,
  //     used100lbsCylinder: 15,
  //   },
  //   {
  //     vehicleNumber: "AD285",
  //     cylinderQuantity: "24",
  //     lastDelivery: "32mins ago",
  //     route: "Abc Road, Nyc",
  //     vehicleColor: "text-blue-400",
  //     total20lbsCylinder: 20,
  //     used20lbsCylinder: 10,
  //     total40lbsCylinder: 25,
  //     used40lbsCylinder: 12,
  //     total100lbsCylinder: 30,
  //     used100lbsCylinder: 15,
  //   },
  //   {
  //     vehicleNumber: "AD235",
  //     cylinderQuantity: "254",
  //     lastDelivery: "32mins ago",
  //     route: "Abc Road, Nyc",
  //     vehicleColor: "text-blue-400",
  //     total20lbsCylinder: 20,
  //     used20lbsCylinder: 10,
  //     total40lbsCylinder: 25,
  //     used40lbsCylinder: 12,
  //     total100lbsCylinder: 30,
  //     used100lbsCylinder: 15,
  //   },
  //   {
  //     vehicleNumber: "S5464",
  //     cylinderQuantity: "224",
  //     lastDelivery: "32mins ago",
  //     route: "Abc Road, Nyc",
  //     vehicleColor: "text-blue-400",
  //     total20lbsCylinder: 20,
  //     used20lbsCylinder: 10,
  //     total40lbsCylinder: 25,
  //     used40lbsCylinder: 12,
  //     total100lbsCylinder: 30,
  //     used100lbsCylinder: 15,
  //   },
  //   {
  //     vehicleNumber: "V89234",
  //     cylinderQuantity: "264",
  //     lastDelivery: "32mins ago",
  //     route: "Abc Road, Nyc",
  //     vehicleColor: "text-blue-400",
  //     total20lbsCylinder: 20,
  //     used20lbsCylinder: 10,
  //     total40lbsCylinder: 25,
  //     used40lbsCylinder: 12,
  //     total100lbsCylinder: 30,
  //     used100lbsCylinder: 15,
  //   },
  // ];

  const FetchVehicleCards = async () => {
    try {
      const res = await API().GetDashboardVehicleCards();
      if (res.data.status) {
        setVehicleCards(res.data.data);
        setCardSkeleton(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const FetchPendingOrders = async () => {
    try {
      const res = await API().GetPendingOrders();
      if (res.data.status) {
        setPendingOrders(res.data.data);
        setPendingDeliverySkeleton(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const FetchDeliveredOrders = async () => {
    try {
      const res = await API().GetDeliveredOrders();
      if (res.data.status) {
        setDeliveredOrders(res.data.data);
        setConfirmDeliverySkeleton(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const FetchTodayOrders = async () => {
    try {
      const res = await API().GetTodayOrders();
      if (res.data.status) {
        setTodayOrders(res.data.data);
        setTodayOrderSkeleton(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const FetchAdvanceOrders = async () => {
    try {
      const res = await API().GetAdvanceOrders();
      if (res.data.status) {
        setAdvanceOrders(res.data.data);
        setAdvanceOrderSkeleton(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const FetchOfflineCustomers = async () => {
    try {
      const res = await API().GetOfflineCustomers();
      if (res.data.status) {
        setOfflineCustomers(res.data.data);
        setOfflineCustomerSkeleton(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const FetchDailySales = async () => {
    try {
      const res = await API().GetDailySales();
      if (res.data.status) {
        setChartCardSkeleton(false);
        setDailySales(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const FetchSalesGrowth = async () => {
    try {
      const res = await API().GetSalesGrowth();
      if (res.data.status) {
        setSalesGrowth(res.data.data);
        setBarChartCardSkeleton(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    FetchVehicleCards();
    FetchPendingOrders();
    FetchDeliveredOrders();
    FetchTodayOrders();
    FetchAdvanceOrders();
    FetchOfflineCustomers();
    FetchDailySales();
    FetchSalesGrowth();
  }, []);

  useEffect(() => {
    let IntervalForAPI = setInterval(() => {
      FetchVehicleCards();
      FetchPendingOrders();
      FetchDeliveredOrders();
      FetchTodayOrders();
      FetchOfflineCustomers();
      FetchAdvanceOrders();
      FetchDailySales();
      FetchSalesGrowth();
    }, 30000);
    return () => clearInterval(IntervalForAPI);
  }, []);

  return (
    <>
      <div className=" flex w-full flex-col lg:flex-row">
        <div className="flex w-full flex-col px-2 ">
          <div className="mt-4 flex flex-col items-center lg:flex-row">
            <div className="mb-4 mr-2 w-full lg:mb-0 lg:w-18p ">
              <FirstCard
                icon={<FaTruck className="h-4 w-4" />}
                totalCylinder={"2413"}
                totalVehicle={"234"}
                extra={`bg-[#002147]`}
              />
            </div>
            <div className="- my-2 h-60 w-full lg:w-62p lg:pr-2 ">
              {cardSkeleton ? (
                <div className=" grid h-full auto-cols-[100%] grid-flow-col items-center overflow-y-hidden overflow-x-scroll scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 scrollbar-thumb-rounded sm:auto-cols-[50%] lg:auto-cols-[33.33%]">
                  <div className="h-full w-full p-2 py-4">
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div className="h-full w-full p-2 py-4">
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div className="h-full w-full p-2 py-4">
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
              ) : (
                <div className=" grid h-full auto-cols-[100%] grid-flow-col items-center overflow-y-hidden overflow-x-scroll scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 scrollbar-thumb-rounded sm:auto-cols-[50%] lg:auto-cols-[33.33%]">
                  {vehicleCards.map((i, index) => {
                    return (
                      <Widget
                        key={index}
                        icon={
                          <FaTruck
                            style={{ stroke: "#6b7280", strokeWidth: "30" }}
                            className="h-6 w-6 "
                          />
                        }
                        vehicleNum={i.REGISTRATION_NO}
                        cylinderQuantity={"103"}
                        route={"abc Road, bahrain"}
                        vehicleColor={i.COLOR}
                        lastDelivery={"32 mins Ago"}
                        cylinderName={i.ABBREVIATION}
                        cylinderQty={i.QTY}
                        //showInfoCard={() => setInfoCard(true)}
                        extra={`mx-2 `}
                      />
                    );
                  })}
                </div>
              )}
            </div>
            <div className="hidden h-60  lg:flex lg:w-20p">
              <Card extra="mt-3 w-full overflow-x-hidden h-52">
                <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-2 pt-4 pb-[15px] shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
                  <h4 className="text-lg font-bold text-navy-700 dark:text-white">
                    Pending Delivery
                  </h4>
                </div>
                {pendingDeliverySkeleton ? (
                  <div className=" flex h-full w-full ">
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height="100%"
                      width="100%"
                    />
                  </div>
                ) : pendingOrders.length ? (
                  <div className=" h-full overflow-x-hidden overflow-y-scroll py-2 px-2 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 scrollbar-thumb-rounded">
                    {pendingOrders.map((i, index) => {
                      return (
                        <OrderComment
                          key={index}
                          text={i.ITEMS}
                          extraCss={`bg-gradient-to-r from-pink-50 to-purple-100`}
                          username={i.NAME}
                          time={i.time}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div className="flex h-full w-full items-center justify-center text-sm text-gray-500">
                    No Pending Orders...
                  </div>
                )}
              </Card>
            </div>
          </div>
          <div className="mt-4 flex flex-col items-center sm:flex-row">
            <div className="mb-3 w-full px-1 sm:mb-0 sm:w-3/6 lg:w-2/5 lg:pl-0 lg:pr-3">
              {chartCardSkeleton ? (
                <div className=" flex h-72 w-full p-2 py-4">
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    height="100%"
                    width="100%"
                  />
                </div>
              ) : (
                <TotalSpent chartData={salesGrowth} />
              )}
            </div>
            <div className="mb-3 w-full px-1 sm:mb-0 sm:w-3/6 lg:w-2/5 lg:pl-0 lg:pr-3">
              {barChartCardSkeleton ? (
                <div className=" flex h-72 w-full p-2 py-4">
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    height="100%"
                    width="100%"
                  />
                </div>
              ) : (
                <WeeklyRevenue chartData={dailySales} />
              )}
            </div>
            <div className="hidden h-72 lg:flex lg:w-1/5 ">
              <Card extra="w-full overflow-x-hidden h-full">
                <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-2 pt-4 pb-[15px] shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
                  <h4 className="text-lg  font-bold text-navy-700 dark:text-white">
                    Confirm Deliveries
                  </h4>
                </div>
                {confirmDeliverySkeleton ? (
                  <div className=" flex h-full w-full ">
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height="100%"
                      width="100%"
                    />
                  </div>
                ) : deliveredOrders.length ? (
                  <div className=" h-full overflow-x-hidden overflow-y-scroll py-2 px-2 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 scrollbar-thumb-rounded">
                    {deliveredOrders.map((i, index) => {
                      return (
                        <OrderComment
                          key={index}
                          text={i.ITEMS}
                          extraCss={`bg-gradient-to-r from-pink-50 to-purple-100`}
                          username={i.NAME}
                          time={i.time}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div className="flex h-full w-full items-center justify-center text-sm text-gray-500">
                    No Delivered Orders...
                  </div>
                )}
              </Card>
            </div>
          </div>
          <div className="mt-4 flex h-60 flex-col items-center sm:flex-row">
            <div className="mb-4 flex h-60 w-full px-1 sm:mb-0 sm:w-2/4 lg:w-2/5 lg:pl-0 lg:pr-3 ">
              {todayOrderSkeleton ? (
                <div className=" flex h-60 w-full p-2 py-4">
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    height="100%"
                    width="100%"
                  />
                </div>
              ) : (
                <>
                  <OrderCard
                    icon={<BsCartCheckFill className="h-5 w-5" />}
                    heading="Online Orders"
                    totalQuantity={todayOrders[0]?.ONLINERECEIVEDTODAY || 0}
                    deliveredQuantity={
                      todayOrders[0]?.ONLINEDELIVEREDTODAY || 0
                    }
                    extra={`!h-60 mr-2 !w-full md:w-40 lg:!w-48`}
                  />
                  <OrderCard
                    icon={<BsCartXFill className="h-5 w-5" />}
                    heading="Offline Orders"
                    totalQuantity={todayOrders[0]?.OFFLINERECEIVEDTODAY || 0}
                    deliveredQuantity={
                      todayOrders[0]?.OFFLINEDELIVEREDTODAY || 0
                    }
                    extra={`!h-60 ml-2 !w-full md:w-40 lg:!w-48`}
                  />
                </>
              )}
            </div>
            <div className="mb-4 grid h-60 w-full px-1 sm:mb-0 sm:w-2/4 lg:w-2/5 lg:pl-0 lg:pr-3 ">
              <Card extra="w-full overflow-x-hidden h-full">
                <div className="flex h-fit w-full items-center justify-between rounded-2xl bg-white px-2 pt-4 pb-[15px] shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
                  <h4 className="text-lg  font-bold text-navy-700 dark:text-white">
                    Offline Customers
                  </h4>
                </div>
                {offlineCustomerSkeleton ? (
                  <div className=" flex h-full w-full ">
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height="100%"
                      width="100%"
                    />
                  </div>
                ) : offlineCustomers.length ? (
                  <>
                    <div className="grid w-full grid-cols-5 justify-center px-3 text-sm font-bold text-gray-700 dark:text-white">
                      <span>Vehicle No.</span>
                      <span className="flex items-center justify-center">
                        Name
                      </span>
                      <span className="flex items-center justify-center">
                        Area
                      </span>
                      <span className="flex items-center justify-center">
                        Last Order
                      </span>
                      <span className="flex items-center justify-center">
                        Mob. Num.
                      </span>
                    </div>
                    <div className=" h-full  overflow-x-hidden overflow-y-scroll p-2 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 scrollbar-thumb-rounded">
                      <AdvanceOrderCard data={offlineCustomers} />
                    </div>
                  </>
                ) : (
                  <div className="flex h-full w-full items-center justify-center text-sm text-gray-500">
                    No Offline Customers.
                  </div>
                )}
              </Card>
            </div>
            <div className="hidden w-1/5 lg:flex ">
              <Card extra=" w-full overflow-x-hidden h-60">
                <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-2 pt-4 pb-[15px] shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
                  <h4 className="text-lg  font-bold text-navy-700 dark:text-white">
                    Advance Order
                  </h4>
                </div>
                {advanceOrderSkeleton ? (
                  <div className=" flex h-full w-full ">
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height="100%"
                      width="100%"
                    />
                  </div>
                ) : advanceOrders.length ? (
                  <>
                    <div className="flex items-center rounded-t-3xl py-1 pl-3 pr-3">
                      <div className="grid w-full grid-cols-3 justify-center text-sm font-bold text-gray-700 dark:text-white">
                        <span>ID</span>
                        <span>Name</span>
                        <span className="flex items-center justify-center">
                          Cont. No.
                        </span>
                      </div>
                    </div>
                    <div className=" h-full  overflow-x-hidden overflow-y-scroll  scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 scrollbar-thumb-rounded">
                      <OfflineCustomerCard data={advanceOrders} />
                    </div>
                  </>
                ) : (
                  <div className="flex h-full w-full items-center justify-center text-sm text-gray-500">
                    No Advance Orders.
                  </div>
                )}
              </Card>
            </div>
          </div>
        </div>
        <div className="mt-72 flex w-full flex-col px-2 md:mt-0 lg:hidden ">
          <div className=" my-2 h-60 ">
            <Card extra="mt-3 w-full overflow-x-hidden h-52">
              <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-2 pt-4 pb-[15px] shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
                <h4 className="text-lg font-bold text-navy-700 dark:text-white">
                  Pending Delivery
                </h4>
              </div>
              <div className=" h-full overflow-x-hidden overflow-y-scroll py-2 px-2 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 scrollbar-thumb-rounded">
                {pendingOrders.map((i, index) => {
                  return (
                    <OrderComment
                      key={index}
                      text={i.ITEMS}
                      extraCss={`bg-gradient-to-r from-pink-50 to-purple-100`}
                      username={i.NAME}
                      time={i.ORDEREDTIME}
                    />
                  );
                })}
              </div>
            </Card>
          </div>
          <div className=" my-2 h-60">
            <Card extra="w-full overflow-x-hidden h-full">
              <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-2 pt-4 pb-[15px] shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
                <h4 className="text-lg  font-bold text-navy-700 dark:text-white">
                  Confirm Deliveries
                </h4>
              </div>
              <div className=" h-full  overflow-x-hidden overflow-y-scroll p-2 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 scrollbar-thumb-rounded">
                {deliveredOrders.map((i, index) => {
                  return (
                    <OrderComment
                      key={index}
                      extraCss={`bg-gradient-to-r from-teal-100 to-green-200`}
                      text={i.ITEMS}
                      username={i.NAME}
                      time={i.ORDEREDTIME}
                      userId={i.userId}
                    />
                  );
                })}
              </div>
            </Card>
          </div>
          <div className=" my-2 h-60">
            <Card extra=" w-full overflow-x-hidden h-60">
              <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-2 pt-4 pb-[15px] shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
                <h4 className="text-lg  font-bold text-navy-700 dark:text-white">
                  Advance Order
                </h4>
              </div>
              {advanceOrders.length ? (
                <>
                  <div className="flex items-center rounded-t-3xl py-1 pl-3 pr-3">
                    <div className="grid w-full grid-cols-3 justify-center text-sm font-bold text-gray-700 dark:text-white">
                      <span>ID</span>
                      <span>Name</span>
                      <span className="flex items-center justify-center">
                        Cont. No.
                      </span>
                    </div>
                  </div>
                  <div className=" h-full  overflow-x-hidden overflow-y-scroll  scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 scrollbar-thumb-rounded">
                    <OfflineCustomerCard data={advanceOrders} />
                  </div>
                </>
              ) : (
                <div className="flex h-full w-full items-center justify-center text-sm text-gray-500">
                  No Pending Orders...
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
