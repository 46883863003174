import {
  TextField,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ABCReport from "./ABCReport";
import { useContext, useRef, useState } from "react";
import { AppContext } from "Context/AppContext";
import API from "API/API";
import moment from "moment";
import ReactPDFView from "../../../ReactPDF/ReactPDF";
import PromptModal from "components/promptModal/PromptModal";

const Remarks = (props) => {
  const { userData, setCustomerAddress } = useContext(AppContext);

  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const navigate = useNavigate();
  const linkRef = useRef(null);

  const inputCustomStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#1b3bbb",
      },
      "& fieldset": {
        borderColor: "#1b3bbb",
      },
      "&:hover fieldset": {
        borderColor: "#1b3bbb",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
    background: "#fff",
  };

  const handleSubmit = async (formData) => {
    handleDialogClose();
    setOpen(true);
    props.submitBlur(true);
    if (formData.address)
      formData.address = formData.address.filter((e) => e.flat_shop);
    try {
      const res = await API().AddCustomer(
        formData.address,
        formData.companyName,
        formData.CPR_NUMBER,
        formData.crNumber,
        formData.CURRENY,
        formData.CUSTOMER_TYPE,
        formData.EMAIL,
        formData.ENABLE_PORTAL,
        formData.FIRSTNAME,
        formData.LASTNAME,
        formData.MOBILE_NUMBER,
        formData.MODE_OF_PAYMENT,
        formData.PORTAL_LANGUAGE,
        formData.REFERRED_BY,
        formData.REMARKS,
        formData.REPORTING_TAGS,
        formData.TAX_TREATMENT,
        formData.TRN,
        formData.PASSWORD,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      if (res.data.status) {
        setFeedbackMessage("Saved Successfully");
        setSuccessModal(true);
        props.emptyFields();
      } else {
        setFeedbackMessage("Error While Saving Data");
        setSuccessModal(true);
      }
    } catch (error) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    } finally {
      props.submitBlur(false);
    }
  };

  const handleEditSubmit = async (formData) => {
    setEditOpen(false);
    handleEditDialogClose();
    props.submitBlur(true);
    if (formData.address)
      formData.address = formData.address.filter((e) => e.flat_shop);
    try {
      const res = await API().UpdateCustomer(
        formData.CUSTOMERID,
        formData.address,
        formData.CPR_NUMBER,
        formData.CURRENY,
        formData.CUSTOMER_TYPE,
        formData.EMAIL,
        formData.ENABLE_PORTAL,
        formData.FIRSTNAME,
        formData.LASTNAME,
        formData.MOBILE_NUMBER,
        formData.MODE_OF_PAYMENT,
        formData.PORTAL_LANGUAGE,
        formData.REFERRED_BY,
        formData.REMARKS,
        formData.REPORTING_TAGS,
        formData.TAX_TREATMENT,
        formData.TRN,
        formData.PASSWORD,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      if (res.data.status) {
        setFeedbackMessage("Updated Successfully");
        setSuccessModal(true);
        props.emptyFields();
        props.disableEditingMode();
      } else {
        setFeedbackMessage("Error While Updating Data");
        setSuccessModal(true);
      }
    } catch (error) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    } finally {
      props.submitBlur(false);
    }
  };

  const handleCancelEdit = () => {
    props.emptyFields();
    props.disableEditingMode();
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleEditDialogClose = () => {
    setEditOpen(false);
    handleCancelEdit();
  };

  return (
    <>
      <PromptModal
        ModalState={successModal}
        feedbackMessage={feedbackMessage}
        onClick={() => {
          props.callCustomerDB();
          setSuccessModal(false);
          props.resetTabValue();
        }}
      />
      <div className="flex w-full flex-col px-4">
        {/* SAVE DIALOG */}
        <Dialog
          open={open}
          onClose={handleDialogClose}
          fullWidth
          maxWidth="sm"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirm your Intention to Save/Update ?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Save this Customer
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={handleDialogClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSubmit(props.formData);
              }}
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        {/* EDIT DIALOG */}
        <Dialog
          open={editOpen}
          onClose={handleEditDialogClose}
          fullWidth
          maxWidth="sm"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirm your Intention to Save/Update ?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Update this Customer
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={handleEditDialogClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleEditSubmit(props.formData);
              }}
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <h4 className="mt-2 pb-3 text-2xl font-bold text-navy-500">Remarks</h4>
        <form
          className="mt-4 rounded-md border border-gray-300 p-8"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="my-4 grid grid-cols-1 gap-5 ">
            <div>
              <TextField
                fullWidth
                id="REMARKS"
                autoFocus
                label="Remarks"
                variant="outlined"
                name="REMARKS"
                value={props.formData.REMARKS}
                onChange={(e) => props.onChange(e)}
                multiline
                size="small"
                sx={inputCustomStyle}
              />
            </div>
            <div className="flex w-full justify-center">
              <ReactPDFView
                children={<ABCReport data={props.formData} />}
                buttonText="Agreement"
              />
            </div>
          </div>
        </form>
        <div className="my-6 flex w-full justify-between">
          <button
            onClick={props.onClickPrev}
            className="transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none"
          >
            Previous
          </button>
          {props.editingMode ? (
            <div>
              <button
                onClick={() => handleCancelEdit()}
                className="mr-2 transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none"
              >
                Cancel
              </button>
              <button
                onClick={() => setEditOpen(true)}
                className="transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none"
              >
                Done
              </button>
            </div>
          ) : (
            <button
              onClick={() => setOpen(true)}
              className="transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none"
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Remarks;
