import React, { useContext, useEffect, useState } from "react";
import { BlobProvider, PDFDownloadLink, usePDF } from "@react-pdf/renderer";
import moment from "moment";
import API from "API/API";
// Create Document Component
const ReactPDFView = (props) => {
  const [instance, updateInstance] = usePDF({ document: props?.children });

  if (props.sendEmail) {
    function sendEmail() {
      let file = new File(
        [instance],
        `${moment(new Date()).format("lll")} - ${props.reportName}.pdf`,
        {
          type: "application/pdf",
          lastModified: Date.now(),
        }
      );
      let formData = new FormData();
      formData.append("file", file);
    }
    return (
      <div
        onClick={sendEmail}
        className="transform cursor-pointer rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none"
      >
        {props.buttonText}
      </div>
    );
  } else {
    return (
      <BlobProvider document={props.children}>
        {({ url, ...rest }) => {
          return (
            <a
              className="transform cursor-pointer rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none"
              href={url}
              target="_blank"
            >
              {props.buttonText}
            </a>
          );
        }}
      </BlobProvider>
    );
  }
};

export default ReactPDFView;
