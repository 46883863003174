import { Box, CircularProgress } from "@mui/material";

function Loading() {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CircularProgress style={{ color: "#1b3bbb" }} />
      </Box>
    </>
  );
}

export default Loading;
