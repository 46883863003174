import { useEffect, useState } from "react";
import UserForm from "./components/UserForm";
import UserGrid from "./components/UserGrid";
import API from "../../../API/API";
import Loading from "../../../components/loading";
import { Backdrop } from "@mui/material";
import { MdOutlineRefresh } from "react-icons/md";

const Users = () => {
  const [Users, setUsers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorState, setErrorState] = useState(false);

  const FetchUsers = async () => {
    try {
      setIsLoading(true);
      const result = await API().GetUsers();
      if (result.data.status) {
        setUsers(result.data.data.sort((a, b) => a.USERID - b.USERID));
        FetchVehicles();
      } else {
        setErrorState(true);
        setIsLoading(false);
      }
    } catch (err) {
      setErrorState(true);
      setIsLoading(false);
    }
  };

  const FetchVehicles = async () => {
    try {
      const result = await API().GetVehicle();
      if (result.data.status) {
        setVehicles(result.data.data);
        setIsLoading(false);
      } else {
        setErrorState(true);
        setIsLoading(false);
      }
    } catch (err) {
      setErrorState(true);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    FetchUsers();
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <Backdrop
            sx={{
              color: "#fff",
              background: "#F4F7FE",
              position: "relative",
              zIndex: 5,
              height: "100vh",
            }}
            open={isLoading}
          >
            <Loading />
          </Backdrop>
        </>
      ) : (
        <>
          {errorState ? (
            <div className="flex h-[84vh] flex-col items-center justify-center">
              <span
                className="text-navy-600"
                onClick={() => {
                  setIsLoading(true);
                  setErrorState(false);
                  FetchUsers();
                }}
              >
                <MdOutlineRefresh className="h-28 w-28 -rotate-90 cursor-pointer hover:animate-spin" />
              </span>
              <p className="text-2xl font-bold text-navy-600">
                Server Error, Please Refresh
              </p>
            </div>
          ) : (
            <div className="-mt-5 flex w-full flex-col items-center rounded-b-2xl bg-white px-4">
              <h2 className="text-black flex w-full max-w-5xl py-5 text-3xl font-bold capitalize dark:text-white">
                Users
              </h2>
              <UserForm callUserDB={FetchUsers} vehicleList={vehicles} />
              <div className="mt-2 w-full max-w-5xl overflow-hidden rounded-b-2xl border border-navy-500  shadow-xl">
                <UserGrid
                  vehicleList={vehicles}
                  usersList={Users}
                  callUserDB={FetchUsers}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default Users;
