import { TextField } from "@mui/material";

const ReportingTags = (props) => {
  const inputCustomStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#1b3bbb",
      },
      "& fieldset": {
        borderColor: "#1b3bbb",
      },
      "&:hover fieldset": {
        borderColor: "#1b3bbb",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
    background: "#fff",
  };
  return (
    <div className="flex w-full flex-col px-4">
      <h4 className="mt-2 pb-3 text-2xl font-bold text-navy-500">
        Reporting Tags
      </h4>
      <form
        className="mt-4 rounded-md border border-gray-300 p-8"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="my-4 grid grid-cols-1 gap-20 ">
          <div>
            <TextField
              fullWidth
              id="REPORTING_TAGS"
              autoFocus
              label="Reporting Tags"
              variant="outlined"
              name="REPORTING_TAGS"
              value={props.formData.REPORTING_TAGS}
              onChange={(e) => props.onChange(e)}
              multiline
              size="small"
              sx={inputCustomStyle}
            />
          </div>
        </div>
      </form>
      <div className="my-6 flex w-full justify-between">
        <button
          onClick={props.onClickPrev}
          className="transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none"
        >
          Previous
        </button>
        <button
          onClick={props.onClick}
          className="transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ReportingTags;
