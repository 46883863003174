import Card from "components/card";

const Widget = ({ icon, totalCylinder, totalVehicle, extra }) => {
  return (
    <Card
      extra={`${extra} flex-row items-start flex-grow pt-3 h-52 rounded-[20px]`}
    >
      <div className="ml-6 flex h-[50px] w-auto flex-row items-center">
        <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
          <span className="flex items-center text-brand-500 dark:text-white">
            {icon}
          </span>
        </div>
        <span className="ml-2 font-bold text-white">ABU SAAD GAS</span>
      </div>

      <div className="flex w-full flex-col items-center pt-4 text-center ">
        <div className=" grid grid-cols-2 px-4 text-[14px] text-white">
          <span className="font-bold">Total</span>
          <span className="">{totalVehicle}</span>
          <p className="  font-bold">Cyl Qty.</p>
          <span className="">{totalCylinder}</span>
          <span className=" text-[10px]">20 LBS</span>
          <span className=" text-[10px]">{totalVehicle}</span>
          <p className=" text-[10px]">30 LBS</p>
          <span className=" text-[10px]">{totalCylinder}</span>
          <p className=" text-[10px]">100 LBS</p>
          <span className=" text-[10px]">{totalCylinder}</span>
        </div>
      </div>
    </Card>
  );
};

export default Widget;
