import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useRef } from "react";

const CustomerDetail = (props) => {
  const { formData } = props;
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const mobileNoRef = useRef(null);
  const cprNumberRef = useRef(null);

  const inputCustomStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#1b3bbb",
      },
      "& fieldset": {
        borderColor: "#1b3bbb",
      },
      "&:hover fieldset": {
        borderColor: "#1b3bbb",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
    background: "#fff",
  };

  const inputCustomStyleHidden = {
    ".MuiFormControl-root": {
      display: "none",
    },
  };

  useEffect(() => {}, [formData]);

  return (
    <div className="flex w-full flex-col px-4">
      <h4 className="mt-2 pb-3 text-2xl font-bold text-navy-500">
        Customer Details
      </h4>
      <form
        className="mt-4 rounded-md border border-gray-300 p-8"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="my-4 grid grid-cols-1 gap-y-14 sm:grid-cols-2 sm:gap-x-5 md:gap-x-14 lg:grid-cols-3 ">
          <FormControl
            fullWidth
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#1b3bbb",
                },
                "& fieldset": {
                  borderColor: "#1b3bbb",
                },
                "&:hover fieldset": {
                  borderColor: "#1b3bbb",
                },
              },
              "& label.Mui-focused": {
                color: "#24388a",
              },
              background: "#fff",
            }}
          >
            <InputLabel id="customerType">Customer Type</InputLabel>
            <Select
              labelId="customerType"
              id="CUSTOMER_TYPE"
              value={formData.CUSTOMER_TYPE}
              onChange={(e) => props.onChange(e)}
              fullWidth
              label="Customer Type"
              name="CUSTOMER_TYPE"
              inputProps={{
                "aria-label": "Without label",
              }}
              displayEmpty
              size="small"
            >
              <MenuItem value="Individual">Individual</MenuItem>
              <MenuItem value="Company">Company</MenuItem>
            </Select>
          </FormControl>

          <div>
            <TextField
              fullWidth
              id="FIRSTNAME"
              label={
                props.customerTypeVal === "Individual"
                  ? "First Name"
                  : "Company Name"
              }
              variant="outlined"
              autoFocus
              name="FIRSTNAME"
              value={formData.FIRSTNAME}
              onChange={(e) => props.onChange(e)}
              inputRef={firstNameRef}
              size="small"
              sx={inputCustomStyle}
              inputProps={{
                onKeyPress: (event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    event.preventDefault();
                    props.customerTypeVal === "Individual"
                      ? lastNameRef.current.focus()
                      : emailRef.current.focus();
                  }
                },
              }}
            />
          </div>

          <div
            className={`${
              props.customerTypeVal === "Individual" ? "block" : "hidden"
            }`}
          >
            <TextField
              fullWidth
              id="LASTNAME"
              inputRef={lastNameRef}
              label="Last Name"
              name="LASTNAME"
              value={formData.LASTNAME}
              onChange={(e) => props.onChange(e)}
              variant="outlined"
              disabled={props.customerTypeVal === "Individual" ? false : true}
              size="small"
              sx={inputCustomStyle}
              inputProps={{
                onKeyPress: (event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    event.preventDefault();
                    emailRef.current.focus();
                  }
                },
              }}
            />
          </div>

          <div>
            <TextField
              fullWidth
              id="EMAIL"
              inputRef={emailRef}
              label="Email"
              value={formData.EMAIL}
              name="EMAIL"
              onChange={(e) => props.onChange(e)}
              variant="outlined"
              size="small"
              sx={inputCustomStyle}
              inputProps={{
                onKeyPress: (event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    event.preventDefault();
                    mobileNoRef.current.focus();
                  }
                },
              }}
            />
          </div>

          <div>
            <TextField
              fullWidth
              id="MOBILE_NUMBER"
              label="Mobile No."
              variant="outlined"
              name="MOBILE_NUMBER"
              value={formData.MOBILE_NUMBER}
              onChange={(e) => props.onChange(e)}
              inputRef={mobileNoRef}
              size="small"
              sx={inputCustomStyle}
              inputProps={{
                onKeyPress: (event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    event.preventDefault();
                    cprNumberRef.current.focus();
                  }
                },
              }}
            />
          </div>

          <div>
            <TextField
              fullWidth
              id="CPR_NUMBER"
              type="number"
              inputRef={cprNumberRef}
              value={formData.CPR_NUMBER}
              label={
                props.customerTypeVal === "Individual"
                  ? "CPR Number"
                  : "CR Number"
              }
              name="CPR_NUMBER"
              onChange={(e) => props.onChange(e)}
              variant="outlined"
              size="small"
              sx={inputCustomStyle}
            />
          </div>
        </div>
      </form>
      <div className="my-6 flex w-full justify-end">
        <button
          onClick={props.onClick}
          className="transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CustomerDetail;
