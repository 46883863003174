import { Tabs, Tab, Backdrop } from "@mui/material";
import PropTypes from "prop-types";
import { useState, useEffect, useContext } from "react";
import CustomerDetail from "./components/CustomerDetail";
import OtherDetail from "./components/OtherDetail";
import BillingShippingAddress from "./components/BillingShippingAddress";
import CustomerGrid from "./components/CustomerGrid";
import TabPanel from "./components/TablePanel";
import ReportingTags from "./components/ReportingTags";
import Remarks from "./components/Remarks";
import { AppContext } from "Context/AppContext";
import API from "API/API";
import Loading from "components/loading";
import { MdOutlineRefresh } from "react-icons/md";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
let pattren = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
  "abc@gmail.nn"
);
const User = () => {
  const [customers, setCustomers] = useState([]);
  const [logistic, setLogistic] = useState([]);
  const { customerAddress, setCustomerAddress } = useContext(AppContext);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [horizontalTab, setHorizontalTab] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorState, setErrorState] = useState(false);
  const [formData, setFormData] = useState({
    CUSTOMER_TYPE: "Individual",
    FIRSTNAME: "",
    LASTNAME: "",
    EMAIL: "",
    MOBILE_NUMBER: "",
    CPR_NUMBER: "",
    address: customerAddress,
    TAX_TREATMENT: "",
    TRN: "",
    CURRENY: "",
    MODE_OF_PAYMENT: "",
    ENABLE_PORTAL: "",
    PORTAL_LANGUAGE: "",
    REFERRED_BY: "",
    PASSWORD: "",
    REPORTING_TAGS: "",
    REMARKS: "",
  });
  const [editingMode, setEditingMode] = useState(false);

  const FetchCustomer = async () => {
    try {
      setIsLoading(true);
      const result = await API().GetCustomers();
      if (result.data.status) {
        setCustomers(result.data.data);
        FetchLogistic();
      } else {
        setErrorState(true);
        setIsLoading(false);
      }
    } catch (err) {
      setErrorState(true);
      setIsLoading(false);
    }
  };

  const FetchLogistic = async () => {
    try {
      setIsLoading(true);
      const result = await API().GetLogistic();
      if (result.data.status) {
        setLogistic(result.data.data);
        setIsLoading(false);
      } else {
        setErrorState(true);
        setIsLoading(false);
      }
    } catch (err) {
      setErrorState(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 768 ? setHorizontalTab(true) : setHorizontalTab(false)
    );
    FetchCustomer();
  }, []);

  useEffect(() => {
    setFormData({ ...formData, address: customerAddress });
  }, [customerAddress]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFormDataChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleRowEdit = (row) => {
    setFormData({ ...row });
  };

  const emptyFieldsHandler = () => {
    setFormData({
      CUSTOMER_TYPE: "Individual",
      FIRSTNAME: "",
      LASTNAME: "",
      companyName: "",
      EMAIL: "",
      MOBILE_NUMBER: "",
      CPR_NUMBER: "",
      crNumber: "",
      address: customerAddress,
      TAX_TREATMENT: "",
      TRN: "",
      CURRENY: "",
      MODE_OF_PAYMENT: "",
      ENABLE_PORTAL: "",
      PORTAL_LANGUAGE: "",
      REFERRED_BY: "",
      REPORTING_TAGS: "",
      REMARKS: "",
    });
    setCustomerAddress([]);
  };

  return (
    <>
      {isLoading ? (
        <>
          <Backdrop
            sx={{
              color: "#fff",
              background: "#F4F7FE",
              position: "relative",
              zIndex: 5,
              height: "100vh",
            }}
            open={isLoading}
          >
            <Loading />
          </Backdrop>
        </>
      ) : (
        <>
          {errorState ? (
            <div className="flex h-[84vh] flex-col items-center justify-center">
              <span
                className="text-navy-600"
                onClick={() => {
                  setIsLoading(true);
                  setErrorState(false);
                  FetchCustomer();
                }}
              >
                <MdOutlineRefresh className="h-28 w-28 -rotate-90 cursor-pointer hover:animate-spin" />
              </span>
              <p className="text-2xl font-bold text-navy-600">
                Server Error, Please Refresh
              </p>
            </div>
          ) : (
            <div className="-mt-5 flex w-full flex-col rounded-b-2xl bg-white px-4">
              <div className="mx-auto flex w-full max-w-6xl flex-col rounded-b-2xl  bg-white ">
                {/* <Form /> */}
                <h2 className=" flex w-full py-5 pl-1 text-3xl font-bold capitalize text-navy-700 dark:text-white">
                  Customer
                </h2>
                <div className="relative flex h-full w-full flex-col items-center justify-center ">
                  <div
                    className={`absolute w-full px-10 ${
                      isFormLoading ? "flex" : "hidden"
                    } z-50 h-full flex-col items-center justify-center rounded-t-2xl backdrop-blur-sm`}
                  >
                    <Loading />
                  </div>
                  <div
                    className={`${
                      horizontalTab ? "flex-col" : " flex-row"
                    } flex w-full rounded-t-2xl border border-gray-300`}
                  >
                    <Tabs
                      TabIndicatorProps={{
                        style: { background: "#1b3bbb" },
                      }}
                      sx={{
                        borderRight: "1px solid #D4D4D8",
                        padding: "1rem 0rem",
                        "& .MuiTab-root.Mui-selected": {
                          color: "#24388a",
                        },
                      }}
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      orientation={horizontalTab ? "horizontal" : "vertical"}
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                    >
                      <Tab
                        sx={{
                          ".Mui-selected": {
                            color: "#1b3bbb",
                          },
                          alignItems: "start",
                          textAlign: "left",
                        }}
                        label="Customer Details"
                        {...a11yProps(0)}
                      />
                      <Tab
                        sx={{ alignItems: "start", textAlign: "left" }}
                        label="Billing/Shipping Address"
                        {...a11yProps(1)}
                      />
                      <Tab
                        sx={{ alignItems: "start", textAlign: "left" }}
                        label="Other Details"
                        {...a11yProps(2)}
                      />
                      <Tab
                        sx={{ alignItems: "start", textAlign: "left" }}
                        label="Reporting Tags"
                        {...a11yProps(3)}
                      />
                      <Tab
                        sx={{ alignItems: "start", textAlign: "left" }}
                        label="Remarks"
                        {...a11yProps(4)}
                      />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                      <CustomerDetail
                        onChange={(e) =>
                          handleFormDataChange(e.target.name, e.target.value)
                        }
                        customerTypeVal={formData.CUSTOMER_TYPE}
                        formData={formData}
                        onClick={() => setValue(value + 1)}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <BillingShippingAddress
                        formData={formData}
                        logisticData={logistic}
                        onClickPrev={() => setValue(value - 1)}
                        onClick={() => setValue(value + 1)}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <OtherDetail
                        onChange={(e) =>
                          handleFormDataChange(e.target.name, e.target.value)
                        }
                        formData={formData}
                        onClickPrev={() => setValue(value - 1)}
                        onClick={() => setValue(value + 1)}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <ReportingTags
                        onChange={(e) =>
                          handleFormDataChange(e.target.name, e.target.value)
                        }
                        formData={formData}
                        onClickPrev={() => setValue(value - 1)}
                        onClick={() => setValue(value + 1)}
                      />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                      <Remarks
                        onChange={(e) =>
                          handleFormDataChange(e.target.name, e.target.value)
                        }
                        formData={formData}
                        onClickPrev={() => setValue(value - 1)}
                        callCustomerDB={FetchCustomer}
                        editingMode={editingMode}
                        disableEditingMode={() => setEditingMode(false)}
                        emptyFields={emptyFieldsHandler}
                        submitBlur={(e) => setIsFormLoading(e)}
                        resetTabValue={() => setValue(0)}
                      />
                    </TabPanel>
                    {/* </Box> */}
                  </div>
                </div>
              </div>
              <div className="mx-auto mt-2 w-full max-w-6xl overflow-hidden rounded-b-2xl border border-gray-300 ">
                <CustomerGrid
                  handleRowEdit={handleRowEdit}
                  gridData={customers}
                  callCustomerDB={FetchCustomer}
                  openEditingMode={() => setEditingMode(true)}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default User;
