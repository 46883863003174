/* eslint-disable */

import { HiX } from "react-icons/hi";
import nubitLogo from "../../assets/img/layout/nubitlogo.png";
import Links from "./components/Links";
import logo from "../../assets/img/layout/cyltraxlogo.png";
import logoDark from "../../assets/img/layout/CYLTRAXDark.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineLogout } from "react-icons/md";
import routes from "routes.js";
import { AppContext } from "Context/AppContext";
import { useContext } from "react";

const Sidebar = ({ open, onClose }) => {
  let { state } = useLocation();
  const { setIsLogin } = useContext(AppContext);
  const navigate = useNavigate();
  return (
    <div
      className={`sm:none linear fixed !z-50 flex h-screen min-h-full flex-1 flex-col overflow-x-hidden bg-white shadow-2xl  shadow-white/5 transition-all duration-500 dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className="mx-[56px] mt-[50px] flex cursor-pointer items-center">
        <Link to="/dashboard/panel">
          <img src={logo} width={200} />
        </Link>
      </div>
      <div className="mt-10 mb-7 h-px  bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto max-h-full overflow-y-scroll pt-1 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 scrollbar-thumb-rounded">
        <Links routes={routes} />
        <div
          onClick={() => {
            setIsLogin(false);
            sessionStorage.removeItem("token");
            navigate("/auth");
          }}
        >
          <div className="relative mb-3 flex hover:cursor-pointer">
            <li className="my-[3px] flex cursor-pointer items-center px-8">
              <span className="font-medium text-gray-600">
                <MdOutlineLogout className="h-6 w-6 rotate-180" />
              </span>
              <p className="leading-1 ml-4 flex font-medium text-gray-600">
                Logout
              </p>
            </li>
          </div>
        </div>
      </ul>

      {/* Nav item end */}
      <div className="flex w-full cursor-pointer items-center justify-center px-2 pb-4 ">
        <img src={nubitLogo} width={150} />
      </div>
    </div>
  );
};

export default Sidebar;
