import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  ListSubheader,
  InputAdornment,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import moment from "moment";
import { useState, useMemo, useRef, useContext, useEffect } from "react";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AppContext } from "Context/AppContext";
import API from "API/API";
import Loading from "components/loading";
import PromptModal from "components/promptModal/PromptModal";

const data = [
  {
    itemId: "",
    description: "",
    quantity: "",
    unit: "",
    unitPrice: "",
    vatPercent: "",
    vatAmount: "",
  },
];

const RefillForm = (props) => {
  const { userData } = useContext(AppContext);
  const [tableData, setTableData] = useState(data);
  const [rowIndex, setRowIndex] = useState(0);
  const [items, setItems] = useState(props.itemList);
  const [vehicles, setVehicles] = useState(props.vehicleList);
  const [vendors, setVendors] = useState(props.vendorList);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [formData, setFormData] = useState({
    deliveryDocketNo: "",
    vendorId: "",
    date: moment(new Date()),
    lpoRef: "",
    vehicleNo: "",
    items: tableData,
  });

  const handleSaveCell = (cell, value) => {
    if (cell.column.id == "itemId") {
      tableData[cell.row.index][cell.column.id] = value.ITEMID;
      tableData[cell.row.index]["description"] = value.DESCRIPTION;
    }
    tableData[cell.row.index][cell.column.id] = value;
    setFormData({ ...formData, items: tableData });
  };

  useEffect(() => {
    if (props.editingMode) {
      //YE MAAZ BHAI NE KARWAYA HAI
      props.formData.items = props.formData.items.map((item) => {
        return {
          ...item,
          itemId: items.filter((i) => i.ITEMID == item.itemId)[0],
          description: items.filter((i) => i.ITEMID == item.itemId)[0]
            ?.DESCRIPTION,
        };
      });
      setFormData(props.formData);
      setTableData(props.formData.items);
      setRowIndex(props.formData.items.length - 1);
    }
  }, [props.editingMode]);

  const inputCustomStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#1b3bbb",
      },
      "& fieldset": {
        borderColor: "#1b3bbb",
      },
      "&:hover fieldset": {
        borderColor: "#1b3bbb",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
    background: "#fff",
  };
  const inputCustomStylegrid = {
    "& .MuiInputBase-root": {
      "&:after": {
        borderBottom: "2px solid #24388a",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
  };
  const inputCustomStyleGrid = {
    "& .MuiInputBase-root": {
      "&:after": {
        borderBottom: "2px solid #24388a",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
  };

  const editConfirmation = () => {
    setEditOpen(true);
  };

  const handleSubmit = async (formData) => {
    setIsLoading(true);
    handleDialogClose();
    if (formData.items)
      formData.items = formData.items
        .filter((e) => e.itemId)
        .map((e) => {
          return { ...e, itemId: e.itemId.ITEMID };
        });

    try {
      const res = await API().AddRefill(
        formData.deliveryDocketNo,
        formData.vendorId,
        formData.lpoRef,
        formData.vehicleNo,
        formData.date,
        formData.items,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );

      if (res.data.status) {
        setFeedbackMessage("Docket Added Succesfully");
        setSuccessModal(true);
        props.disableEditingMode();
      } else {
        setFeedbackMessage("Error Adding Data");
        setSuccessModal(true);
      }
    } catch (error) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const handleEditSubmit = async (formData) => {
    handleEditDialogClose();
    if (formData.items)
      formData.items = formData.items
        .filter((e) => e.itemId)
        .map((e) => {
          return { ...e, itemId: e.itemId.ITEMID };
        });
    try {
      const res = await API().UpdateRefill(
        formData.deliveryDocketNo,
        formData.vendorId,
        formData.lpoRef,
        formData.vehicleNo,
        formData.date,
        formData.items,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      if (res.data.status) {
        setFeedbackMessage("Docket Updated");
        setSuccessModal(true);
        props.disableEditingMode();
      } else {
        setFeedbackMessage("Error While Editing Data");
        setSuccessModal(true);
      }
    } catch (error) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleEditDialogClose = () => {
    setEditOpen(false);
  };

  const columns = useMemo(() => [
    {
      accessorKey: "itemId",
      header: "Item Abbreviation",
      size: 80,
      Edit: ({ cell }) => (
        <FormControl
          fullWidth
          variant="standard"
          size="small"
          sx={inputCustomStyleGrid}
        >
          <Select
            id="itemId"
            value={cell.row.original.itemId}
            onChange={(e) => {
              if (
                cell.row.index == rowIndex &&
                e.target.value.toString().length > 0
              ) {
                setTableData([
                  ...tableData,
                  {
                    itemId: "",
                    description: "",
                    quantity: "",
                    unit: "",
                    unitPrice: "",
                    vatPercent: "",
                    vatAmount: "",
                  },
                ]);
                setRowIndex(rowIndex + 1);
              }
              handleSaveCell(cell, e.target.value);
            }}
            fullWidth
            inputProps={{
              "aria-label": "Without label",
            }}
            size="small"
          >
            {/* {props.editingMode && (
              <MenuItem value="remove">Remove Row</MenuItem>
            )} */}
            {items.map((item, i) => (
              <MenuItem key={i} value={item}>
                {item.ABBREVIATION}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
    },
    {
      accessorKey: "description",
      header: "Item Description",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          disabled
          variant="standard"
          sx={inputCustomStylegrid}
          id="description"
          value={cell.row.original.description}
          onChange={(e) => handleSaveCell(cell, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "quantity",
      header: "Quantity",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          type="number"
          variant="standard"
          sx={inputCustomStylegrid}
          id="quantity"
          value={cell.row.original.quantity}
          onChange={(e) => handleSaveCell(cell, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "unitPrice",
      header: "Price",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          variant="standard"
          sx={inputCustomStylegrid}
          id="unitPrice"
          value={cell.row.original.unitPrice}
          onChange={(e) => handleSaveCell(cell, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "vatPercent",
      header: "VAT %",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          variant="standard"
          sx={inputCustomStylegrid}
          id="vatPercent"
          value={cell.row.original.vatPercent}
          onChange={(e) => handleSaveCell(cell, e.target.value)}
        />
      ),
    },
    // {
    //   accessorKey: "vatAmount",
    //   header: "VAT Amount",
    //   size: 80,
    //   Edit: ({ cell }) => (
    //     <TextField
    //       variant="standard"
    //       sx={inputCustomStylegrid}
    //       id="vatAmount"
    //       value={cell.row.original.vatAmount}
    //       onChange={(e) => handleSaveCell(cell, e.target.value)}
    //     />
    //   ),
    // },
  ]);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        className={`absolute w-full px-10 ${
          isLoading ? "flex" : "hidden"
        } z-50 h-full flex-col items-center justify-center backdrop-blur-sm`}
      >
        <Loading />
      </div>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your Intention to Save/Update ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Add this Docket
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSubmit(formData);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editOpen}
        onClose={handleEditDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your Intention to Save/Update ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Update this Docket
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleEditDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleEditSubmit(formData);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <PromptModal
        ModalState={successModal}
        feedbackMessage={feedbackMessage}
        onClick={() => {
          props.callREfillDB();
          setSuccessModal(false);
        }}
      />
      <section className="w-full max-w-5xl rounded-t-2xl border border-gray-300 bg-[#EBECF0] p-6 shadow-2xl dark:bg-navy-800 md:px-16 md:py-10">
        <form className="w-full" onSubmit={(e) => e.preventDefault()}>
          <div className="flex w-full flex-col">
            <div className="flex w-full justify-center rounded-xl bg-[#f6f6f6] py-4 px-1 shadow-xl sm:px-4 md:py-5 md:px-10 lg:px-5">
              <div className="grid grid-cols-1 gap-10 px-4 py-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-y-8 lg:px-10">
                <div className="col-span-3">
                  <div className="grid grid-cols-2 gap-10 lg:gap-y-8">
                    <div>
                      <TextField
                        fullWidth
                        id="docketNo"
                        label="Delivery Docket No."
                        variant="outlined"
                        disabled={props.editingMode ? true : false}
                        autoFocus
                        value={formData.deliveryDocketNo}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            deliveryDocketNo: e.target.value,
                          })
                        }
                        size="small"
                        sx={inputCustomStyle}
                        inputProps={{
                          onKeyPress: (event) => {
                            const { key } = event;
                            if (key === "Enter") {
                              //descriptionRef.current.focus();
                            }
                          },
                        }}
                      />
                    </div>
                    <div>
                      <FormControl
                        fullWidth
                        size="small"
                        sx={inputCustomStyle}
                        slotprops={{
                          textField: {
                            size: "small",
                            fullWidth: true,
                          },
                        }}
                      >
                        <InputLabel id="vehicle-id">Vehicle Number</InputLabel>
                        <Select
                          labelId="vehicle-id"
                          id="FK_VEHICLEID"
                          label="Vehicle Number"
                          value={formData.vehicleNo}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              vehicleNo: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          displayEmpty
                          size="small"
                        >
                          {vehicles.map((item, i) => (
                            <MenuItem key={i} value={item.ID}>
                              {item.REGISTRATION_NO}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      onChange={(newValue) =>
                        setFormData({ ...formData, date: moment(newValue) })
                      }
                      label="Date"
                      value={formData.date}
                      format="DD-MM-YYYY"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#1b3bbb",
                          },
                          "& fieldset": {
                            borderColor: "#1b3bbb",
                          },
                          "&:hover fieldset": {
                            borderColor: "#1b3bbb",
                          },
                        },
                        "& label.Mui-focused": {
                          color: "#24388a",
                        },
                        background: "#fff",
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div>
                  <TextField
                    fullWidth
                    id="lpoRef"
                    label="LPO Ref"
                    variant="outlined"
                    size="small"
                    value={formData.lpoRef}
                    onChange={(e) =>
                      setFormData({ ...formData, lpoRef: e.target.value })
                    }
                    sx={inputCustomStyle}
                    inputProps={{
                      onKeyPress: (event) => {
                        const { key } = event;
                        if (key === "Enter") {
                          //descriptionRef.current.focus();
                        }
                      },
                    }}
                  />
                </div>
                <div>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={inputCustomStyle}
                    slotprops={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                      },
                    }}
                  >
                    <InputLabel id="vendor-id">Vendor Name</InputLabel>
                    <Select
                      labelId="vendor-id"
                      id="vendorId"
                      label="Vendor Name"
                      value={formData.vendorId}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          vendorId: e.target.value,
                        });
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      size="small"
                    >
                      {vendors.map((item, i) => (
                        <MenuItem key={i} value={item.VENDORID}>
                          {item.COMPANY_NAME}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="my-10">
              <div className="mt-10 flex w-full justify-center rounded-xl bg-[#f6f6f6] py-4 px-4 shadow-xl sm:px-8 md:py-10 md:px-10 lg:mx-1 lg:mt-0 lg:px-10">
                <div className="grid w-full grid-cols-1 ">
                  <MaterialReactTable
                    columns={columns}
                    enableTopToolbar={false}
                    data={tableData}
                    muiTablePaperProps={{ sx: { borderRadius: "10px" } }}
                    muiTableContainerProps={{ sx: { borderRadius: "10px" } }}
                    editingMode="table"
                    autoResetPageIndex={false}
                    enableEditing
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              {props.editingMode ? (
                <div>
                  <button
                    onClick={() => {
                      setTableData(data);
                      setFormData({
                        deliveryDocketNo: "",
                        vendorId: "",
                        date: moment(new Date()),
                        lpoRef: "",
                        vehicleNo: "",
                        items: tableData,
                      });
                      props.disableEditingMode();
                    }}
                    className="mr-2 transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={editConfirmation}
                    className="transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none"
                  >
                    Done
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => setOpen(true)}
                  className="mr-2 transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none sm:mr-0"
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </form>
      </section>
    </Box>
  );
};

export default RefillForm;
