import axios from "axios";
import moment from "moment";

let token = "";
const Api = "https://nizamuddintai-001-site8.ctempurl.com"; // LIVE API URL
//const Api = "http://localhost:3000"; // SYSTEM ACCESS IP
const fetchJSONwithouttoken = async ({ url }) => {
  const res = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // prettier-ignore
      'Accept': 'application/json',
      // prettier-ignore
      // 'Authorization': `Bearer ${e}`,
    },
  });
  return { data: await res.json() };
  // const res = await axios(url, {
  //   method: "GET",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  // });
  // return axios.get(url);
};
const fetchJSONPOSTwithbodywithouttoken = async ({ url, body }) => {
  const res = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // prettier-ignore
      // 'Authorization': `Bearer ${e}`,
      // prettier-ignore
      'Accept': 'application/json',
    },
    body: JSON.stringify(body),
  });
  return { data: await res.json() };
  // let axiosConfig = {
  //   headers: {
  //     "Content-Type": "application/json;charset=UTF-8",
  //     "Access-Control-Allow-Origin": "*",
  //   },
  // };

  // return axios.post(url, JSON.stringify(body), axiosConfig);
};

const API = (credentials) => ({
  // ############################
  // ######## Get Apies #########
  // ############################
  GetItems: () => fetchJSONwithouttoken({ url: `${Api}/item/getitem` }),
  GetDashboardVehicleCards: () =>
    fetchJSONwithouttoken({ url: `${Api}/dashboard/getVehicleCards` }),
  GetVehicle: () => fetchJSONwithouttoken({ url: `${Api}/vehicle/getvehicle` }),
  GetUsers: () => fetchJSONwithouttoken({ url: `${Api}/users/getusers` }),
  GetDrivers: () => fetchJSONwithouttoken({ url: `${Api}/users/getDrivers` }),
  GetCustomers: () =>
    fetchJSONwithouttoken({ url: `${Api}/customer/getcustomer` }),
  GetVendor: () => fetchJSONwithouttoken({ url: `${Api}/vendors/getvendors` }),
  GetLogistic: () =>
    fetchJSONwithouttoken({ url: `${Api}/logistics/getlogistics` }),
  GetWarehouse: () =>
    fetchJSONwithouttoken({ url: `${Api}/warehouse/getwarehouseactivity` }),
  GetSales: () => fetchJSONwithouttoken({ url: `${Api}/sales/getSales` }),
  GetPurchases: () =>
    fetchJSONwithouttoken({ url: `${Api}/purchase/getPurchase` }),
  GetDockets: () => fetchJSONwithouttoken({ url: `${Api}/docket/getdocket` }),
  GetVendorRates: () =>
    fetchJSONwithouttoken({ url: `${Api}/docket/getPreviousRates` }),
  GetPurchase: () =>
    fetchJSONwithouttoken({ url: `${Api}/purchase/getPurchase` }),
  GetPendingOrders: () =>
    fetchJSONwithouttoken({ url: `${Api}/dashboard/getPendingOrders` }),
  GetDeliveredOrders: () =>
    fetchJSONwithouttoken({ url: `${Api}/dashboard/getDeliveredOrders` }),
  GetTodayOrders: () =>
    fetchJSONwithouttoken({ url: `${Api}/dashboard/getTodayOrders` }),
  GetAdvanceOrders: () =>
    fetchJSONwithouttoken({ url: `${Api}/dashboard/getAdvanceOrders` }),
  GetOfflineCustomers: () =>
    fetchJSONwithouttoken({ url: `${Api}/dashboard/getOfflineCustomers` }),
  GetDailySales: () =>
    fetchJSONwithouttoken({ url: `${Api}/dashboard/getDailySales` }),
  GetSalesGrowth: () =>
    fetchJSONwithouttoken({ url: `${Api}/dashboard/getSalesGrowth` }),

  // ############################
  // ######## Post Apies ########
  // ############################

  //-----ITEM API's-----//

  AddItem: (_itemid, _abbrevation, _unit_price, _description, _createdby) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/item/additem`,
      body: {
        itemid: _itemid,
        abbrevation: _abbrevation,
        unit_price: _unit_price,
        description: _description,
        createdby: _createdby,
        editedby: null,
        deletedby: null,
      },
    }),

  UpdateItem: (_itemid, _abbrevation, _unit_price, _description, _editedby) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/item/updateitem`,
      body: {
        itemid: _itemid,
        abbrevation: _abbrevation,
        description: _description,
        unit_price: _unit_price,
        createdby: null,
        editedby: _editedby,
        deletedby: null,
      },
    }),

  DeleteItem: (_itemid, _abbrevation, _description, _unit_price, _deletedby) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/item/removeitem`,
      body: {
        itemid: _itemid,
        abbrevation: _abbrevation,
        description: _description,
        unit_price: _unit_price,
        createdby: null,
        editedby: null,
        deletedby: _deletedby,
      },
    }),

  //-----VEHICLE API's-----//

  AddVehicle: (
    _make,
    _date,
    _model,
    _registrationNo,
    _purchaseAmount,
    _weight,
    _route,
    _vehicleStatus,
    _remarks,
    _color,
    _insuranceDetails,
    _vehiclePassing,
    _created_by
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/vehicle/addvehicle`,
      body: {
        make: _make,
        modal: _model,
        registration_no: _registrationNo,
        purchase_amount: _purchaseAmount,
        weight: _weight,
        route: _route,
        vehicle_status: _vehicleStatus,
        registration_date: _date,
        remarks: _remarks,
        color: _color,
        insurance_detail: _insuranceDetails,
        vehicle_passing: _vehiclePassing,
        createdby: _created_by,
        editedby: null,
        deletedby: null,
      },
    }),

  UpdateVehicle: (
    _ID,
    _MAKE,
    _REGISTRATION_DATE,
    _MODAL,
    _REGISTRATION_NO,
    _PURCHASE_AMOUNT,
    _WEIGHT,
    _ROUTE,
    _VEHICLE_STATUS,
    _REMARKS,
    _COLOR,
    _INSURANCE_DETAIL,
    _VEHICLE_PASSING,
    _editedby
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/vehicle/updatevehicle`,
      body: {
        vehicleId: _ID,
        make: _MAKE,
        model: _MODAL,
        registration_no: _REGISTRATION_NO,
        purchase_amount: _PURCHASE_AMOUNT,
        weight: _WEIGHT,
        route: _ROUTE,
        vehicle_status: _VEHICLE_STATUS,
        registration_date: _REGISTRATION_DATE,
        remarks: _REMARKS,
        color: _COLOR,
        insurance_detail: _INSURANCE_DETAIL,
        vehicle_passing: _VEHICLE_PASSING,
        createdby: null,
        editedby: _editedby,
        deletedby: null,
      },
    }),

  DeleteVehicle: (
    _ID,
    _MAKE,
    _REGISTRATION_DATE,
    _MODAL,
    _REGISTRATION_NO,
    _PURCHASE_AMOUNT,
    _WEIGHT,
    _ROUTE,
    _VEHICLE_STATUS,
    _REMARKS,
    _COLOR,
    _INSURANCE_DETAIL,
    _VEHICLE_PASSING,
    _deletedby
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/vehicle/removevehicle`,
      body: {
        vehicleId: _ID,
        make: _MAKE,
        model: _MODAL,
        registration_no: _REGISTRATION_NO,
        purchase_amount: _PURCHASE_AMOUNT,
        weight: _WEIGHT,
        route: _ROUTE,
        vehicle_status: _VEHICLE_STATUS,
        registration_date: _REGISTRATION_DATE,
        remarks: _REMARKS,
        color: _COLOR,
        insurance_detail: _INSURANCE_DETAIL,
        vehicle_passing: _VEHICLE_PASSING,
        createdby: null,
        editedby: null,
        deletedby: _deletedby,
      },
    }),

  //-----USERS API's-----//

  AddUser: (
    _email,
    _password,
    _portal,
    _role,
    _userId,
    _username,
    _vehicleId,
    _created_by
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/users/addusers`,
      body: {
        email: _email,
        password: _password,
        portal: _portal,
        role: _role,
        userid: _userId,
        username: _username,
        vehicleId: _vehicleId,
        createdby: _created_by,
        edited_by: null,
        deleted_by: null,
      },
    }),

  UpdateUser: (
    _email,
    _password,
    _passChanged,
    _portal,
    _role,
    _userid,
    _username,
    _vehicleId,
    _editby
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/users/updateusers`,
      body: {
        userid: _userid,
        username: _username,
        email: _email,
        password: _password,
        PassChanged: _passChanged,
        portal: _portal,
        vehicleId: _vehicleId,
        role: _role,
        created_by: null,
        edited_by: _editby,
        deleted_by: null,
      },
    }),

  DeleteUser: (
    _email,
    _password,
    _portal,
    _role,
    _userid,
    _username,
    _vehicleId,
    _deletedby
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/users/removeusers`,
      body: {
        userid: _userid,
        username: _username,
        email: _email,
        password: _password,
        portal: _portal,
        vehicleId: _vehicleId,
        role: _role,
        created_by: null,
        edited_by: null,
        deleted_by: _deletedby,
      },
    }),

  //-----VENDOR API's-----//

  AddVendor: (
    _crNo,
    _companyName,
    _address,
    _phoneNo,
    _email,
    _vatNo,
    _created_by
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/vendors/addvendor`,
      body: {
        cr_no: _crNo,
        company_name: _companyName,
        address: _address,
        phone_no: _phoneNo,
        email: _email,
        vat_no: _vatNo,
        createdby: _created_by,
      },
    }),

  UpdateVendor: (
    _ADDRESS,
    _COMPANY_NAME,
    _CR_NO,
    _EMAIL,
    _PHONE_NO,
    _VAT_NO,
    _VENDORID,
    _edited_by
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/vendors/updatevendor`,
      body: {
        vendorid: _VENDORID,
        cr_no: _CR_NO,
        company_name: _COMPANY_NAME,
        address: _ADDRESS,
        phone_no: _PHONE_NO,
        email: _EMAIL,
        vat_no: _VAT_NO,
        editedby: _edited_by,
      },
    }),

  DeleteVendor: (
    _ADDRESS,
    _COMPANY_NAME,
    _CR_NO,
    _EMAIL,
    _PHONE_NO,
    _VAT_NO,
    _VENDORID,
    _deletedby
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/vendors/removevendor`,
      body: {
        vendorid: _VENDORID,
        cr_no: _CR_NO,
        company_name: _COMPANY_NAME,
        address: _ADDRESS,
        phone_no: _PHONE_NO,
        email: _EMAIL,
        vat_no: _VAT_NO,
        deletedby: _deletedby,
      },
    }),

  //-----CUSTOMER API's-----//

  AddCustomer: (
    _adrresses,
    _companyName,
    _cprNumber,
    _crNumber,
    _currency,
    _customerType,
    _email,
    _enablePortal,
    _firstName,
    _lastName,
    _mobileNo,
    _paymentTerms,
    _portalLanguage,
    _refferedBy,
    _remarks,
    _reportingTags,
    _taxTreatment,
    _trn,
    _password,
    _created_by
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/customer/addcustomer`,
      body: {
        address: _adrresses,
        customer_type: _customerType,
        firstname: _firstName,
        lastname: _lastName,
        email: _email,
        mobile_number: _mobileNo,
        cpr_number: _cprNumber,
        tax_treatment: _taxTreatment,
        trn: _trn,
        currency: _currency,
        mode_of_payment: _paymentTerms,
        enable_portal: _enablePortal,
        portal_language: _portalLanguage,
        referred_by: _refferedBy,
        reporting_tags: _reportingTags,
        remarks: _remarks,
        password: _password,
        createdby: _created_by,
      },
    }),

  UpdateCustomer: (
    _customerId,
    _address,
    _CPR_NUMBER,
    _CURRENY,
    _CUSTOMER_TYPE,
    _EMAIL,
    _ENABLE_PORTAL,
    _FIRSTNAME,
    _LASTNAME,
    _MOBILE_NUMBER,
    _MODE_OF_PAYMENT,
    _PORTAL_LANGUAGE,
    _REFERRED_BY,
    _REMARKS,
    _REPORTING_TAGS,
    _TAX_TREATMENT,
    _TRN,
    _PASSWORD,
    _edited_by
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/customer/editcustomer`,
      body: {
        customerid: _customerId,
        address: _address,
        customer_type: _CUSTOMER_TYPE,
        firstname: _FIRSTNAME,
        lastname: _LASTNAME,
        email: _EMAIL,
        mobile_number: _MOBILE_NUMBER,
        cpr_number: _CPR_NUMBER,
        tax_treatment: _TAX_TREATMENT,
        trn: _TRN,
        currency: _CURRENY,
        mode_of_payment: _MODE_OF_PAYMENT,
        enable_portal: _ENABLE_PORTAL,
        portal_language: _PORTAL_LANGUAGE,
        referred_by: _REFERRED_BY,
        reporting_tags: _REPORTING_TAGS,
        remarks: _REMARKS,
        password: _PASSWORD,
        editby: _edited_by,
      },
    }),

  DeleteCustomer: (
    _adrresses,
    _customerId,
    _cprNumber,
    _currency,
    _customerType,
    _email,
    _enablePortal,
    _firstName,
    _lastName,
    _mobileNo,
    _paymentTerms,
    _portalLanguage,
    _refferedBy,
    _remarks,
    _reportingTags,
    _taxTreatment,
    _trn,
    _deletedby
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/customer/removecustomer`,
      body: {
        address: _adrresses,
        customerid: _customerId,
        customer_type: _customerType,
        firstname: _firstName,
        lastname: _lastName,
        email: _email,
        mobile_number: _mobileNo,
        cpr_number: _cprNumber,
        tax_treatment: _taxTreatment,
        trn: _trn,
        currency: _currency,
        mode_of_payment: _paymentTerms,
        enable_portal: _enablePortal,
        portal_language: _portalLanguage,
        referred_by: _refferedBy,
        reporting_tags: _reportingTags,
        remarks: _remarks,
        deleted_by: _deletedby,
      },
    }),

  //-----WAREHOUSE API's-----//

  AddWarehouse: (
    _warehouseStatus,
    _serialFrom,
    _serialTo,
    _itemId,
    _itemAbbreviation,
    _itemDescription,
    _itemQuantity,
    _vehicleId,
    _issuedAt,
    _recievedAt,
    _remarks,
    _created_by
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/warehouse/addwarehouseactivity`,
      body: {
        warehouse_status: _warehouseStatus,
        serialfrom: _serialFrom,
        serialto: _serialTo,
        fk_itemid: _itemId,
        item_quantity: _itemQuantity,
        description: _itemDescription,
        fk_vehicleid: _vehicleId,
        issued_date: _issuedAt,
        recieved_date: _recievedAt,
        remarks: _remarks,
        createdby: _created_by,
        editedby: null,
        deletedby: null,
      },
    }),

  UpdateWarehouse: (
    _WAREACTIVITY_ID,
    _WAREHOUSE_STATUS,
    _SERIALFROM,
    _SERIALTO,
    _ITEMID,
    _ITEM_QUANTITY,
    _DESCRIPTION,
    _FK_VEHICLEID,
    _ISSUED_DATE,
    _RECIEVED_DATE,
    _edited_by
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/warehouse/updatewarehouseactivity`,
      body: {
        wareactivity_id: _WAREACTIVITY_ID,
        warehouse_status: _WAREHOUSE_STATUS,
        serialfrom: _SERIALFROM,
        serialto: _SERIALTO,
        fk_itemid: _ITEMID,
        item_quantity: _ITEM_QUANTITY,
        description: _DESCRIPTION,
        fk_vehicleid: _FK_VEHICLEID,
        issued_date: _ISSUED_DATE,
        recieved_date: _RECIEVED_DATE,
        createdby: null,
        editedby: _edited_by,
        deletedby: null,
      },
    }),

  DeleteWarehouse: (
    _DESCRIPTION,
    _FK_ITEMID,
    _FK_VEHICLEID,
    _WAREACTIVITY_ID,
    _ISSUED_DATE,
    _ITEM_QUANTITY,
    _RECIEVED_DATE,
    _SERIALFROM,
    _SERIALTO,
    _WAREHOUSE_STATUS,
    _deletedby
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/warehouse/removewarehouseactivity`,
      body: {
        wareactivity_id: _WAREACTIVITY_ID,
        warehouse_status: _WAREHOUSE_STATUS,
        serialfrom: _SERIALFROM,
        serialto: _SERIALTO,
        fk_itemid: _FK_ITEMID,
        item_quantity: _ITEM_QUANTITY,
        description: _DESCRIPTION,
        fk_vehicleid: _FK_VEHICLEID,
        issued_date: _ISSUED_DATE,
        recieved_date: _RECIEVED_DATE,
        createdby: null,
        editedby: null,
        deletedby: _deletedby,
      },
    }),

  //-----Logistic API's-----//

  AddLogistic: (_vehicleId, _deliveryAreas, _created_by) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/logistics/addlogistics`,
      body: {
        fk_vehicleid: _vehicleId,
        deliveryAreas: _deliveryAreas,
        createdby: _created_by,
        editedby: null,
        deletedby: null,
      },
    }),

  UpdateLogistic: (_FK_VEHICLEID, _DELIVERY_AREA, _status, _edited_by) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/logistics/updatelogistics`,
      body: {
        fk_vehicleid: _FK_VEHICLEID,
        deliveryAreas: _DELIVERY_AREA,
        STATUS: _status,
        createdby: null,
        editedby: _edited_by,
        deletedby: null,
      },
    }),

  DeleteLogistic: (_DELIVERY_AREA, _FK_VEHICLEID, _deletedby) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/logistics/removelogistics`,
      body: {
        fk_vehicleid: _FK_VEHICLEID,
        deliveryAreas: _DELIVERY_AREA,
        createdby: null,
        editedby: null,
        deleted_by: _deletedby,
      },
    }),

  //-----SALES/ORDER API's-----//

  AddOrder: (
    _vehicle,
    _orderStatus,
    _orderType,
    _cprNumber,
    _mobileNumber,
    _date,
    _flat_shopNo,
    _building,
    _road,
    _block,
    _area,
    _contactPerson,
    _items,
    _created_by
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/sales/addSales`,
      body: {
        fk_customerid: _cprNumber,
        fk_addressid: _flat_shopNo,
        fk_vehicleid: _vehicle,
        ordertype: _orderType,
        deliverystatus: _orderStatus,
        discount: 0,
        delivery_date: _date,
        items: _items,
        createdby: _created_by,
        editedby: null,
        deletedby: null,
      },
    }),

  UpdateOrder: (
    _SALEID,
    _orderStatus,
    _orderType,
    _cprNumber,
    _mobileNumber,
    _date,
    _flat_shopNo,
    _building,
    _road,
    _block,
    _area,
    _vehicle,
    _contactPerson,
    _items,
    _edited_by
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/sales/updateSales`,
      body: {
        saleid: _SALEID,
        fk_customerid: _cprNumber,
        fk_addressid: _flat_shopNo,
        fk_vehicleid: _vehicle,
        ordertype: _orderType,
        deliverystatus: _orderStatus,
        discount: 0,
        delivery_date: _date,
        items: _items,
        createdby: null,
        editedby: _edited_by,
        deletedby: null,
      },
    }),

  DeleteOrder: (
    _DELIVERYSTATUS,
    _DELIVERY_DATE,
    _Discount,
    _FK_ADDRESSID,
    _FK_CUSTOMERID,
    _FK_VEHICLEID,
    _ORDERTYPE,
    _SALEID,
    _items,
    _deletedby
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/sales/removeSales`,
      body: {
        saleid: _SALEID,
        fk_customerid: _FK_CUSTOMERID,
        fk_addressid: _FK_ADDRESSID,
        fk_vehicleid: _FK_VEHICLEID,
        ordertype: _ORDERTYPE,
        deliverystatus: _DELIVERYSTATUS,
        discount: _Discount,
        delivery_date: _DELIVERY_DATE,
        items: _items,
        createdby: null,
        editedby: null,
        deletedby: _deletedby,
      },
    }),

  //-----PURCHASE API's-----//

  AddPurchase: (
    _vehicle,
    _orderStatus,
    _orderType,
    _cprNumber,
    _mobileNumber,
    _date,
    _flat_shopNo,
    _building,
    _road,
    _block,
    _area,
    _contactPerson,
    _items,
    _created_by
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/purchase/addPurchase`,
      body: {
        fk_customerid: _cprNumber,
        fk_addressid: _flat_shopNo,
        fk_vehicleid: _vehicle,
        ordertype: _orderType,
        deliverystatus: _orderStatus,
        discount: 0,
        delivery_date: _date,
        items: _items,
        createdby: _created_by,
        editedby: null,
        deletedby: null,
      },
    }),

  UpdatePurchase: (
    _SALEID,
    _orderStatus,
    _orderType,
    _cprNumber,
    _mobileNumber,
    _date,
    _flat_shopNo,
    _building,
    _road,
    _block,
    _area,
    _vehicle,
    _contactPerson,
    _items,
    _edited_by
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/purchase/updatePurchase`,
      body: {
        saleid: _SALEID,
        fk_customerid: _cprNumber,
        fk_addressid: _flat_shopNo,
        fk_vehicleid: _vehicle,
        ordertype: _orderType,
        deliverystatus: _orderStatus,
        discount: 0,
        delivery_date: _date,
        items: _items,
        createdby: null,
        editedby: _edited_by,
        deletedby: null,
      },
    }),

  DeletePurchase: (
    _DELIVERYSTATUS,
    _DELIVERY_DATE,
    _Discount,
    _FK_ADDRESSID,
    _FK_CUSTOMERID,
    _FK_VEHICLEID,
    _ORDERTYPE,
    _SALEID,
    _items,
    _deletedby
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/purchase/removePurchase`,
      body: {
        saleid: _SALEID,
        fk_customerid: _FK_CUSTOMERID,
        fk_addressid: _FK_ADDRESSID,
        fk_vehicleid: _FK_VEHICLEID,
        ordertype: _ORDERTYPE,
        deliverystatus: _DELIVERYSTATUS,
        discount: _Discount,
        delivery_date: _DELIVERY_DATE,
        items: _items,
        createdby: null,
        editedby: null,
        deletedby: _deletedby,
      },
    }),

  FetchBill: (
    _CURRENCY,
    _DELIVERY_FROM,
    _DELIVERY_TO,
    _DUE_DATE,
    _FK_VENDORID,
    _INVOICE_DATE,
    _INVOICE_NO,
    _LPO_REF,
    _PURCHASEID,
    _created_by
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/purchase/getPurchaseBill`,
      body: {
        CURRENCY: _CURRENCY,
        DELIVERY_FROM: _DELIVERY_FROM,
        DELIVERY_TO: _DELIVERY_TO,
        DUE_DATE: _DUE_DATE,
        FK_VENDORID: _FK_VENDORID,
        INVOICE_DATE: _INVOICE_DATE,
        INVOICE_NO: _INVOICE_NO,
        LPO_REF: _LPO_REF,
        PURCHASEID: _PURCHASEID,
        createdby: _created_by,
      },
    }),

  //-----REFILL API's-----//

  AddRefill: (
    _deliveryDocketNo,
    _vendorId,
    _lpoRef,
    _vehicleNo,
    _date,
    _items,
    _created_by
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/docket/adddocket`,
      body: {
        deliveryDocketNo: _deliveryDocketNo,
        vendorId: _vendorId,
        lpoRef: _lpoRef,
        vehicleNo: _vehicleNo,
        date: _date,
        items: _items,
        createdby: _created_by,
        editedby: null,
        deletedby: null,
      },
    }),

  UpdateRefill: (
    _deliveryDocketNo,
    _vendorId,
    _lpoRef,
    _vehicleNo,
    _date,
    _items,
    _edited_by
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/docket/updatedocket`,
      body: {
        deliveryDocketNo: _deliveryDocketNo,
        vendorId: _vendorId,
        lpoRef: _lpoRef,
        vehicleNo: _vehicleNo,
        date: _date,
        items: _items,
        createdby: null,
        editedby: _edited_by,
        deletedby: null,
      },
    }),

  DeleteRefill: (
    _deliveryDocketNo,
    _vendorId,
    _lpoRef,
    _vehicleNo,
    _date,
    _items,
    _deletedby
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/docket/removedocket`,
      body: {
        deliveryDocketNo: _deliveryDocketNo,
        lpoRef: _lpoRef,
        vendorId: _vendorId,
        vehicleNo: _vehicleNo,
        date: _date,
        items: _items,
        createdby: null,
        editedby: null,
        deletedby: _deletedby,
      },
    }),

  //-----PURCHASE API's-----//

  GetDocketPurchase: (_fk_vendorid, _fromDate, _toDate) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/purchase/getPurchaseDocket`,
      body: {
        fk_vendorid: _fk_vendorid,
        fromDate: _fromDate,
        toDate: _toDate,
      },
    }),

  AddPurchase: (
    _currency,
    _deliveryDateFrom,
    _deliveryDateTo,
    _dueDate,
    _invoiceDate,
    _invoiceNo,
    _lpoRef,
    _vendorId,
    _created_by
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/purchase/addPurchase`,
      body: {
        currency: _currency,
        deliveryDateFrom: _deliveryDateFrom,
        deliveryDateTo: _deliveryDateTo,
        dueDate: _dueDate,
        invoiceDate: _invoiceDate,
        invoiceNo: _invoiceNo,
        lpoRef: _lpoRef,
        vendorId: _vendorId,
        createdby: _created_by,
        editedby: null,
        deletedby: null,
      },
    }),

  UpdatePurchase: (
    _CURRENCY,
    _DELIVERY_FROM,
    _DELIVERY_TO,
    _DUE_DATE,
    _FK_VENDORID,
    _INVOICE_DATE,
    _INVOICE_NO,
    _LPO_REF,
    _PURCHASEID,
    _edited_by
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/purchase/updatePurchase`,
      body: {
        purchaseId: _PURCHASEID,
        currency: _CURRENCY,
        deliveryDateFrom: _DELIVERY_FROM,
        deliveryDateTo: _DELIVERY_TO,
        dueDate: _DUE_DATE,
        invoiceDate: _INVOICE_DATE,
        invoiceNo: _INVOICE_NO,
        lpoRef: _LPO_REF,
        vendorId: _FK_VENDORID,
        createdby: null,
        editedby: _edited_by,
        deletedby: null,
      },
    }),

  DeletePurchase: (
    _CURRENCY,
    _DELIVERY_FROM,
    _DELIVERY_TO,
    _DUE_DATE,
    _FK_VENDORID,
    _INVOICE_DATE,
    _INVOICE_NO,
    _LPO_REF,
    _PURCHASEID,
    _deletedby
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/purchase/removePurchase`,
      body: {
        purchaseId: _PURCHASEID,
        currency: _CURRENCY,
        deliveryDateFrom: _DELIVERY_FROM,
        deliveryDateTo: _DELIVERY_TO,
        dueDate: _DUE_DATE,
        invoiceDate: _INVOICE_DATE,
        invoiceNo: _INVOICE_NO,
        lpoRef: _LPO_REF,
        vendorId: _FK_VENDORID,
        createdby: null,
        editedby: null,
        deletedby: _deletedby,
      },
    }),

  //----REPORTS----//

  CustomerReport: (_filterBy, _days, _status, _vehicle, _area) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/reports/customerReport`,
      body: {
        filterBy: _filterBy,
        days: _days,
        status: _status,
        vehicle: _vehicle,
        area: _area,
      },
    }),

  UpdateRefill: (
    _deliveryDocketNo,
    _vendorId,
    _lpoRef,
    _vehicleNo,
    _date,
    _items,
    _edited_by
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/docket/updatedocket`,
      body: {
        deliveryDocketNo: _deliveryDocketNo,
        vendorId: _vendorId,
        lpoRef: _lpoRef,
        vehicleNo: _vehicleNo,
        date: _date,
        items: _items,
        createdby: null,
        editedby: _edited_by,
        deletedby: null,
      },
    }),

  DeleteRefill: (
    _deliveryDocketNo,
    _vendorId,
    _lpoRef,
    _vehicleNo,
    _date,
    _items,
    _deletedby
  ) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/docket/removedocket`,
      body: {
        deliveryDocketNo: _deliveryDocketNo,
        lpoRef: _lpoRef,
        vendorId: _vendorId,
        vehicleNo: _vehicleNo,
        date: _date,
        items: _items,
        createdby: null,
        editedby: null,
        deletedby: _deletedby,
      },
    }),

  Signin: (_email, _password) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${Api}/Authentication/login`,
      body: {
        email: _email,
        password: _password,
      },
    }),
});

export default API;
