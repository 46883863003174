import Card from "components/card";

const OrderCards = ({
  extra,
  icon,
  heading,
  totalQuantity,
  deliveredQuantity,
}) => {
  return (
    <Card extra={`${extra} flex-row p-6 flex-grow w-48 rounded-[20px]`}>
      <div className="flex h-[50px] w-auto flex-row items-center">
        <div className="rounded-xl bg-lightPrimary p-3 dark:bg-navy-700">
          <span className="flex items-center text-brand-500 dark:text-white">
            {icon}
          </span>
        </div>
      </div>

      <div className=" mt-2 w-auto">
        <h4 className="py-2 text-lg font-bold text-navy-700 dark:text-white">
          {heading}
        </h4>
        <h4 className="py-2 text-xl font-bold text-navy-700 dark:text-white">
          {deliveredQuantity} / {totalQuantity}
        </h4>
      </div>
    </Card>
  );
};

export default OrderCards;
