import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import moment from "moment";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ReactPDFView from "../../ReactPDF/ReactPDF";
import Report from "../../reports";
import { Link } from "react-router-dom";
import API from "API/API";

const VehicleReport = () => {
  const [vehicles, setVehicles] = useState([]);
  const [formData, setFormData] = useState({
    fromDate: moment(new Date()),
    toDate: moment(new Date()),
    Vehicle: "",
  });

  const FetchVehicles = async () => {
    try {
      const result = await API().GetVehicle();
      if (result.data.status) {
        setVehicles(result.data.data);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    FetchVehicles();
  }, []);
  const inputCustomStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#1b3bbb",
      },
      "& fieldset": {
        borderColor: "#1b3bbb",
      },
      "&:hover fieldset": {
        borderColor: "#1b3bbb",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    background: "#fff",
  };
  const dateCustomStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#1b3bbb",
      },
      "& fieldset": {
        borderColor: "#1b3bbb",
      },
      "&:hover fieldset": {
        borderColor: "#1b3bbb",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    background: "#fff",
  };
  return (
    <div className="-mt-5 flex min-h-[95vh] w-full flex-col items-center rounded-b-2xl bg-white px-4">
      <h2 className="text-black flex w-full max-w-5xl py-5 text-3xl font-bold capitalize dark:text-white">
        Vehicle Report
      </h2>
      <section className="w-full max-w-5xl rounded-t-2xl border border-gray-300 bg-[#EBECF0] p-6 shadow-2xl dark:bg-navy-800 md:px-16 md:py-10">
        <form className="w-full" onSubmit={(e) => e.preventDefault()}>
          <div className="flex w-full flex-col">
            <div className="flex w-full justify-center rounded-xl bg-[#f6f6f6] py-4 px-1 shadow-xl sm:px-4 md:py-5 md:px-10 lg:px-8">
              <div className="grid w-full grid-cols-1 gap-10 px-4 py-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-y-8 lg:px-1">
                <div>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="From Date"
                      format="DD-MM-YYYY"
                      value={formData.fromDate}
                      onChange={(value) =>
                        setFormData({ ...formData, fromDate: moment(value) })
                      }
                      sx={dateCustomStyle}
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </LocalizationProvider>
                </div>

                <div>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="To Date"
                      format="DD-MM-YYYY"
                      value={formData.toDate}
                      onChange={(value) =>
                        setFormData({ ...formData, toDate: moment(value) })
                      }
                      sx={dateCustomStyle}
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </LocalizationProvider>
                </div>

                <div>
                  <FormControl fullWidth size="small" sx={inputCustomStyle}>
                    <InputLabel id="vehicle">Vehicles</InputLabel>
                    <Select
                      labelId="vehicle"
                      id="vehicle"
                      value={formData.Vehicle}
                      onChange={(e) =>
                        setFormData({ ...formData, Vehicle: e.target.value })
                      }
                      fullWidth
                      label="Vehicles"
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      size="small"
                    >
                      {vehicles.map((e, i) => (
                        <MenuItem key={i} value={e.REGISTRATION_NO}>
                          {e.REGISTRATION_NO}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <div className="mt-10 flex w-full justify-end">
                <div className="mr-5">
                  <ReactPDFView
                    reportName={"Vehicle-Report"}
                    sendEmail={true}
                    children={
                      <Report
                        reportName={"Vehicle Report"}
                        paramsData={formData}
                      />
                    }
                    buttonText="Send Via Email"
                  />
                </div>
                <ReactPDFView
                  reportName={"Vehicle-Report"}
                  sendEmail={false}
                  children={
                    <Report
                      reportName={"Vehicle Report"}
                      paramsData={formData}
                    />
                  }
                  buttonText="Generate PDF"
                />
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default VehicleReport;
