import React, { useContext, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  IconButton,
  Tooltip,
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
} from "@mui/material";
import { Delete, Discount, Edit } from "@mui/icons-material";
import PromptModal from "components/promptModal/PromptModal";
import moment from "moment";
import { AppContext } from "Context/AppContext";
import API from "API/API";

const SaleGrid = (props) => {
  const { userData } = useContext(AppContext);
  const [items, setItems] = useState(props.itemList);
  const [customers, setCustomers] = useState(props.customerList);
  const [vehicles, setVehicles] = useState(props.vehicleList);
  const [tableData, setTableData] = useState(props.gridData);
  const [deleteUserDetails, setDeleteUserDetails] = useState({});
  const [successModal, setSuccessModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [open, setOpen] = useState(false);

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {};

  const handleCancelRowEdits = () => {};

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteRow = async (userDetails) => {
    try {
      const res = await API().DeleteOrder(
        userDetails.DELIVERYSTATUS,
        userDetails.DELIVERY_DATE,
        userDetails.Discount,
        userDetails.FK_ADDRESSID,
        userDetails.FK_CUSTOMERID,
        userDetails.FK_VEHICLEID,
        userDetails.ORDERTYPE,
        userDetails.SALEID,
        userDetails.items,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      handleClose();
      if (res.data.status) {
        setFeedbackMessage("Entry Deleted");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error While Deleting Data");
        setSuccessModal(true);
      }
    } catch (err) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const columns = useMemo(() => [
    {
      accessorKey: "SALEID",
      header: "Sale ID",
      size: 80,
    },
    {
      accessorFn: (row) =>
        customers.filter((elem) => row.FK_CUSTOMERID == elem.CUSTOMERID)[0]
          .FIRSTNAME,
      accessorKey: "FK_CUSTOMERID",
      header: "Customer",
      size: 80,
    },
    {
      accessorFn: (row) =>
        customers
          .filter((elem) => row.FK_CUSTOMERID == elem.CUSTOMERID)[0]
          .address.filter((e) => row.FK_ADDRESSID == e.addressId)[0].area,
      accessorKey: "FK_ADDRESSID",
      header: "Address",
      size: 80,
    },
    {
      accessorFn: (row) =>
        vehicles.filter((elem) => row.FK_VEHICLEID == elem.ID)[0]
          .REGISTRATION_NO,
      accessorKey: "FK_VEHICLEID",
      header: "Vehicle No.",
      size: 80,
    },
    {
      accessorKey: "ORDERTYPE",
      header: "Order Type",
      size: 80,
    },
    {
      accessorKey: "DELIVERYSTATUS",
      header: "Delivery Status",
      size: 80,
    },
    {
      accessorKey: "DISCOUNT",
      header: "Discount",
      size: 80,
    },
    {
      accessorFn: (row) =>
        moment(row.DELIVERY_DATE).format("DD-MM-YYYY hh:mm a"),
      accessorKey: "DELIVERY_DATE",
      header: "Delivery Date/Time",
      size: 80,
    },
  ]);

  return (
    <>
      <PromptModal
        ModalState={successModal}
        feedbackMessage={feedbackMessage}
        onClick={() => {
          props.callSalesDB();
          setSuccessModal(false);
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Remove this Order
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteRow(deleteUserDetails);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <MaterialReactTable
        muiTableBodyRowProps={{ sx: { background: "#EBECF0" } }}
        muiTopToolbarProps={{ sx: { background: "#EBECF0" } }}
        muiBottomToolbarProps={{ sx: { background: "#EBECF0" } }}
        muiTableHeadRowProps={{ sx: { background: "#EBECF0" } }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal"
        enableColumnOrdering
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton
                onClick={() => {
                  props.handleRowEdit(row.original);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton
                color="error"
                onClick={() => {
                  setDeleteUserDetails(row.original);
                  setOpen(true);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
    </>
  );
};

export default SaleGrid;
