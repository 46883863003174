import React, { useEffect } from "react";
import {
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Box,
} from "@mui/material";
import { useState, useContext, useMemo, useId } from "react";
import moment from "moment";
import { AppContext } from "Context/AppContext";
import API from "API/API";
import PromptModal from "components/promptModal/PromptModal";
import Loading from "components/loading";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MaterialReactTable } from "material-react-table";

const data = [
  {
    itemId: "",
    description: "",
    quantity: "",
    unit: "",
    unitPrice: "",
    vatPercent: "",
    vatAmount: "",
  },
];

export const PurchaseForm = (props) => {
  const { userData } = useContext(AppContext);
  const [tableData, setTableData] = useState(props.docketList);
  const [rowIndex, setRowIndex] = useState(0);
  const [vendors, setVendors] = useState(props.vendorList);
  const [dockets, setDockets] = useState(props.docketList);
  const [items, setItems] = useState(props.itemList);
  const [vehicles, setVehicles] = useState(props.vehicleList);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataToAdd, setDatatoAdd] = useState({});
  const [successModal, setSuccessModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [formData, setFormData] = useState({
    invoiceNo: "",
    invoiceDate: moment(new Date()),
    dueDate: moment(new Date()),
    lpoRef: "",
    vendorId: "",
    deliveryDateFrom: moment(new Date()),
    deliveryDateTo: moment(new Date()),
    currency: "",
  });

  const handleSaveCell = (cell, value) => {
    if (cell.column.id == "itemId") {
      tableData[cell.row.index][cell.column.id] = value.ITEMID;
      tableData[cell.row.index]["description"] = value.DESCRIPTION;
    }
    tableData[cell.row.index][cell.column.id] = value;
    setFormData({ ...formData, items: tableData });
  };

  const addPurchase = async (values) => {
    setIsLoading(true);
    handleDialogClose();
    try {
      const res = await API().AddPurchase(
        values.currency,
        values.deliveryDateFrom,
        values.deliveryDateTo,
        values.dueDate,
        values.invoiceDate,
        values.invoiceNo,
        values.lpoRef,
        values.vendorId,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      if (res.data.status) {
        setFeedbackMessage("Purchase Added Succesfully");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error Adding Data");
        setSuccessModal(true);
      }
    } catch (error) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const getDocketByDate = async (data) => {
    try {
      const res = await API().GetDocketPurchase(
        data.vendorId,
        data.deliveryDateFrom,
        data.deliveryDateTo
      );
      if (res.data.status) {
        setTableData(res.data.data);
      } else {
        setTableData([]);
      }
    } catch (error) {
      console.log(error, "ERROR");
    }
  };
  useEffect(() => {
    getDocketByDate(formData);
  }, [formData.deliveryDateFrom, formData.deliveryDateTo, formData.vendorId]);

  const dateCustomStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#1b3bbb",
      },
      "& fieldset": {
        borderColor: "#1b3bbb",
      },
      "&:hover fieldset": {
        borderColor: "#1b3bbb",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    background: "#fff",
  };
  const inputCustomStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#1b3bbb",
      },
      "& fieldset": {
        borderColor: "#1b3bbb",
      },
      "&:hover fieldset": {
        borderColor: "#1b3bbb",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
    background: "#fff",
  };
  const inputCustomStylegrid = {
    "& .MuiInputBase-root": {
      "&:after": {
        borderBottom: "2px solid #24388a",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
  };
  const inputCustomStyleGrid = {
    "& .MuiInputBase-root": {
      "&:after": {
        borderBottom: "2px solid #24388a",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
  };

  const columns = useMemo(() => [
    {
      accessorKey: "deliveryDocketNo",
      header: "Docket No.",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          disabled
          variant="standard"
          sx={inputCustomStylegrid}
          id="deliveryDocketNo"
          value={cell.row.original.deliveryDocketNo}
          onChange={(e) => handleSaveCell(cell, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "vendorId",
      header: "Vendor",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          disabled
          variant="standard"
          sx={inputCustomStylegrid}
          id="vendorId"
          value={cell.row.original.vendorId}
          onChange={(e) => handleSaveCell(cell, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "vehicleNo",
      header: "Vehicle",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          disabled
          variant="standard"
          sx={inputCustomStylegrid}
          id="vehicleNo"
          value={
            vehicles.filter((e) => e.ID == cell.row.original.vehicleNo)[0]
              .REGISTRATION_NO
          }
          onChange={(e) => handleSaveCell(cell, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "items",
      header: "Quantity",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          disabled
          variant="standard"
          sx={inputCustomStylegrid}
          id="quantity"
          value={cell.row.original.items.reduce(function (item1, item2) {
            return item1.quantity
              ? item1.quantity + item2.quantity
              : item1 + item2.quantity;
          })}
          onChange={(e) => handleSaveCell(cell, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "items",
      header: "Price",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          disabled
          variant="standard"
          sx={inputCustomStylegrid}
          id="unitPrice"
          value={
            cell.row.original.items.map(
              (item) => item.quantity * parseInt(item.unitPrice)
            )[0]
          }
          onChange={(e) => handleSaveCell(cell, e.target.value)}
        />
      ),
    },
  ]);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div
        className={`absolute w-full px-10 ${
          isLoading ? "flex" : "hidden"
        } z-50 h-full flex-col items-center justify-center backdrop-blur-sm`}
      >
        <Loading />
      </div>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Save/Update ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Save this Bill
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              addPurchase(formData);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <PromptModal
        ModalState={successModal}
        feedbackMessage={feedbackMessage}
        onClick={() => {
          setSuccessModal(false);
          props.callPurchaseDB();
        }}
      />
      <section className="w-full max-w-5xl rounded-t-2xl border border-gray-300 bg-[#EBECF0] p-6 shadow-2xl dark:bg-navy-800 md:px-16 md:py-10">
        <form className="w-full" onSubmit={(e) => e.preventDefault()}>
          <div className="flex w-full flex-col">
            <div className="flex w-full justify-center rounded-xl bg-[#f6f6f6] py-4 px-1 shadow-xl sm:px-4 md:py-5 md:px-10 lg:px-8">
              <div className="grid grid-cols-1 gap-10 px-4 py-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-y-8 lg:px-1">
                <div>
                  <TextField
                    fullWidth
                    id="invoiceNo"
                    name="invoiceNo"
                    label="Invoice No."
                    value={formData.invoiceNo}
                    onChange={(e) =>
                      setFormData({ ...formData, invoiceNo: e.target.value })
                    }
                    variant="outlined"
                    size="small"
                    sx={inputCustomStyle}
                  />
                </div>

                <div>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      onChange={(newValue) =>
                        setFormData({ ...formData, invoiceDate: newValue })
                      }
                      label="Invoice Date"
                      value={formData.invoiceDate}
                      format="DD-MM-YYYY"
                      sx={dateCustomStyle}
                      disabled={true}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          // onKeyPress: (event) => {
                          //   const { key } = event;
                          //   if (key === "Enter") {
                          //     event.preventDefault();
                          //     deliveryTimeRef.current.focus();
                          //   }
                          // },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>

                <div>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      onChange={(newValue) =>
                        setFormData({ ...formData, dueDate: newValue })
                      }
                      label="Due Date"
                      value={formData.dueDate}
                      format="DD-MM-YYYY"
                      sx={dateCustomStyle}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          // onKeyPress: (event) => {
                          //   const { key } = event;
                          //   if (key === "Enter") {
                          //     event.preventDefault();
                          //     deliveryTimeRef.current.focus();
                          //   }
                          // },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>

                <div>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={inputCustomStyle}
                    slotprops={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                      },
                    }}
                  >
                    <InputLabel id="vendor-id">Vendor Name</InputLabel>
                    <Select
                      labelId="vendor-id"
                      id="vendorId"
                      label="Vendor Name"
                      value={formData.vendorId}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          vendorId: e.target.value,
                        });
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                      displayEmpty
                      size="small"
                    >
                      {vendors.map((item, i) => (
                        <MenuItem key={i} value={item.VENDORID}>
                          {item.COMPANY_NAME}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      onChange={(newValue) => {
                        if (new Date(newValue) < new Date()) {
                          setFormData({
                            ...formData,
                            deliveryDateFrom: moment(newValue),
                          });
                        } else {
                          setFormData({
                            ...formData,
                            deliveryDateFrom: moment(new Date()),
                          });
                        }
                      }}
                      label="Delivery Date From"
                      value={formData.deliveryDateFrom}
                      format="DD-MM-YYYY"
                      sx={dateCustomStyle}
                      disableFuture={true}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          // onKeyPress: (event) => {
                          //   const { key } = event;
                          //   if (key === "Enter") {
                          //     event.preventDefault();
                          //     deliveryTimeRef.current.focus();
                          //   }
                          // },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>

                <div>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      onChange={(newValue) => {
                        if (
                          new Date(newValue) >=
                          new Date(formData.deliveryDateFrom)
                        ) {
                          if (new Date(newValue) < new Date()) {
                            setFormData({
                              ...formData,
                              deliveryDateTo: moment(newValue),
                            });
                          } else {
                            setFormData({
                              ...formData,
                              deliveryDateTo: moment(new Date()),
                            });
                          }
                        } else {
                          setFormData({
                            ...formData,
                            deliveryDateTo: moment(new Date()),
                          });
                        }
                      }}
                      label="Delivery Date To"
                      value={formData.deliveryDateTo}
                      format="DD-MM-YYYY"
                      sx={dateCustomStyle}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          // onKeyPress: (event) => {
                          //   const { key } = event;
                          //   if (key === "Enter") {
                          //     event.preventDefault();
                          //     deliveryTimeRef.current.focus();
                          //   }
                          // },
                        },
                      }}
                      disableFuture={true}
                    />
                  </LocalizationProvider>
                </div>

                <div>
                  <TextField
                    fullWidth
                    id="currency"
                    name="currency"
                    label="Currency"
                    value={formData.currency}
                    onChange={(e) =>
                      setFormData({ ...formData, currency: e.target.value })
                    }
                    variant="outlined"
                    size="small"
                    sx={inputCustomStyle}
                  />
                </div>

                <div>
                  <TextField
                    fullWidth
                    id="lpoRef"
                    name="lpoRef"
                    label="Lpo Ref"
                    value={formData.lpoRef}
                    onChange={(e) =>
                      setFormData({ ...formData, lpoRef: e.target.value })
                    }
                    variant="outlined"
                    size="small"
                    sx={inputCustomStyle}
                  />
                </div>
              </div>
            </div>
            <div className="my-10">
              <div className="mt-10 flex w-full justify-center rounded-xl bg-[#f6f6f6] py-4 px-4 shadow-xl sm:px-8 md:py-10 md:px-10 lg:mx-1 lg:mt-0 lg:px-10">
                <div className="grid w-full grid-cols-1 ">
                  <MaterialReactTable
                    columns={columns}
                    enableTopToolbar={false}
                    data={tableData}
                    muiTablePaperProps={{ sx: { borderRadius: "10px" } }}
                    muiTableContainerProps={{ sx: { borderRadius: "10px" } }}
                    editingMode="table"
                    autoResetPageIndex={false}
                    enableEditing
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end">
              <button
                onClick={() => setOpen(true)}
                type="submit"
                className="mr-2 transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none sm:mr-0"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </section>
    </Box>
  );
};
