import {
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material";
import { useContext, useRef, useState } from "react";
import { useFormik } from "formik";
import moment from "moment";
import * as yup from "yup";
import API from "API/API";
import { AppContext } from "Context/AppContext";
import Loading from "components/loading";
import PromptModal from "components/promptModal/PromptModal";

const VendorForm = (props) => {
  const { userData } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataToAdd, setDatatoAdd] = useState({});
  const [successModal, setSuccessModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const companyNameRef = useRef(null);
  const addressRef = useRef(null);
  const phoneNoRef = useRef(null);
  const emailRef = useRef(null);
  const vatNoRef = useRef(null);

  const addVendor = async (values) => {
    setIsLoading(true);
    handleDialogClose();
    try {
      const res = await API().AddVendor(
        values.crNumber,
        values.companyName,
        values.address,
        values.phoneNo,
        values.email,
        values.vatNo,
        values.created_by
      );
      if (res.data.status) {
        setFeedbackMessage("Vendor Added Succesfully");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error While Adding Data");
        setSuccessModal(true);
      }
    } catch (err) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const inputCustomStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#1b3bbb",
      },
      "& fieldset": {
        borderColor: "#1b3bbb",
      },
      "&:hover fieldset": {
        borderColor: "#1b3bbb",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
    background: "#fff",
  };

  const vendorValidationSchema = yup.object({
    crNumber: yup
      .string()
      .required("CR Number is Required")
      .min(2, "Min 2 Characters")
      .max(30, "Max 30 Characters"),
    companyName: yup
      .string()
      .required("Company Name is Required")
      .min(2, "Min 2 Characters")
      .max(40, "Max 40 Characters"),
    address: yup
      .string()
      .required("Address is Required")
      .min(2, "Min 2 Characters")
      .max(50, "Max 50 Characters"),
    phoneNo: yup
      .string()
      .required("Phone Number is Required")
      .min(1, "Min 1 Characters")
      .max(15, "Max 15 Characters"),
    email: yup
      .string()
      .email("Field Should be an Email Address")
      .required("Email is Required")
      .min(2, "Min 2 Characters")
      .max(30, "Max 30 Characters"),
    vatNo: yup
      .number("Field Should be a Number")
      .required("VAT Number is Required")
      .min(0, "Min 0 Value"),
  });

  const formik = useFormik({
    initialValues: {
      crNumber: "",
      companyName: "",
      address: "",
      phoneNo: "",
      email: "",
      vatNo: "",
      created_by: `${userData.USERNAME} | ${moment(new Date()).format("lll")}`,
    },
    validationSchema: vendorValidationSchema,
    onSubmit: (values) => {
      setDatatoAdd(values);
      setOpen(true);
    },
  });

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        className={`absolute w-full px-10 ${
          isLoading ? "flex" : "hidden"
        } z-50 h-full flex-col items-center justify-center backdrop-blur-sm`}
      >
        <Loading />
      </div>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Save/Update ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Add this Vendor
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              addVendor(dataToAdd);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <PromptModal
        ModalState={successModal}
        feedbackMessage={feedbackMessage}
        onClick={() => {
          setSuccessModal(false);
          props.callVendorDB();
        }}
      />
      <section className="w-full max-w-5xl rounded-t-2xl border border-gray-300 bg-[#EBECF0] p-6 shadow-2xl dark:bg-navy-800 md:p-16">
        <div className="flex w-full justify-center rounded-xl bg-[#f6f6f6] py-4 px-1 shadow-xl sm:px-4 md:py-10 md:px-10 lg:px-16">
          <form className="w-full" onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-1 gap-10 px-4 py-4 sm:grid-cols-2 lg:mt-4 lg:grid-cols-3 lg:gap-y-14 lg:px-10">
              <div>
                <TextField
                  fullWidth
                  id="crNumber"
                  autoFocus
                  label="CR Number"
                  name="crNumber"
                  value={formik.values.crNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.crNumber && Boolean(formik.errors.crNumber)
                  }
                  helperText={formik.touched.crNumber && formik.errors.crNumber}
                  variant="outlined"
                  size="small"
                  sx={inputCustomStyle}
                  inputProps={{
                    maxLength: 10,
                    onKeyPress: (event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.preventDefault();
                        companyNameRef.current.focus();
                      }
                    },
                  }}
                />
              </div>

              <div>
                <TextField
                  fullWidth
                  id="companyName"
                  inputRef={companyNameRef}
                  label="Company Name"
                  name="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.companyName &&
                    Boolean(formik.errors.companyName)
                  }
                  helperText={
                    formik.touched.companyName && formik.errors.companyName
                  }
                  variant="outlined"
                  size="small"
                  sx={inputCustomStyle}
                  inputProps={{
                    maxLength: 40,
                    onKeyPress: (event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.preventDefault();
                        addressRef.current.focus();
                      }
                    },
                  }}
                />
              </div>

              <div>
                <TextField
                  fullWidth
                  id="address"
                  inputRef={addressRef}
                  label="Address"
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  variant="outlined"
                  size="small"
                  sx={inputCustomStyle}
                  inputProps={{
                    maxLength: 50,
                    onKeyPress: (event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.preventDefault();
                        phoneNoRef.current.focus();
                      }
                    },
                  }}
                />
              </div>

              <div>
                <TextField
                  type="number"
                  fullWidth
                  inputRef={phoneNoRef}
                  id="phoneNo"
                  label="Phone No."
                  name="phoneNo"
                  value={formik.values.phoneNo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.phoneNo && Boolean(formik.errors.phoneNo)
                  }
                  helperText={formik.touched.phoneNo && formik.errors.phoneNo}
                  variant="outlined"
                  size="small"
                  sx={inputCustomStyle}
                  inputProps={{
                    maxLength: 10,
                    onKeyPress: (event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.preventDefault();
                        emailRef.current.focus();
                      }
                    },
                  }}
                />
              </div>

              <div>
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  variant="outlined"
                  inputRef={emailRef}
                  size="small"
                  sx={inputCustomStyle}
                  inputProps={{
                    maxLength: 30,
                    onKeyPress: (event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.preventDefault();
                        vatNoRef.current.focus();
                      }
                    },
                  }}
                />
              </div>

              <div>
                <TextField
                  fullWidth
                  id="vatNo"
                  label="VAT No."
                  name="vatNo"
                  value={formik.values.vatNo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.vatNo && Boolean(formik.errors.vatNo)}
                  helperText={formik.touched.vatNo && formik.errors.vatNo}
                  variant="outlined"
                  inputRef={vatNoRef}
                  size="small"
                  sx={inputCustomStyle}
                  inputProps={{
                    maxLength: 20,
                  }}
                />
              </div>
            </div>
            <div className="mt-6 flex justify-end">
              <button
                type="submit"
                className="mr-2 transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none sm:mr-0"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </Box>
  );
};

export default VendorForm;
