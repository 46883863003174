import { useMemo, useState, useContext, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  IconButton,
  Tooltip,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { AppContext } from "Context/AppContext";
import API from "API/API";
import moment from "moment";
import PromptModal from "components/promptModal/PromptModal";

const UserGrid = (props) => {
  const [tableData, setTableData] = useState(props.usersList);
  const [vehciles, setVehicles] = useState(props.vehicleList);
  const [oldPassVal, setOldPassVal] = useState("");
  const [deleteUserDetails, setDeleteUserDetails] = useState({});
  const [dataToEdit, setDataToEdit] = useState({});
  const [passChanged, setPassChanged] = useState(false);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const { userData } = useContext(AppContext);

  const handleSaveRowEdits = async () => {
    handleEditDialogClose();
    try {
      const res = await API().UpdateUser(
        dataToEdit.EMAIL,
        `${passChanged ? dataToEdit.PASSWORD : oldPassVal}`,
        passChanged,
        dataToEdit.PORTAL,
        dataToEdit.ROLE,
        dataToEdit.USERID,
        dataToEdit.USERNAME,
        dataToEdit.FK_VEHICLEID,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      if (res.data.status) {
        setFeedbackMessage("User Updated");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error While Updating Data");
        setSuccessModal(true);
      }
    } catch (err) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const editConfirmation = ({ exitEditingMode }) => {
    setEditOpen(true);
  };

  const inputCustomStyle = {
    "& .MuiInputBase-root": {
      "&:after": {
        borderBottom: "2px solid #24388a",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
  };
  const inputCustomStyleHidden = {
    ".MuiFormControl-root": {
      display: "none",
    },
  };
  const handleCancelRowEdits = () => {};

  const handleDataEditChange = (name, value) => {
    const tempVal = dataToEdit;
    tempVal[name] = value;
    setDataToEdit((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDeleteRow = async (row) => {
    try {
      const res = await API().DeleteUser(
        row.EMAIL,
        row.PASSWORD,
        row.PORTAL,
        row.ROLE,
        row.USERID,
        row.USERNAME,
        row.FK_VEHICLEID,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      handleClose();
      if (res.data.status) {
        setFeedbackMessage("User Deleted");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error While Deleting Data");
        setSuccessModal(true);
      }
    } catch (err) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleEditDialogClose = () => {
    setEditOpen(false);
  };

  const columns = useMemo(() => [
    {
      accessorKey: "USERID",
      header: "User ID",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="userId"
          sx={inputCustomStyleHidden}
          fullWidth
          disabled
          value={dataToEdit.USERID}
          label="User ID"
          variant="standard"
          style={{ display: "none" }}
        />
      ),
    },
    {
      accessorKey: "USERNAME",
      header: "Username",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="username"
          sx={inputCustomStyle}
          value={dataToEdit.USERNAME}
          fullWidth
          name="USERNAME"
          label="Username"
          variant="standard"
          onChange={(e) => handleDataEditChange(e.target.name, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "EMAIL",
      header: "Email",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="email"
          sx={inputCustomStyle}
          value={dataToEdit.EMAIL}
          fullWidth
          name="EMAIL"
          label="Email"
          variant="standard"
          onChange={(e) => handleDataEditChange(e.target.name, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "PASSWORD",
      header: "Password",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="password"
          sx={inputCustomStyle}
          fullWidth
          name="PASSWORD"
          value={passChanged ? dataToEdit.PASSWORD : ""}
          label="Password"
          variant="standard"
          onChange={(e) => {
            if (e.target.value.length > 0) {
              setPassChanged(true);
            } else {
              setPassChanged(false);
            }
            handleDataEditChange(e.target.name, e.target.value);
          }}
        />
      ),
    },
    {
      accessorKey: "PORTAL",
      header: "Portal",
      size: 80,
      Edit: ({ cell }) => (
        <FormControl
          fullWidth
          variant="standard"
          size="small"
          sx={inputCustomStyle}
        >
          <InputLabel id="portal">Portal</InputLabel>
          <Select
            labelId="portal"
            id="portal"
            name="PORTAL"
            value={dataToEdit.PORTAL}
            onChange={(e) => {
              handleDataEditChange(e.target.name, e.target.value);
            }}
            fullWidth
            label="Portal"
            inputProps={{
              "aria-label": "Without label",
            }}
            displayEmpty
            size="small"
          >
            <MenuItem value="web">Web</MenuItem>
            <MenuItem value="mobile">Mobile</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      accessorKey: "FK_VEHICLEID",
      header: "Vehicle ID",
      size: 80,
      Edit: ({ cell }) => (
        <FormControl
          fullWidth
          variant="standard"
          size="small"
          sx={inputCustomStyle}
        >
          <InputLabel id="vehicleId">Vehicle ID</InputLabel>
          <Select
            labelId="vehicleId"
            id="FK_VEHICLEID"
            name="FK_VEHICLEID"
            value={dataToEdit.FK_VEHICLEID}
            onChange={(e) =>
              handleDataEditChange(e.target.name, e.target.value)
            }
            fullWidth
            label="Vehicle ID"
            inputProps={{
              "aria-label": "Without label",
            }}
            displayEmpty
            size="small"
          >
            {vehciles.map((e, i) => (
              <MenuItem key={i} value={e.ID}>
                {e.REGISTRATION_NO}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
    },
    {
      accessorKey: "ROLE",
      header: "Role",
      size: 80,
      Edit: ({ cell }) => (
        <FormControl
          fullWidth
          variant="standard"
          size="small"
          sx={inputCustomStyle}
        >
          <InputLabel id="role">Role</InputLabel>
          <Select
            labelId="role"
            id="ROLE"
            name="ROLE"
            value={dataToEdit.ROLE}
            onChange={(e) => {
              handleDataEditChange(e.target.name, e.target.value);
            }}
            fullWidth
            label="Role"
            inputProps={{
              "aria-label": "Without label",
            }}
            displayEmpty
            size="small"
          >
            <MenuItem value="Admin">Admin</MenuItem>
            <MenuItem value="Driver">Driver</MenuItem>
            <MenuItem value="Helper">Helper</MenuItem>
            <MenuItem value="Manager">Manager</MenuItem>
            <MenuItem value="Sales Man">Sales Man</MenuItem>
          </Select>
        </FormControl>
      ),
    },
  ]);
  return (
    <>
      <PromptModal
        ModalState={successModal}
        feedbackMessage={feedbackMessage}
        onClick={() => {
          setSuccessModal(false);
          props.callUserDB();
        }}
      />
      {/* DELETE DIALOG */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Remove this User
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteRow(deleteUserDetails);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* UPDATE DIALOG */}
      <Dialog
        open={editOpen}
        onClose={handleEditDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Save/Update?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Update this Item
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleEditDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSaveRowEdits(dataToEdit);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <MaterialReactTable
        muiTableBodyRowProps={{ sx: { background: "#EBECF0" } }}
        muiTopToolbarProps={{ sx: { background: "#EBECF0" } }}
        muiBottomToolbarProps={{ sx: { background: "#EBECF0" } }}
        muiTableHeadRowProps={{ sx: { background: "#EBECF0" } }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 40,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal" //default
        initialState={{
          columnVisibility: {
            PASSWORD: false,
            VEHICLEID: false,
            USERID: false,
          },
        }}
        enableColumnOrdering
        enableEditing
        onEditingRowSave={editConfirmation}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton
                onClick={() => {
                  setDataToEdit(row.original);
                  setOldPassVal(row.original.PASSWORD);
                  table.setEditingRow(row);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton
                color="error"
                onClick={() => {
                  setDeleteUserDetails(row.original);
                  setOpen(true);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
    </>
  );
};

export default UserGrid;
