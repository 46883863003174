import { useContext, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Box,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { AppContext } from "Context/AppContext";
import moment from "moment";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import API from "API/API";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import PromptModal from "components/promptModal/PromptModal";

const VehicleGrid = (props) => {
  const [tableData, setTableData] = useState(props.vehicleList);
  const [dataToEdit, setDataToEdit] = useState({});
  const [deleteUserDetails, setDeleteUserDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [colorValue, setColorValue] = useState(dataToEdit.COLOR);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [remarks, setRemarks] = useState("");
  const { userData } = useContext(AppContext);

  const handleSaveRowEdits = async ({ exitEditingMode }) => {
    handleEditDialogClose();
    try {
      const res = await API().UpdateVehicle(
        dataToEdit.ID,
        dataToEdit.MAKE,
        dataToEdit.REGISTRATION_DATE,
        dataToEdit.MODAL,
        dataToEdit.REGISTRATION_NO,
        dataToEdit.PURCHASE_AMOUNT,
        dataToEdit.WEIGHT,
        dataToEdit.ROUTE,
        dataToEdit.VEHICLE_STATUS,
        dataToEdit.REMARKS,
        dataToEdit.COLOR,
        dataToEdit.INSURANCE_DETAIL,
        dataToEdit.VEHICLE_PASSING,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      if (res.data.status) {
        setFeedbackMessage("Vehicle Updated");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error While Editing Data");
        setSuccessModal(true);
      }
    } catch (err) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const handleCancelRowEdits = () => {};

  const handleDeleteRow = async (row) => {
    const data = { ...row, REMARKS: remarks };
    try {
      const res = await API().DeleteVehicle(
        data.ID,
        data.MAKE,
        data.REGISTRATION_DATE,
        data.MODAL,
        data.REGISTRATION_NO,
        data.PURCHASE_AMOUNT,
        data.WEIGHT,
        data.ROUTE,
        data.VEHICLE_STATUS,
        data.REMARKS,
        data.COLOR,
        data.INSURANCE_DETAIL,
        data.VEHICLE_PASSING,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      handleClose();
      if (res.data.status) {
        setFeedbackMessage("Vehicle Deleted");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error While Editing Data");
        setSuccessModal(true);
      }
    } catch (err) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const editConfirmation = ({ exitEditingMode }) => {
    setEditOpen(true);
  };

  const inputCustomStyle = {
    "& .MuiInputBase-root": {
      "&:after": {
        borderBottom: "2px solid #24388a",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
  };

  const inputCustomStyleHidden = {
    ".MuiFormControl-root": {
      display: "none",
    },
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "100%",
        height: "25px",
        borderRadius: "2px",
        background: `${colorValue}`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
        width: "100%",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
        top: 0,
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleColorPickerClose = () => {
    setDisplayColorPicker(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditDialogClose = () => {
    setEditOpen(false);
  };

  const handleEditDataChange = (name, value) => {
    setDataToEdit({ ...dataToEdit, [name]: value });
  };

  const columns = useMemo(() => [
    {
      accessorKey: "ID",
      header: "ID",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="ID"
          sx={inputCustomStyleHidden}
          fullWidth
          name="ID"
          disabled
          label="ID"
          value={dataToEdit.ID}
          variant="standard"
        />
      ),
    },
    {
      accessorKey: "REGISTRATION_NO",
      header: "Registration No.",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="REGISTRATION_NO"
          sx={inputCustomStyle}
          fullWidth
          name="REGISTRATION_NO"
          label="Registration No."
          value={dataToEdit.REGISTRATION_NO}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      accessorKey: "MAKE",
      header: "Make",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="MAKE"
          sx={inputCustomStyle}
          fullWidth
          name="MAKE"
          label="Make"
          value={dataToEdit.MAKE}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      accessorKey: "MODAL",
      header: "Model",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="MODAL"
          sx={inputCustomStyle}
          fullWidth
          name="MODAL"
          label="Model"
          value={dataToEdit.MODAL}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      accessorKey: "PURCHASE_AMOUNT",
      header: "Purchase Amount",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="PURCHASE_AMOUNT"
          sx={inputCustomStyle}
          fullWidth
          name="PURCHASE_AMOUNT"
          type="number"
          label="Purchase Amount"
          value={dataToEdit.PURCHASE_AMOUNT}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      accessorKey: "WEIGHT",
      header: "Weight",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          id="WEIGHT"
          sx={inputCustomStyle}
          fullWidth
          name="WEIGHT"
          label="Weight"
          value={dataToEdit.WEIGHT}
          onChange={(e) => handleEditDataChange(e.target.name, e.target.value)}
          variant="standard"
        />
      ),
    },
    {
      accessorKey: "VEHICLE_STATUS",
      header: "Status",
      size: 80,
      Edit: ({ cell }) => (
        <FormControl
          fullWidth
          variant="standard"
          size="small"
          sx={inputCustomStyle}
        >
          <InputLabel id="vehicleStatus">Vehicle Status</InputLabel>
          <Select
            labelId="vehicleStatus"
            id="vehicleStatus"
            name="VEHICLE_STATUS"
            value={dataToEdit.VEHICLE_STATUS}
            onChange={(e) => {
              handleEditDataChange(e.target.name, e.target.value);
            }}
            fullWidth
            label="Vehicle Status"
            inputProps={{
              "aria-label": "Without label",
            }}
            size="small"
          >
            <MenuItem value={0}>Active</MenuItem>
            <MenuItem value={1}>Inactive</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      accessorFn: (row) => {
        return (
          <span
            className="h-2 w-4 rounded-md border border-gray-800 py-2 px-4"
            style={{ backgroundColor: `${row.COLOR}` }}
          />
        );
      },
      accessorKey: "COLOR",
      header: "Color",
      size: 80,
      Edit: ({ cell }) => (
        <div className="flex w-full items-center justify-center">
          <div className="mr-4 text-base font-medium text-navy-600">Color:</div>
          <div style={styles.swatch} onClick={handleClick}>
            <div style={styles.color} />
          </div>
          {displayColorPicker ? (
            <div style={styles.popover}>
              <div style={styles.cover} onClick={handleColorPickerClose} />
              <SketchPicker
                color={colorValue}
                name="COLOR"
                onChange={(value) => {
                  setColorValue(value.hex);
                  handleEditDataChange("COLOR", value.hex);
                }}
              />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      accessorFn: (row) => moment(row.INSURANCE_DETAIL).format("DD-MM-YYYY"),
      accessorKey: "INSURANCE_DETAIL",
      header: "Insurance Details",
      size: 80,
      Edit: ({ cell }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Registration Date"
            format="DD-MM-YYYY"
            value={moment(dataToEdit.INSURANCE_DETAIL)}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: false,
                variant: "standard",
                sx: inputCustomStyle,
              },
            }}
            onChange={(newValue) =>
              handleEditDataChange(
                "INSURANCE_DETAIL",
                moment(newValue).format("lll")
              )
            }
          />
        </LocalizationProvider>
      ),
    },
    {
      accessorFn: (row) => moment(row.VEHICLE_PASSING).format("DD-MM-YYYY"),
      accessorKey: "VEHICLE_PASSING",
      header: "Vehicle Passing",
      size: 80,
      Edit: ({ cell }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Registration Date"
            format="DD-MM-YYYY"
            value={moment(dataToEdit.VEHICLE_PASSING)}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: false,
                variant: "standard",
                sx: inputCustomStyle,
              },
            }}
            onChange={(newValue) =>
              handleEditDataChange(
                "VEHICLE_PASSING",
                moment(newValue).format("lll")
              )
            }
          />
        </LocalizationProvider>
      ),
    },
  ]);

  return (
    <>
      <PromptModal
        ModalState={successModal}
        feedbackMessage={feedbackMessage}
        onClick={() => {
          setSuccessModal(false);
          props.callVehicleDB();
        }}
      />
      {/* DELETE DIALOG */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Remove this Vehicle
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            sx={inputCustomStyle}
            multiline
            id="remarks"
            label="Remarks"
            fullWidth
            variant="standard"
            inputProps={{
              maxLength: 300,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteRow(deleteUserDetails);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* UPDATE DIALOG */}
      <Dialog
        open={editOpen}
        onClose={handleEditDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Save/Update?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Update this Vehicle
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleEditDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSaveRowEdits(dataToEdit);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <MaterialReactTable
        muiTableBodyRowProps={{ sx: { background: "#EBECF0" } }}
        muiTopToolbarProps={{ sx: { background: "#EBECF0" } }}
        muiBottomToolbarProps={{ sx: { background: "#EBECF0" } }}
        muiTableHeadRowProps={{ sx: { background: "#EBECF0" } }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        onEditingRowSave={editConfirmation}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton
                onClick={() => {
                  setDataToEdit(row.original);
                  table.setEditingRow(row);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton
                color="error"
                onClick={() => {
                  setDeleteUserDetails(row.original);
                  setOpen(true);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
    </>
  );
};

export default VehicleGrid;
