import {
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { useRef } from "react";

const OtherDetail = (props) => {
  const { formData } = props;
  const trnRef = useRef(null);
  const currencyRef = useRef(null);
  const paymentTermsRef = useRef(null);
  const enablePortalRef = useRef(null);
  const portalLanguageRef = useRef(null);
  const refferedByRef = useRef(null);

  const inputCustomStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#1b3bbb",
      },
      "& fieldset": {
        borderColor: "#1b3bbb",
      },
      "&:hover fieldset": {
        borderColor: "#1b3bbb",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
    background: "#fff",
  };

  return (
    <div className="flex w-full flex-col px-4">
      <h4 className="mt-2 pb-3 text-2xl font-bold text-navy-500">
        Other Details
      </h4>
      <form
        className="mt-4 rounded-md border border-gray-300 p-8"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="my-4 grid grid-cols-1 gap-y-14 sm:grid-cols-2 sm:gap-x-5 md:gap-x-14 lg:grid-cols-3 ">
          <div>
            <TextField
              fullWidth
              id="TAX_TREATMENT"
              label="Tax Treatment"
              variant="outlined"
              value={formData.TAX_TREATMENT}
              name="TAX_TREATMENT"
              onChange={(e) => props.onChange(e)}
              autoFocus
              size="small"
              sx={inputCustomStyle}
              inputProps={{
                onKeyPress: (event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    trnRef.current.focus();
                  }
                },
              }}
            />
          </div>

          <div>
            <TextField
              fullWidth
              id="TRN"
              label="VAT Number"
              variant="outlined"
              name="TRN"
              value={formData.TRN}
              onChange={(e) => props.onChange(e)}
              inputRef={trnRef}
              size="small"
              sx={inputCustomStyle}
              inputProps={{
                onKeyPress: (event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    currencyRef.current.focus();
                  }
                },
              }}
            />
          </div>

          <div>
            <TextField
              fullWidth
              id="CURRENY"
              label="App Status"
              inputRef={currencyRef}
              value={formData.CURRENY}
              name="CURRENY"
              onChange={(e) => props.onChange(e)}
              variant="outlined"
              size="small"
              sx={inputCustomStyle}
              inputProps={{
                maxLength: 15,
                onKeyPress: (event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    paymentTermsRef.current.focus();
                  }
                },
              }}
            />
          </div>

          <div>
            <FormControl
              fullWidth
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#1b3bbb",
                  },
                  "& fieldset": {
                    borderColor: "#1b3bbb",
                  },
                  "&:hover fieldset": {
                    borderColor: "#1b3bbb",
                  },
                },
                "& label.Mui-focused": {
                  color: "#24388a",
                },
                background: "#fff",
              }}
            >
              <InputLabel id="customerType">Mode Of Payment</InputLabel>
              <Select
                labelId="customerType"
                id="MODE_OF_PAYMENT"
                value={formData.MODE_OF_PAYMENT}
                onChange={(e) => props.onChange(e)}
                fullWidth
                label="Mode Of Payment"
                name="MODE_OF_PAYMENT"
                inputProps={{
                  "aria-label": "Without label",
                }}
                displayEmpty
                size="small"
              >
                <MenuItem value="cash">Cash</MenuItem>
                <MenuItem value="benefit">Benefit</MenuItem>
                <MenuItem value="credit">Credit</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div>
            <TextField
              fullWidth
              id="ENABLE_PORTAL"
              label="Enable Portal"
              inputRef={enablePortalRef}
              name="ENABLE_PORTAL"
              value={formData.ENABLE_PORTAL}
              onChange={(e) => props.onChange(e)}
              variant="outlined"
              size="small"
              sx={inputCustomStyle}
              inputProps={{
                onKeyPress: (event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    portalLanguageRef.current.focus();
                  }
                },
              }}
            />
          </div>

          <div>
            <TextField
              fullWidth
              id="PORTAL_LANGUAGE"
              label="Portal Language"
              value={formData.PORTAL_LANGUAGE}
              inputRef={portalLanguageRef}
              variant="outlined"
              name="PORTAL_LANGUAGE"
              onChange={(e) => props.onChange(e)}
              size="small"
              sx={inputCustomStyle}
              inputProps={{
                onKeyPress: (event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    refferedByRef.current.focus();
                  }
                },
              }}
            />
          </div>

          <div>
            <TextField
              fullWidth
              id="REFERRED_BY"
              label="Referred by"
              name="REFERRED_BY"
              value={formData.REFERRED_BY}
              onChange={(e) => props.onChange(e)}
              inputRef={refferedByRef}
              variant="outlined"
              size="small"
              sx={inputCustomStyle}
            />
          </div>

          <div>
            <TextField
              fullWidth
              id="password"
              label="Password"
              name="PASSWORD"
              value={formData.PASSWORD}
              onChange={(e) => props.onChange(e)}
              //inputRef={refferedByRef}
              variant="outlined"
              size="small"
              sx={inputCustomStyle}
            />
          </div>
        </div>
      </form>
      <div className="my-6 flex w-full justify-between">
        <button
          onClick={props.onClickPrev}
          className="transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none"
        >
          Previous
        </button>
        <button
          onClick={props.onClick}
          className="transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default OtherDetail;
