import React from "react";
import { Button, Modal, Typography } from "@mui/material";
import CyltraxImg from "assets/img/layout/cyltraxlogo.png";
import { motion } from "framer-motion";

function PromptModal(props) {
  return (
    <Modal
      open={props.ModalState}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          position: "absolute",
          display: "flex",
          height: "100vh",
          width: "100vw",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          style={{
            width: 400,
            backgroundColor: "#fff",
            border: "1px solid #24388a",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            boxShadow: 24,
            padding: "3rem 1rem 2rem",
          }}
        >
          <img src={CyltraxImg} width={200} />
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {props.feedbackMessage}
          </Typography>
          <div className="mt-5 flex w-full justify-end">
            <Button onClick={props.onClick} color="success" variant="contained">
              OK
            </Button>
          </div>
        </motion.div>
      </div>
    </Modal>
  );
}

export default PromptModal;
