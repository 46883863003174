import { useEffect, useState } from "react";
import SaleForm from "./components/SaleForm";
import SaleGrid from "./components/SaleGrid";
import API from "API/API";
import Loading from "components/loading";
import { Backdrop } from "@mui/material";
import moment from "moment";
import { MdOutlineRefresh } from "react-icons/md";

const Sales = () => {
  const [sales, setSales] = useState([]);
  const [items, setItems] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [errorState, setErrorState] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editingMode, setEditingMode] = useState(false);
  const [formData, setFormData] = useState({
    orderType: "Offline",
    orderStatus: "In Process",
    cprNumber: "",
    mobileNumber: "",
    firstName: "",
    lastName: "",
    date: moment(new Date()),
    flat_shopNo: "",
    building: "",
    road: "",
    block: "",
    area: "",
    vehicle: "",
    contactPerson: "",
    items: [],
  });

  const FetchSales = async () => {
    setIsLoading(true);
    emptyFieldsHandler();
    try {
      let result1 = new Promise((resolve, reject) => {
        resolve(API().GetSales());
      });
      let result2 = new Promise((resolve, reject) => {
        resolve(API().GetVehicle());
      });
      let result3 = new Promise((resolve, reject) => {
        resolve(API().GetItems());
      });
      let result4 = new Promise((resolve, reject) => {
        resolve(API().GetCustomers());
      });
      Promise.all([result1, result2, result3, result4])
        .then((e) => {
          if (
            e[0].data.status &&
            e[1].data.status &&
            e[2].data.status &&
            e[3].data.status
          ) {
            setSales(e[0].data.data);
            setVehicles(e[1].data.data);
            setItems(e[2].data.data);
            setCustomers(e[3].data.data);
          } else {
            setErrorState(true);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setErrorState(true);
          setIsLoading(false);
        });
    } catch (err) {
      setErrorState(true);
      setIsLoading(false);
    }
  };

  const handleRowEdit = (row) => {
    setEditingMode(false);
    const vehicleInfo = vehicles.filter(
      (elem) => elem.ID === row.FK_VEHICLEID
    )[0];
    const customerInfo = customers.filter(
      (elem) => elem.CUSTOMERID === row.FK_CUSTOMERID
    )[0];
    const customerAddressInfo = customerInfo.address.filter(
      (elem) => elem.addressId === row.FK_ADDRESSID
    )[0];
    setFormData({
      saleId: row.SALEID,
      orderType: row.ORDERTYPE,
      orderStatus: row.DELIVERYSTATUS,
      cprNumber: customerInfo,
      mobileNumber: customerInfo,
      firstName: customerInfo.FIRSTNAME,
      lastName: customerInfo.LASTNAME,
      date: moment(customerAddressInfo.preferred_times),
      flat_shopNo: customerAddressInfo,
      building: customerAddressInfo,
      road: customerAddressInfo,
      block: customerAddressInfo,
      area: customerAddressInfo,
      vehicle: vehicleInfo.ID,
      items: row.items,
      contactPerson: customerAddressInfo.contactPerson,
    });
    setTimeout(() => {
      setEditingMode(true);
    }, 0);
  };

  const emptyFieldsHandler = () => {
    setFormData({
      orderType: "Offline",
      orderStatus: "In Process",
      cprNumber: "",
      mobileNumber: "",
      firstName: "",
      lastName: "",
      date: moment(new Date()),
      flat_shopNo: "",
      building: "",
      road: "",
      block: "",
      area: "",
      vehicle: "",
      contactPerson: "",
      items: [],
    });
    setEditingMode(false);
  };

  useEffect(() => {}, [formData]);

  useEffect(() => {
    FetchSales();
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <Backdrop
            sx={{
              color: "#fff",
              background: "#F4F7FE",
              position: "relative",
              zIndex: 5,
              height: "100vh",
            }}
            open={isLoading}
          >
            <Loading />
          </Backdrop>
        </>
      ) : (
        <>
          {errorState ? (
            <div className="flex h-[84vh] flex-col items-center justify-center">
              <span
                className="text-navy-600"
                onClick={() => {
                  setIsLoading(true);
                  setErrorState(false);
                  FetchSales();
                }}
              >
                <MdOutlineRefresh className="h-28 w-28 -rotate-90 cursor-pointer hover:animate-spin" />
              </span>
              <p className="text-2xl font-bold text-navy-600">
                Server Error, Please Refresh
              </p>
            </div>
          ) : (
            <div className="-mt-5 flex w-full flex-col items-center rounded-b-2xl bg-white px-4">
              <h2 className="text-black flex w-full max-w-5xl py-5 text-3xl font-bold capitalize dark:text-white">
                Sales
              </h2>
              <SaleForm
                itemList={items}
                customerList={customers}
                vehicleList={vehicles}
                callSalesDB={FetchSales}
                editingMode={editingMode}
                formData={formData}
                disableEditingMode={emptyFieldsHandler}
              />
              <div className="mt-2 w-full max-w-5xl overflow-hidden rounded-b-2xl border border-gray-300">
                <SaleGrid
                  itemList={items}
                  customerList={customers}
                  vehicleList={vehicles}
                  handleRowEdit={handleRowEdit}
                  gridData={sales}
                  callSalesDB={FetchSales}
                  // openEditingMode={() => setEditingMode(true)}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default Sales;
