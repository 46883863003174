import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

function ABCReport(props) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.mainHeading}>
            <Text>EQUIPMENT AGREEMENT</Text>
            {/* <Text>{props.data.CUSTOMER_TYPE}</Text> */}
            {/* <Text> MASTER HOTEL MANAGEMENT AGREEMENT</Text> */}
          </View>
          <View style={styles.para1}>
            <Text>
              This Agreement is made with effect from 25 day of April, 2023 By
              and Between
            </Text>
          </View>
          <View style={styles.pararow}>
            <Text style={styles.h1}>Abu Saad Gas WLL, CR # 88116-4</Text>
            <Text style={styles.simpleP}>through its representative</Text>
            <Text style={styles.h1}>Mr Sameer Awan CPR # 920813470</Text>
          </View>
          <View style={styles.para2}>
            <Text>
              (hereinafter referred to as the “OWNER”), on the one part:
            </Text>
          </View>
          <View style={styles.center}>
            <Text style={styles.para2}>And</Text>
          </View>
          <View style={styles.pararow}>
            <Text style={styles.simpleH}>
              {props.data.FIRSTNAME}, CR # {props.data.CPR_NUMBER}
            </Text>
            <Text style={styles.simpleP1}>through its representative</Text>
            <Text style={styles.simpleH}>Muhammad Younas CPR # 640175805</Text>
          </View>
          <View style={styles.para2}>
            <Text>
              (hereinafter referred to as the “Borrower”), on the one part:
            </Text>
          </View>
          <View style={styles.para2}>
            <Text>The Parties here to agree as follows:</Text>
          </View>
          <View style={styles.pararow}>
            <Text style={styles.number}>1.</Text>
            <Text style={styles.numberP}>
              The Owner agrees to lend the Equipment, as detailed below, to be
              Borrower. The term of this agreement shall be for as indefinite
              period terminable by either party at any time, by giving the other
              party 2 days written notice.
            </Text>
          </View>
          <View>
            <Text style={styles.p3}>
              The Equipment details are mentioned below:
            </Text>
            <Text style={styles.p3}> 1) 10 Meter Copper Pipe </Text>
            <Text style={styles.p3}> 2) 6 Safety Valves </Text>
            <Text style={styles.p3}> 3) 5 Copper Tee </Text>
            <Text style={styles.space}> </Text>
            {/* for space bottom  */}
            <Text style={styles.p3}>
              Other than this equipment, OWNER has sold the Cylinders Box and 1
              100 LBS Cylinder and received 90 BD against this sale . Now there
              are 2 Cylinders at the buyer premises, One is the Property of
              Nashta Point Restaurant WLL and other Cylinder is the property of
              Abu Saad Gas WLL. GAS Detector is also fixed by ABU SAAD GAS WLL
              at the buyer premises and received Half Payment against this
              Detector i.e, 20 BD
            </Text>
            <Text style={styles.space}> </Text>
            {/* for space bottom  */}
            <Text style={styles.p3}>
              This agreement shall commence on the date of its execution by the
              authorized representatives of both parties.{" "}
            </Text>
          </View>
          <Text style={styles.space}> </Text>
          {/* for space bottom  */}
          <View style={styles.pararow}>
            <Text style={styles.number}>2.</Text>
            <Text style={styles.numberP}>
              The Borrower acknowledges that the Equipment mentioned above at
              all times shall be the sole and exclusive property of the Owner.
              The Borrower shall have no right, title on interest in or to the
              Equipment, except the time to use the Equipment as provided in
              this agreement. The Borrower hereby undertakes that it shall not
              at any time part with possession or remove form its premises the
              Equipment, nor shall the Borrower assign, mortgage, transfer,
              pledge or otherwise encumber this Agreement or any part thereof.{" "}
            </Text>
          </View>
          <View style={styles.pararow}>
            <Text style={styles.number1}>3.</Text>
            <Text style={styles.numberPP}>
              The Equipment shall be used exclusively and continuously used for
              GAS only.
            </Text>
          </View>
          <View style={styles.pararow}>
            <Text style={styles.number}>4.</Text>
            <Text style={styles.numberP}>
              The equipment shall be displayed, and remain at all times, in a
              prominent position in the premises of the Borrower, as agreed by
              the Owner.
            </Text>
          </View>
          <View style={styles.pararow}>
            <Text style={styles.number}>5.</Text>
            <Text style={styles.numberP}>
              The Owner shall have the right to enter upon the premises of the
              Borrower and take possession of the Equipment at any time, all
              without prejudice to any other rights that the Owner has under
              this Agreement or by Law.
            </Text>
          </View>
          <View style={styles.pararow}>
            <Text style={styles.number}>6.</Text>
            <Text style={styles.numberP}>
              The Owner shall have the right to inspect the Equipment at any
              reasonable time and shall have the right to remove the equipment,
              at any time, form the premises of the Borrower for the purpose of
              restoring, repairing, exchanging or replacing the Equipment.
            </Text>
          </View>
          <View style={styles.pararow1}>
            <Text style={styles.number}>7.</Text>
            <Text style={styles.numberP}>
              The Borrower assumes the entire risk of loss or damage to the
              Equipment. The Borrower agrees to and does hereby indemnify and
              hold the owner harmless of form and against all claims cost
              expenses damages and liabilities resulting form or incident to the
              use, operation or storage of the Equipment during the term of this
              Agreement.{" "}
            </Text>
          </View>
          <View style={styles.pararow}>
            <Text style={styles.number1}>8.</Text>
            <Text style={styles.numberPP}>
              The Borrower shall not make any alterations additions or
              improvements to the Equipment{" "}
            </Text>
          </View>
          <View style={styles.pararow}>
            <Text style={styles.number}>9.</Text>
            <Text style={styles.numberP}>
              The Equipment is the asset of the owner, and if any loss or damage
              may occur at the premises of the borrower, borrower will pay the
              amount of the equipment at current market value of the equipment.{" "}
            </Text>
          </View>
          <View style={styles.pararow}>
            <Text style={styles.number}>10.</Text>
            <Text style={styles.numberP1}>
              Borrower is not allowed to take refilling services from any other
              vendor instead of the owner, if at any time in the future, there
              is evidence that Borrower is taking that facility from any other
              vendor, owner has the right to remove their equipment and also
              charge penalty to the borrower.
            </Text>
          </View>
          <View style={styles.pararow}>
            <Text style={styles.number}>11.</Text>
            <Text style={styles.numberP1}>
              Owner will deliver the GAS as required by the borrower at its
              premises, and will charge 4.50 BD/ Cylinder and that amount will
              be paid at the time of delivery.
            </Text>
          </View>
          <View style={styles.pararow}>
            <Text style={styles.number1}>12.</Text>
            <Text style={styles.numberPP}>
              Any repairing services rendered by the owner in the future will be
              charged as per work done.
            </Text>
          </View>
          <View style={styles.pararow}>
            <Text style={styles.number}>13.</Text>
            <Text style={styles.numberP1}>
              No failure or delay by the Owner to exercise its rights thereunder
              shall operate as a waiver thereof. This Agreement constitutes the
              entire agreement of the parties with respect to the subject matter
              hereof. No amendment shall be effective unless in writing and
              signed by both parties.
            </Text>
          </View>
          <View style={styles.para1}>
            <Text>
              IN WITNESS WHEREOF, the parties hereto have executed this
              Agreement effective on the date herein provided.
            </Text>
          </View>
          <View style={styles.main}>
            <View style={styles.box1}>
              <Text style={styles.box1Text}>By:Abu Saad Gas WLL</Text>
              <Text style={styles.box1Text}>By: Sameer Awan</Text>
              <Text style={styles.box1Text}>Authorized Representative </Text>
              <Text style={styles.box1Text}>CPR No. 920813470</Text>
              <Text style={styles.box1Text}>Date:</Text>
              <View style={styles.childBox}>
                <Text style={styles.box1Text1}>1.</Text>
                <Text style={styles.box1Text1}>2.</Text>
              </View>
            </View>
            <View style={styles.box2}>
              <Text style={styles.box1Text}>Nashta Point Restaurant WLL</Text>
              <Text style={styles.box1Text}>By: Muhammad Younas</Text>
              <Text style={styles.box1Text}>Authorized Representative </Text>
              <Text style={styles.box1Text}>CPR No. # 640175805</Text>
              <Text style={styles.box1Text}>Date:</Text>
              <View style={styles.childBox}>
                <Text style={styles.box1Text1}>1.</Text>
                <Text style={styles.box1Text1}>2.</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default ABCReport;

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
  },
  section: {
    paddingHorizontal: 50,
    marginVertical: 10,
    flexGrow: 1,
  },
  mainHeading: {
    marginTop: 20,
    fontSize: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  para1: {
    marginTop: 20,
    fontSize: 10,
  },
  h1: {
    fontSize: 11,

    marginVertical: 20,
  },
  para2: {
    fontSize: 10,
    marginBottom: 20,
  },
  pararow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  pararow1: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 30,
  },
  simpleP: {
    fontSize: 10,
    marginVertical: 20,
    marginHorizontal: 5,
  },
  center: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  simpleH: {
    fontSize: 11,
    marginBottom: 20,
  },
  simpleP1: {
    fontSize: 10,
    marginHorizontal: 5,
  },
  number: {
    fontSize: 10,
    marginLeft: 10,
  },
  numberPg: {
    fontSize: 10,
    marginLeft: 10,
    marginBottom: 20,
  },
  number1: {
    fontSize: 10,
    marginLeft: 10,
    marginTop: -11,
  },
  numberP: {
    fontSize: 10,
    marginLeft: 23,
    marginTop: -10.5,
    marginBottom: 25,
  },
  numberPP: {
    fontSize: 10,
    marginLeft: 8,
    marginTop: -10.5,
    marginBottom: 25,
  },
  numberP1: {
    fontSize: 10,
    marginLeft: 27,
    marginTop: -10.5,
    marginBottom: 25,
  },
  space: {
    marginBottom: 1,
  },
  p3: {
    fontSize: 10,
    marginLeft: 20,
    marginBottom: 5,
  },
  main: {
    display: "flex",
    flexDirection: "row",
  },
  box1: {
    marginTop: 20,
    width: "50%",
    flexDirection: "column",
  },
  box2: {
    marginTop: 20,
    width: "50%",
    flexDirection: "column",
  },
  box1Text: {
    fontSize: 11,
    marginBottom: 20,
  },
  box1Text1: {
    fontSize: 11,
    marginBottom: 40,
  },
  childBox: {
    marginTop: 20,
  },
});
