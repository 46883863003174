import {
  Select,
  MenuItem,
  InputLabel,
  TextField,
  FormControl,
  Box,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  ListSubheader,
  InputAdornment,
} from "@mui/material";
import { AiOutlineSearch } from "react-icons/ai";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { useState, useRef, useMemo, useContext, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import API from "API/API";
import { AppContext } from "Context/AppContext";
import Loading from "components/loading";
import PromptModal from "components/promptModal/PromptModal";

const data = {
  itemId: "",
  itemQuantity: "",
  discount: "",
  salePrice: "",
};

const SaleForm = (props) => {
  const { userData } = useContext(AppContext);
  const [items, setItems] = useState(props.itemList);
  const [customers, setCustomers] = useState(props.customerList);
  const [vehicles, setVehicles] = useState(props.vehicleList);
  const [tableData, setTableData] = useState([{ ...data }]);
  const [rowIndex, setRowIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [currentCustomer, setCurrentCustomer] = useState(customers[0].address);
  const [reRender, setReRender] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [cprNumberSelect, setCprNumberSelect] = useState(false);
  const [formData, setFormData] = useState({
    orderType: "Offline",
    orderStatus: "In Process",
    cprNumber: customers[0],
    mobileNumber: customers[0],
    firstName: customers[0].FIRSTNAME,
    lastName: customers[0].LASTNAME,
    date: moment(new Date()),
    flat_shopNo: currentCustomer[0],
    building: currentCustomer[0],
    road: currentCustomer[0],
    block: currentCustomer[0],
    area: currentCustomer[0],
    vehicle: vehicles[0].ID,
    contactPerson: currentCustomer[0].contactPerson,
    items: tableData,
  });

  const cprnumberRef = useRef(null);
  const deliveryDateRef = useRef(null);
  const deliveryTimeRef = useRef(null);
  const mobNumRef = useRef(null);

  const handleSaveCell = (cell, value) => {
    tableData[cell.row.index][cell.column.id] = value;
    setFormData({ ...formData, items: tableData });
  };

  useEffect(() => {
    if (props.editingMode) {
      setFormData(props.formData);
      setTableData(props.formData.items);
      setRowIndex(props.formData.items.length - 1);
    }
  }, [props.editingMode]);

  const editConfirmation = () => {
    setEditOpen(true);
  };

  const handleCustomerChange = (value) => {
    setFormData({
      ...formData,
      cprNumber: value,
      mobileNumber: value,
      firstName: value.FIRSTNAME,
      lastName: value.LASTNAME,
      flat_shopNo: value.address[0],
      building: value.address[0],
      road: value.address[0],
      block: value.address[0],
      area: value.address[0],
      contactPerson: value.address[0].contact_person,
    });
    setCurrentCustomer(value.address);
  };

  const handleAddressChange = (value) => {
    setFormData({
      ...formData,
      flat_shopNo: value,
      building: value,
      road: value,
      block: value,
      area: value,
      contactPerson: value.contact_person,
    });
  };

  const handleSubmit = async (formData) => {
    setIsLoading(true);
    handleDialogClose();
    if (formData.items)
      formData.items = formData.items.filter((e) => e.itemQuantity);
    try {
      const res = await API().AddOrder(
        formData.vehicle,
        formData.orderStatus,
        formData.orderType,
        formData.cprNumber.CUSTOMERID,
        formData.mobileNumber.CUSTOMERID,
        formData.date,
        formData.flat_shopNo.addressId,
        formData.building.addressId,
        formData.road.addressId,
        formData.block.addressId,
        formData.area.addressId,
        formData.contactPerson,
        formData.items,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );

      if (res.data.status) {
        setFeedbackMessage(
          `Order Added Succesfully Order Number: ${res.data.orderNumber}`
        );
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error Adding Data");
        setSuccessModal(true);
      }
    } catch (error) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  useEffect(() => {}, [reRender]);

  const handleEditSubmit = async (formData) => {
    handleEditDialogClose();
    formData.items = formData.items
      .filter((ele) => ele.itemId)
      .map((element) => {
        return element.salePrice == "remove"
          ? { ...element, STATUS: 9, salePrice: 0 }
          : { ...element, STATUS: 1 };
      });
    try {
      const res = await API().UpdateOrder(
        formData.saleId,
        formData.orderStatus,
        formData.orderType,
        formData.cprNumber.CUSTOMERID,
        formData.mobileNumber.CUSTOMERID,
        formData.date,
        formData.flat_shopNo.addressId,
        formData.building.addressId,
        formData.road.addressId,
        formData.block.addressId,
        formData.area.addressId,
        formData.vehicle,
        formData.contactPerson,
        formData.items,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      if (res.data.status) {
        setFeedbackMessage("Order Updated");
        setSuccessModal(true);
        props.disableEditingMode();
      } else {
        setFeedbackMessage("Error While Editing Data");
        setSuccessModal(true);
      }
    } catch (error) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    }
  };

  const inputCustomStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#1b3bbb",
      },
      "& fieldset": {
        borderColor: "#1b3bbb",
      },
      "&:hover fieldset": {
        borderColor: "#1b3bbb",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
    background: "#fff",
  };
  const inputCustomStylegrid = {
    "& .MuiInputBase-root": {
      "&:after": {
        borderBottom: "2px solid #24388a",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
  };
  const inputCustomStyleGrid = {
    "& .MuiInputBase-root": {
      "&:after": {
        borderBottom: "2px solid #24388a",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleEditDialogClose = () => {
    setEditOpen(false);
  };

  const containsText = (text, searchText) =>
    text.toString().indexOf(searchText.toLowerCase()) > -1;

  const displayedOptions = useMemo(
    () =>
      customers.filter((option) => containsText(option.CPR_NUMBER, searchText)),
    [searchText]
  );

  const columns = useMemo(() => [
    {
      accessorKey: "itemId",
      header: "Item Abbreviation",
      size: 80,
      Edit: ({ cell }) => (
        <FormControl
          fullWidth
          variant="standard"
          size="small"
          sx={inputCustomStyleGrid}
        >
          <Select
            id="itemAbbreviation"
            value={
              cell.row.original.salePrice == "remove"
                ? "remove"
                : cell.row.original.itemId
            }
            onChange={(e) => {
              if (e.target.value == "remove") {
                setReRender(!reRender);
                tableData[cell.row.index]["salePrice"] = e.target.value;
              } else {
                tableData[cell.row.index]["salePrice"] = "";
                handleSaveCell(cell, e.target.value);
              }
              if (
                cell.row.index == rowIndex &&
                e.target.value.toString().length > 0
              ) {
                setTableData([
                  ...tableData,
                  {
                    itemId: "",
                    itemQuantity: "",
                    discount: "",
                    salePrice: "",
                  },
                ]);
                setRowIndex(rowIndex + 1);
              }
            }}
            fullWidth
            inputProps={{
              "aria-label": "Without label",
            }}
            size="small"
          >
            {props.editingMode && (
              <MenuItem value="remove">Remove Row</MenuItem>
            )}
            {items.map((item, i) => (
              <MenuItem key={i} value={item.ITEMID}>
                {item.ABBREVIATION}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
    },
    {
      accessorKey: "itemQuantity",
      header: "Item Quantity",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          type="number"
          variant="standard"
          sx={inputCustomStylegrid}
          id="item_quantity"
          value={cell.row.original.itemQuantity}
          onChange={(e) => handleSaveCell(cell, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "discount",
      header: "Discount",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          type="number"
          variant="standard"
          sx={inputCustomStylegrid}
          id="discount"
          value={cell.row.original.discount}
          onChange={(e) => handleSaveCell(cell, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "salePrice",
      header: "Sale Price",
      size: 80,
      Edit: ({ cell }) => (
        <TextField
          type="number"
          variant="standard"
          sx={inputCustomStylegrid}
          id="salePrice"
          value={cell.row.original.salePrice}
          onChange={(e) => handleSaveCell(cell, e.target.value)}
        />
      ),
    },
  ]);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        className={`absolute w-full px-10 ${
          isLoading ? "flex" : "hidden"
        } z-50 h-full flex-col items-center justify-center backdrop-blur-sm`}
      >
        <Loading />
      </div>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your Intention to Save/Update ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Add this Order
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSubmit(formData);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editOpen}
        onClose={handleEditDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your Intention to Save/Update ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Update this Order
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleEditDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleEditSubmit(formData);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <PromptModal
        ModalState={successModal}
        feedbackMessage={feedbackMessage}
        onClick={() => {
          props.callSalesDB();
          setSuccessModal(false);
        }}
      />
      <section className="w-full max-w-5xl rounded-t-2xl border border-gray-300 bg-[#EBECF0] px-6 py-1 shadow-2xl dark:bg-navy-800 md:p-10 md:py-5">
        <form className="w-full" onSubmit={(e) => e.preventDefault()}>
          <div className="flex w-full flex-col">
            <div className="flex w-full justify-center rounded-xl bg-[#f6f6f6] py-4 px-1 shadow-xl sm:px-4 md:py-2 md:px-10 lg:px-10">
              <div className="grid w-full grid-cols-1 gap-5 px-4 py-4 sm:grid-cols-2 lg:grid-cols-4 lg:gap-y-5 lg:px-5">
                <div>
                  <TextField
                    fullWidth
                    id="orderType"
                    value="Offline"
                    label="Order Type"
                    disabled
                    variant="outlined"
                    size="small"
                    sx={inputCustomStyle}
                  />
                </div>

                <div>
                  <TextField
                    fullWidth
                    id="orderStatus"
                    value="Delivered"
                    label="Order Status"
                    disabled
                    variant="outlined"
                    size="small"
                    sx={inputCustomStyle}
                  />
                </div>

                <div>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#1b3bbb",
                        },
                        "& fieldset": {
                          borderColor: "#1b3bbb",
                        },
                        "&:hover fieldset": {
                          borderColor: "#1b3bbb",
                        },
                      },
                      "& label.Mui-focused": {
                        color: "#24388a",
                      },
                      background: "#fff",
                    }}
                  >
                    <InputLabel id="mobNumber">Mobile Number</InputLabel>
                    <Select
                      labelId="mobNumber"
                      id="mobileNumber"
                      inputRef={mobNumRef}
                      name="mobileNumber"
                      value={formData.mobileNumber}
                      onChange={(e) => handleCustomerChange(e.target.value)}
                      fullWidth
                      label="Mobile Number"
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      size="small"
                    >
                      {customers.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item.MOBILE_NUMBER}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#1b3bbb",
                        },
                        "& fieldset": {
                          borderColor: "#1b3bbb",
                        },
                        "&:hover fieldset": {
                          borderColor: "#1b3bbb",
                        },
                      },
                      "& label.Mui-focused": {
                        color: "#24388a",
                      },
                      background: "#fff",
                    }}
                  >
                    <InputLabel id="cprnumber">CPR/CR Number</InputLabel>
                    <Select
                      MenuProps={{
                        autoFocus: false,
                        sx: {
                          "& .MuiPaper-root": {
                            maxHeight: "200px",
                          },
                        },
                      }}
                      labelId="cprnumber"
                      autoFocus
                      inputRef={cprnumberRef}
                      id="cprNumber"
                      name="cprNumber"
                      value={formData.cprNumber}
                      onChange={(e) => {
                        handleCustomerChange(e.target.value);
                      }}
                      onClose={() => setSearchText("")}
                      fullWidth
                      label="CPR/CR Number"
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      size="small"
                    >
                      <ListSubheader>
                        <TextField
                          size="small"
                          autoFocus
                          placeholder="Type to search..."
                          fullWidth
                          sx={inputCustomStyle}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AiOutlineSearch />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key == "Enter") {
                              handleCustomerChange(displayedOptions[0]);
                              e.view.closed = true;
                            }
                            if (e.key !== "Escape") {
                              // Prevents autoselecting item while typing (default Select behaviour)
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>
                      {displayedOptions.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item.CPR_NUMBER}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div>
                  <TextField
                    fullWidth
                    id="firstName"
                    value={formData.firstName}
                    label="FirstName"
                    disabled
                    variant="outlined"
                    size="small"
                    sx={inputCustomStyle}
                  />
                </div>

                <div>
                  <TextField
                    fullWidth
                    id="lastName"
                    value={formData.lastName ? formData.lastName : ""}
                    label="Last Name"
                    disabled
                    variant="outlined"
                    size="small"
                    sx={inputCustomStyle}
                  />
                </div>

                <div className="col-span-2">
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      onChange={(newValue) =>
                        setFormData({ ...formData, date: newValue })
                      }
                      inputRef={deliveryDateRef}
                      label="Date/ Time"
                      value={formData.date}
                      format="DD-MM-YYYY hh:mm a"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#1b3bbb",
                          },
                          "& fieldset": {
                            borderColor: "#1b3bbb",
                          },
                          "&:hover fieldset": {
                            borderColor: "#1b3bbb",
                          },
                        },
                        "& label.Mui-focused": {
                          color: "#24388a",
                        },
                        background: "#fff",
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          onKeyPress: (event) => {
                            const { key } = event;
                            if (key === "Enter") {
                              event.preventDefault();
                              deliveryTimeRef.current.focus();
                            }
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
                {/* 
                <div>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      onChange={(newValue) => {}}
                      inputRef={deliveryTimeRef}
                      value={formData.time}
                      label="Time"
                      format="hh:mm a"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#1b3bbb",
                          },
                          "& fieldset": {
                            borderColor: "#1b3bbb",
                          },
                          "&:hover fieldset": {
                            borderColor: "#1b3bbb",
                          },
                        },
                        "& label.Mui-focused": {
                          color: "#24388a",
                        },
                        background: "#fff",
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div> */}

                <div className="sm:col-span-2 lg:col-span-4">
                  <div className="grid grid-cols-1 gap-x-3 sm:grid-cols-2 lg:grid-cols-5">
                    <div className="my-3 lg:my-0">
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#1b3bbb",
                            },
                            "& fieldset": {
                              borderColor: "#1b3bbb",
                            },
                            "&:hover fieldset": {
                              borderColor: "#1b3bbb",
                            },
                          },
                          "& label.Mui-focused": {
                            color: "#24388a",
                          },
                          background: "#fff",
                        }}
                      >
                        <InputLabel id="flatShopNo">Flat/Shop No.</InputLabel>
                        <Select
                          labelId="flatShopNo"
                          value={formData.flat_shopNo}
                          id="flatShopNo"
                          fullWidth
                          label="Flat/Shop No."
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          onChange={(e) => handleAddressChange(e.target.value)}
                          size="small"
                        >
                          {currentCustomer.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item.flat_shop}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="my-3 lg:my-0">
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#1b3bbb",
                            },
                            "& fieldset": {
                              borderColor: "#1b3bbb",
                            },
                            "&:hover fieldset": {
                              borderColor: "#1b3bbb",
                            },
                          },
                          "& label.Mui-focused": {
                            color: "#24388a",
                          },
                          background: "#fff",
                        }}
                      >
                        <InputLabel id="buildingNo">Building</InputLabel>
                        <Select
                          labelId="building"
                          value={formData.building}
                          id="building"
                          fullWidth
                          label="Building"
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          onChange={(e) => handleAddressChange(e.target.value)}
                          size="small"
                        >
                          {currentCustomer.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item.building}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="my-3 lg:my-0">
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#1b3bbb",
                            },
                            "& fieldset": {
                              borderColor: "#1b3bbb",
                            },
                            "&:hover fieldset": {
                              borderColor: "#1b3bbb",
                            },
                          },
                          "& label.Mui-focused": {
                            color: "#24388a",
                          },
                          background: "#fff",
                        }}
                      >
                        <InputLabel id="road">Road</InputLabel>
                        <Select
                          labelId="road"
                          value={formData.road}
                          id="road"
                          fullWidth
                          label="Road"
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          onChange={(e) => handleAddressChange(e.target.value)}
                          size="small"
                        >
                          {currentCustomer.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item.road}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="my-3  lg:my-0">
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#1b3bbb",
                            },
                            "& fieldset": {
                              borderColor: "#1b3bbb",
                            },
                            "&:hover fieldset": {
                              borderColor: "#1b3bbb",
                            },
                          },
                          "& label.Mui-focused": {
                            color: "#24388a",
                          },
                          background: "#fff",
                        }}
                      >
                        <InputLabel id="block">Block</InputLabel>
                        <Select
                          labelId="block"
                          value={formData.block}
                          id="block"
                          fullWidth
                          label="Block"
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          onChange={(e) => handleAddressChange(e.target.value)}
                          size="small"
                        >
                          {currentCustomer.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item.block}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="my-3 sm:col-span-2 lg:col-span-1 lg:my-0">
                      <FormControl
                        fullWidth
                        size="small"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#1b3bbb",
                            },
                            "& fieldset": {
                              borderColor: "#1b3bbb",
                            },
                            "&:hover fieldset": {
                              borderColor: "#1b3bbb",
                            },
                          },
                          "& label.Mui-focused": {
                            color: "#24388a",
                          },
                          background: "#fff",
                        }}
                      >
                        <InputLabel id="area">Area</InputLabel>
                        <Select
                          labelId="area"
                          value={formData.area}
                          id="area"
                          fullWidth
                          label="Area"
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          onChange={(e) => handleAddressChange(e.target.value)}
                          size="small"
                        >
                          {currentCustomer.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item.area}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div className="col-span-4 grid grid-cols-3 gap-5">
                  <div>
                    <FormControl fullWidth size="small" sx={inputCustomStyle}>
                      <InputLabel id="vehicle">Vehicle</InputLabel>
                      <Select
                        labelId="vehicle"
                        id="vehicle"
                        fullWidth
                        label="Vehicle"
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        onChange={(e) =>
                          setFormData({ ...formData, vehicle: e.target.value })
                        }
                        value={formData.vehicle}
                        size="small"
                      >
                        {vehicles.map((item, index) => (
                          <MenuItem key={index} value={item.ID}>
                            {item.REGISTRATION_NO}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div>
                    <TextField
                      fullWidth
                      id="contactPerson"
                      value={
                        formData.contactPerson ? formData.contactPerson : ""
                      }
                      label="Contact Person"
                      onChange={(e) => {}}
                      variant="outlined"
                      size="small"
                      sx={inputCustomStyle}
                    />
                  </div>

                  <div>
                    <TextField
                      fullWidth
                      id="deliveryTime"
                      value="2 mins ago"
                      label="Delivery Time"
                      onChange={(e) => {}}
                      variant="outlined"
                      disabled
                      size="small"
                      sx={inputCustomStyle}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="my-5">
              <div className="mt-10 flex w-full justify-center rounded-xl bg-[#f6f6f6] py-4 px-4 shadow-xl sm:px-8 md:py-10 md:px-10 lg:mx-1 lg:mt-0 lg:px-10">
                <div className="grid h-48 w-full grid-cols-1 overflow-y-scroll scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 scrollbar-thumb-rounded ">
                  <MaterialReactTable
                    columns={columns}
                    enableTopToolbar={false}
                    data={tableData}
                    muiTablePaperProps={{ sx: { borderRadius: "10px" } }}
                    muiTableContainerProps={{ sx: { borderRadius: "10px" } }}
                    editingMode="table"
                    autoResetPageIndex={false}
                    enableEditing
                    // muiTableBodyCellEditTextFieldProps={({ cell }) => ({
                    //   onBlur: (event) => {
                    //     if (cell.row.index == rowIndex) {
                    //       setTableData([
                    //         ...tableData,
                    //         tableData.push({
                    //           itemId: "",
                    //           itemQuantity: "",
                    //           discount: "",
                    //           salePrice: "",
                    //           cylinderType: "",
                    //         }),
                    //       ]);
                    //       setRowIndex(rowIndex + 1);
                    //     }
                    //     handleSaveCell(cell, event.target.value);
                    //   },
                    // })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className=" flex justify-end">
            {props.editingMode ? (
              <div>
                <button
                  onClick={() => {
                    setTableData([{ ...data }]);
                    setFormData({
                      orderType: "Offline",
                      orderStatus: "In Process",
                      cprNumber: customers[0],
                      mobileNumber: customers[0],
                      firstName: customers[0].FIRSTNAME,
                      lastName: customers[0].LASTNAME,
                      date: moment(currentCustomer[0].preferred_times),
                      flat_shopNo: currentCustomer[0],
                      building: currentCustomer[0],
                      road: currentCustomer[0],
                      block: currentCustomer[0],
                      area: currentCustomer[0],
                      vehicle: vehicles[0].ID,
                      contactPerson: currentCustomer[0].contactPerson,
                      items: tableData,
                    });
                    props.disableEditingMode();
                  }}
                  className="mr-2 transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none"
                >
                  Cancel
                </button>
                <button
                  onClick={editConfirmation}
                  className="transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none"
                >
                  Done
                </button>
              </div>
            ) : (
              <button
                onClick={() => setOpen(true)}
                className="mr-2 transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none sm:mr-0"
              >
                Submit
              </button>
            )}
          </div>
        </form>
      </section>
    </Box>
  );
};

export default SaleForm;
