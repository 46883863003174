import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import { object } from "yup";

function HOCReport(props) {
  //console.log(props.apiData, "PROPS");
  // console.log(props.apiData.map((elem) => elem.MOBILE_NUMBER));

  const getReport = () => {
    switch (props.reportName) {
      case "Customer Report":
        return (
          <>
            {props?.apiData?.map((ele) => {
              return (
                <>
                  <View style={styles.categoryCont}>
                    <Text style={styles.mainHeading}>AREA</Text>
                  </View>
                  {ele?.area?.map((elem) => {
                    const renderBoxes = (item) => {
                      const boxes = [];
                      for (
                        let i = 0;
                        i < elem[Object.keys(elem)[0]].length;
                        i++
                      ) {
                        const row = [];
                        for (let j = 0; j < item.length; j++) {
                          row.push(
                            <>
                              <Text style={styles.fontSize}>
                                {item[i].MOBILE_NUMBER}
                              </Text>
                              <Text style={styles.fontSize}>
                                {item[i].NAME}
                              </Text>
                              <Text style={styles.fontSize}>
                                {item[i].ABBREVIATION}
                              </Text>
                              <Text style={styles.fontSize}>{item[i].QTY}</Text>
                              <Text style={styles.fontSize}>
                                {item[i].DELIVERYSTATUS}
                              </Text>{" "}
                              <Text style={styles.fontSize}>
                                {item[i].AREA}
                              </Text>{" "}
                              <Text style={styles.fontSize}>
                                {item[i].HSITORY}
                              </Text>
                            </>
                          );
                        }
                        boxes.push(
                          <View style={styles.customerRowStyle}>
                            <Text style={[styles.fontSize, styles.heading]}>
                              Mobile Number
                            </Text>
                            <Text style={styles.fontSize}>{row}</Text>
                          </View>
                        );
                      }
                      return boxes;
                    };
                    return (
                      <>
                        <View style={styles.categoryCont}>
                          <Text style={styles.heading}>
                            {Object.keys(elem)[0]} :
                          </Text>
                        </View>
                        <View style={styles.gridBody}>
                          {renderBoxes(elem[Object.keys(elem)[0]])}
                        </View>
                        {/* <View style={styles.categoryCont}>
                          <Text style={styles.heading}>
                            {Object.keys(elem)[0]} :
                          </Text>
                        </View>
                          <View style={styles.customerRowStyle}>
                            <Text style={[styles.fontSize, styles.heading]}>
                              Mobile Number
                            </Text>
                            {elem[Object.keys(elem)[0]].map((ee) => (
                              <Text style={styles.fontSize}>
                                {ee.MOBILE_NUMBER}
                              </Text>
                            ))}
                          </View>
                          <View style={styles.customerRowStyle}>
                            <Text style={[styles.fontSize, styles.heading]}>
                              Name
                            </Text>
                            {elem[Object.keys(elem)[0]].map((ee) => (
                              <Text style={styles.fontSize}>{ee.NAME}</Text>
                            ))}
                          </View>
                          <View style={styles.customerRowStyle}>
                            <Text style={[styles.fontSize, styles.heading]}>
                              Abbreviation
                            </Text>
                            {elem[Object.keys(elem)[0]].map((ee) => (
                              <Text style={styles.fontSize}>
                                {ee.ABBREVIATION}
                              </Text>
                            ))}
                          </View>
                          <View style={styles.customerRowStyle}>
                            <Text style={[styles.fontSize, styles.heading]}>
                              Quantity
                            </Text>
                            {elem[Object.keys(elem)[0]].map((ee) => (
                              <Text style={styles.fontSize}>{ee.QTY}</Text>
                            ))}
                          </View>
                          <View style={styles.customerRowStyle}>
                            <Text style={[styles.fontSize, styles.heading]}>
                              Status
                            </Text>
                            {elem[Object.keys(elem)[0]].map((ee) => (
                              <Text style={styles.fontSize}>
                                {ee.DELIVERYSTATUS}
                              </Text>
                            ))}
                          </View>
                          <View style={styles.customerRowStyle}>
                            <Text style={[styles.fontSize, styles.heading]}>
                              Area
                            </Text>
                            {elem[Object.keys(elem)[0]].map((ee) => (
                              <Text style={styles.fontSize}>
                                {ee.AREA || "--"}
                              </Text>
                            ))}
                          </View>
                          <View style={styles.customerRowStyle}>
                            <Text style={[styles.fontSize, styles.heading]}>
                              History
                            </Text>
                            {elem[Object.keys(elem)[0]].map((ee) => (
                              <Text style={styles.fontSize}>{ee.HISTORY}</Text>
                            ))}
                          </View>
                         */}
                      </>
                    );
                  })}
                  <View style={styles.categoryCont}>
                    <Text style={styles.mainHeading}>VEHICLE</Text>
                  </View>
                  {ele?.vehicle?.map((elem) => {
                    const renderBoxes = (item) => {
                      const boxes = [];
                      const row = [];
                      for (let i = 0; i < elem.length; i++) {
                        // row.push(
                        //   <View style={styles.categoryCont}>
                        //     <Text style={styles.heading}>
                        //       {Object.keys(elem[i])[0]} :
                        //     </Text>
                        //   </View>
                        // );
                        // for (let j = 0; j < elem[i].length; j++) {
                        //   row.push(
                        //     <View style={styles.box}>
                        //       <Text>{elem[i][j]}</Text>
                        //     </View>
                        //   );
                        // }
                        // boxes.push(<View>{row}</View>);
                      }
                      //return row;
                    };
                    return (
                      <>
                        <View style={styles.categoryCont}>
                          <Text style={styles.heading}>
                            {Object.keys(elem)[0]} :
                          </Text>
                        </View>
                        <View style={styles.gridBody}>
                          {renderBoxes(elem[Object.keys(elem)[0]])}
                        </View>
                        {/* <View style={styles.categoryCont}>
                          <Text style={styles.heading}>
                            {Object.keys(elem)[0]} :
                          </Text>
                        </View>
                          <View style={styles.customerRowStyle}>
                            <Text style={[styles.fontSize, styles.heading]}>
                              Mobile Number
                            </Text>
                            {elem[Object.keys(elem)[0]].map((ee) => (
                              <Text style={styles.fontSize}>
                                {ee.MOBILE_NUMBER}
                              </Text>
                            ))}
                          </View>
                          <View style={styles.customerRowStyle}>
                            <Text style={[styles.fontSize, styles.heading]}>
                              Name
                            </Text>
                            {elem[Object.keys(elem)[0]].map((ee) => (
                              <Text style={styles.fontSize}>{ee.NAME}</Text>
                            ))}
                          </View>
                          <View style={styles.customerRowStyle}>
                            <Text style={[styles.fontSize, styles.heading]}>
                              Abbreviation
                            </Text>
                            {elem[Object.keys(elem)[0]].map((ee) => (
                              <Text style={styles.fontSize}>
                                {ee.ABBREVIATION}
                              </Text>
                            ))}
                          </View>
                          <View style={styles.customerRowStyle}>
                            <Text style={[styles.fontSize, styles.heading]}>
                              Quantity
                            </Text>
                            {elem[Object.keys(elem)[0]].map((ee) => (
                              <Text style={styles.fontSize}>{ee.QTY}</Text>
                            ))}
                          </View>
                          <View style={styles.customerRowStyle}>
                            <Text style={[styles.fontSize, styles.heading]}>
                              Status
                            </Text>
                            {elem[Object.keys(elem)[0]].map((ee) => (
                              <Text style={styles.fontSize}>
                                {ee.DELIVERYSTATUS}
                              </Text>
                            ))}
                          </View>
                          <View style={styles.customerRowStyle}>
                            <Text style={[styles.fontSize, styles.heading]}>
                              Area
                            </Text>
                            {elem[Object.keys(elem)[0]].map((ee) => (
                              <Text style={styles.fontSize}>
                                {ee.AREA || "--"}
                              </Text>
                            ))}
                          </View>
                          <View style={styles.customerRowStyle}>
                            <Text style={[styles.fontSize, styles.heading]}>
                              History
                            </Text>
                            {elem[Object.keys(elem)[0]].map((ee) => (
                              <Text style={styles.fontSize}>{ee.HISTORY}</Text>
                            ))}
                          </View>
                         */}
                      </>
                    );
                  })}
                </>
              );
              //return <>;
            })}
            {/* <View style={styles.customerRowStyle}>
                <Text style={[styles.fontSize, styles.heading]}>Name</Text>
                {props.apiData.map((elem, i) => (
                  <Text key={i} style={styles.fontSize}>
                    {elem.NAME || "--"}
                  </Text>
                ))}
              </View>
              <View style={styles.customerRowStyle}>
                <Text style={[styles.fontSize, styles.heading]}>Item</Text>
                {props.apiData.map((elem, i) => (
                  <Text key={i} style={styles.fontSize}>
                    {elem.ABBREVIATION || "--"}
                  </Text>
                ))}
              </View>
              <View style={styles.customerRowStyle}>
                <Text style={[styles.fontSize, styles.heading]}>Quantity</Text>
                {props.apiData.map((elem, i) => (
                  <Text key={i} style={styles.fontSize}>
                    {elem.QTY || "--"}
                  </Text>
                ))}
              </View>
              <View style={styles.customerRowStyle}>
                <Text style={[styles.fontSize, styles.heading]}>Status</Text>
                {props.apiData.map((elem, i) => (
                  <Text key={i} style={styles.fontSize}>
                    {elem.QTY || "--"}
                  </Text>
                ))}
              </View>
              <View style={styles.customerRowStyle}>
                <Text style={[styles.fontSize, styles.heading]}>History</Text>
                {props.apiData.map((elem, i) => (
                  <Text key={i} style={styles.fontSize}>
                    {elem.HISTORY || "--"}
                  </Text>
                ))}
              </View> */}
          </>
        );

      default:
        return (
          <>
            <View style={styles.rowStyle}>
              <Text style={[styles.fontSize, styles.heading]}> -- </Text>
              <Text style={styles.fontSize}> -- </Text>
            </View>
            <View style={styles.rowStyle}>
              <Text style={[styles.fontSize, styles.heading]}> -- </Text>
              <Text style={styles.fontSize}> -- </Text>
            </View>
            <View style={styles.rowStyle}>
              <Text style={[styles.fontSize, styles.heading]}> -- </Text>
              <Text style={styles.fontSize}> -- </Text>
            </View>
          </>
        );
    }
  };
  // const numSections = 5;
  // const numDivsPerSection = 5;

  // const sections = [];
  // const renderBoxes = () => {
  //   const boxes = [];
  //   for (let i = 0; i < 5; i++) {
  //     const row = [];
  //     for (let j = 0; j < 5; j++) {
  //       row.push(
  //         <View style={styles.box}>
  //           <Text>ABCD</Text>
  //         </View>
  //       );
  //     }
  //     boxes.push(<View style={styles.row}>{row}</View>);
  //   }
  //   return boxes;
  // };
  return (
    <>
      <View style={styles.gridBodyCont}>
        {/* <View style={styles.container}>{renderBoxes()}</View> */}
        {getReport()}
        {/* <View style={styles.gridBody}>
           <View style={styles.rowStyle} >
            <Text style={[styles.fontSize, styles.heading]}>Mobile</Text>
            <Text style={styles.fontSize}>5121516569</Text>
            <Text style={styles.fontSize}>5151516561</Text>
            <Text style={styles.fontSize}>5131618976</Text>
            <Text style={styles.fontSize}>5131614575</Text>
            <Text style={styles.fontSize}>5131617461</Text>
          </View>
          <View style={styles.rowStyle} >
            <Text style={[styles.fontSize, styles.heading]}>Name</Text>
            <Text style={styles.fontSize}> Ammad</Text>
            <Text style={styles.fontSize}>Ameer Hamza</Text>
            <Text style={styles.fontSize}>Syed Hashir Ali</Text>
            <Text style={styles.fontSize}>Maaz Alam</Text>
            <Text style={styles.fontSize}>Shaad Raza</Text>
          </View>
          <View style={styles.rowStyle} debug={true}>
            <Text style={[styles.fontSize, styles.heading]}>Item</Text>
            <Text style={styles.fontSize}>20 LBS Auto</Text>
            <Text style={styles.fontSize}>40 LBS Manual</Text>
            <Text style={styles.fontSize}>60 LBS Auto</Text>
            <Text style={styles.fontSize}>80 LBS Manual</Text>
            <Text style={styles.fontSize}>100 LBS Manual</Text>
          </View>
          <View style={styles.rowStyle} debug={true}>
            <Text style={[styles.fontSize, styles.heading]}>Quantity</Text>
            <Text style={styles.fontSize}>20</Text>
            <Text style={styles.fontSize}>22</Text>
            <Text style={styles.fontSize}>20</Text>
            <Text style={styles.fontSize}>15</Text>
            <Text style={styles.fontSize}>63</Text>
          </View>
          <View style={styles.rowStyle} debug={true}>
            <Text style={[styles.fontSize, styles.heading]}>History</Text>
            <Text style={styles.fontSize}>80</Text>
            <Text style={styles.fontSize}>83</Text>
            <Text style={styles.fontSize}>85</Text>
            <Text style={styles.fontSize}>83</Text>
            <Text style={styles.fontSize}>180</Text>
          </View> 
        </View> */}
      </View>
      {/* <View style={styles.totalGrpCont}>
        <View style={styles.totalGrpBody}>
          <View style={styles.totalGrpAnalytics}>
            <View style={styles.totalGrpAnalyticsDetails}>
              <View style={styles.taglines}>
                <Text>Price : </Text>
              </View>
              <View style={styles.price}>
                <Text>200$</Text>
              </View>
            </View>
            <View style={styles.totalGrpAnalyticsDetails}>
              <View style={styles.taglines}>
                <Text>Tax : </Text>
              </View>
              <View style={styles.price}>
                <Text>5$</Text>
              </View>
            </View>
          </View>
          <View style={styles.seprator}></View>
          <View style={styles.totalGrpAnalytics}>
            <View style={styles.totalGrpAnalyticsDetails}>
              <View style={styles.taglines}>
                <Text>Total : </Text>
              </View>
              <View style={styles.price}>
                <Text>205$</Text>
              </View>
            </View>
          </View>
        </View>
      </View> */}
      <View style={styles.reportSepratorCont}>
        <View style={styles.reportSeprator}></View>
      </View>
      <View style={styles.reportDetailsCont}>
        <View style={styles.reportDetailsBodyCol}>
          <View style={styles.reportDetailsBody}>
            <View style={styles.reportDetailsRow}>
              <Text>Printed On : </Text>
              <Text>{moment(new Date()).format("lll")}</Text>
            </View>
            <View style={styles.reportDetailsRow}>
              <Text>Report # </Text>
              <Text>001100</Text>
            </View>
          </View>
          <View style={[styles.reportDetailsRow, { fontSize: 12 }]}>
            <Text>Printed By : </Text>
            <Text>Jonathan</Text>
          </View>
        </View>
      </View>
    </>
  );
}

export default HOCReport;

const styles = StyleSheet.create({
  gridBodyCont: {
    marginVertical: 10,
    width: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  gridBody: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 20,
  },
  fontSize: {
    fontSize: 12,
    paddingHorizontal: 10,
    textAlign: "center",
    paddingVertical: 10,
    borderWidth: 1,
  },
  totalGrpCont: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginVertical: 15,
  },
  totalGrpBody: {
    width: "40%",
  },
  seprator: {
    height: 1,
    marginVertical: 2,
    backgroundColor: "#000",
    width: "100%",
  },
  totalGrpAnalytics: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 10,
  },
  totalGrpAnalyticsDetails: {
    flexDirection: "row",
    marginVertical: 5,
  },
  taglines: {
    width: "50%",
    alignItems: "flex-end",
    fontSize: 12,
    justifyContent: "center",
  },
  price: {
    fontSize: 12,
    width: "50%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  heading: {
    fontSize: 14,
    fontWeight: "bold",
    textDecoration: "underline",
  },
  mainHeading: {
    fontSize: 18,
    fontWeight: "bold",
    textDecoration: "underline",
  },
  reportSepratorCont: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  reportSeprator: {
    height: 1,
    backgroundColor: "#000",
    width: "100%",
  },
  reportDetailsCont: {
    width: "100%",
    marginVertical: 15,
  },
  reportDetailsBodyCol: {
    flexDirection: "column",
  },
  reportDetailsBody: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    fontSize: 12,
    marginVertical: 2,
  },
  reportDetailsRow: {
    flexDirection: "row",
  },
  customerRowStyle: {
    width: `${100 / 7}%`,
  },
  categoryCont: {
    padding: 5,
    marginVertical: 10,
    display: "flex",
    width: "100%",
  },
});
