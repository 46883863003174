import { useContext, useEffect, useRef, useState } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  TextField,
  FormControl,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import reactCSS from "reactcss";
import { useFormik } from "formik";
import * as yup from "yup";
import { SketchPicker } from "react-color";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import API from "API/API";
import { AppContext } from "Context/AppContext";
import Loading from "components/loading";
import PromptModal from "components/promptModal/PromptModal";

const VehicleForm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [colorValue, setColorValue] = useState("#FF0000");
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataToAdd, setDatatoAdd] = useState({});
  const [successModal, setSuccessModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const { userData } = useContext(AppContext);

  const modelRef = useRef(null);
  const TestRef = useRef(null);
  const regNoRef = useRef(null);
  const purchaseAmountRef = useRef(null);
  const weightRef = useRef(null);
  const routeRef = useRef(null);
  const statusRef = useRef(null);
  const dateRef = useRef(null);
  const remarksRef = useRef(null);
  const insuranceDetailsRef = useRef(null);
  const vehiclePassingRef = useRef(null);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const addVehicle = async (values) => {
    setIsLoading(true);
    handleDialogClose();
    try {
      const res = await API().AddVehicle(
        values.make,
        values.date,
        values.model,
        values.registrationNo,
        values.purchaseAmount,
        values.weight,
        values.route,
        values.vehicleStatus,
        values.remarks,
        values.color,
        values.insuranceDetails,
        values.vehiclePassing,
        `${userData.USERNAME} | ${moment(new Date()).format("lll")}`
      );
      if (res.data.status) {
        setFeedbackMessage("Vehicle Added Successfully");
        setSuccessModal(true);
      } else {
        setFeedbackMessage("Error Adding Data");
        setSuccessModal(true);
      }
    } catch (err) {
      setFeedbackMessage("Please Check your Internet");
      setSuccessModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const styles = reactCSS({
    default: {
      color: {
        width: "100%",
        height: "25px",
        borderRadius: "2px",
        background: `${colorValue}`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
        width: "100%",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const inputCustomStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#1b3bbb",
      },
      "& fieldset": {
        borderColor: "#1b3bbb",
      },
      "&:hover fieldset": {
        borderColor: "#1b3bbb",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      backgroundColor: "#f6f6f6",
      margin: 0,
      paddingTop: 0.5,
    },
    background: "#fff",
  };

  const vehicleValidationSchema = yup.object({
    make: yup
      .string()
      .required("Make is Required")
      .min(2, "Min 2 Characters")
      .max(24, "Max 24 Characters"),
    model: yup
      .string()
      .required("Model is Required")
      .min(2, "Min 2 Characters")
      .max(50, "Max 50 Characters"),
    registrationNo: yup
      .string()
      .required("Registration Number is Required")
      .min(2, "Min 2 Characters")
      .max(10, "Max 10 Characters"),
    purchaseAmount: yup
      .number()
      .required("Purchase Amount is Required")
      .min(0.01, "Min 0.01 Value")
      .max(9999999999, "Max 99999999 Value"),
    weight: yup
      .string()
      .required("Weight is Required")
      .min(1, "Min 1 Characters")
      .max(20, "Max 20 Characters"),
  });

  const formik = useFormik({
    initialValues: {
      make: "",
      model: "",
      registrationNo: "",
      purchaseAmount: "",
      shownAmount: "",
      weight: "",
      route: "",
      vehicleStatus: 0,
      date: moment(new Date()),
      remarks: "",
      insuranceDetails: moment(new Date()),
      vehiclePassing: moment(new Date()),
    },
    validationSchema: vehicleValidationSchema,
    onSubmit: (values) => {
      setDatatoAdd(values);
      setOpen(true);
    },
  });

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        className={`absolute w-full px-10 ${
          isLoading ? "flex" : "hidden"
        } z-50 h-full flex-col items-center justify-center backdrop-blur-sm`}
      >
        <Loading />
      </div>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm your intention to Save ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Add this Vehicle
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              addVehicle(dataToAdd);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <PromptModal
        ModalState={successModal}
        feedbackMessage={feedbackMessage}
        onClick={() => {
          setSuccessModal(false);
          props.callVehicleDB();
        }}
      />
      <section className="w-full max-w-5xl rounded-t-2xl border border-gray-300 bg-[#EBECF0] p-6 shadow-2xl dark:bg-navy-800 md:p-16">
        <div className="flex w-full justify-center rounded-xl bg-[#f6f6f6] py-4 px-1 shadow-xl sm:px-4 md:py-10 md:px-10 lg:px-16">
          <form className="w-full" onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-1 gap-10 px-4 py-4 sm:grid-cols-2 lg:mt-4 lg:grid-cols-3 lg:gap-y-14 lg:px-10">
              <div>
                <TextField
                  fullWidth
                  id="make"
                  name="make"
                  label="Make"
                  value={formik.values.make}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.make && Boolean(formik.errors.make)}
                  helperText={formik.touched.make && formik.errors.make}
                  autoFocus
                  variant="outlined"
                  size="small"
                  sx={inputCustomStyle}
                  inputProps={{
                    maxLength: 24,
                    onKeyPress: (event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.preventDefault();
                        modelRef.current.focus();
                      }
                    },
                  }}
                />
              </div>

              <div>
                <TextField
                  fullWidth
                  inputRef={modelRef}
                  id="model"
                  name="model"
                  label="Model"
                  value={formik.values.model}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.model && Boolean(formik.errors.model)}
                  helperText={formik.touched.model && formik.errors.model}
                  variant="outlined"
                  size="small"
                  sx={inputCustomStyle}
                  inputProps={{
                    maxLength: 50,
                    onKeyPress: (event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.preventDefault();
                        regNoRef.current.focus();
                      }
                    },
                  }}
                />
              </div>

              <div>
                <TextField
                  fullWidth
                  id="registrationNo"
                  label="Registration No."
                  name="registrationNo"
                  value={formik.values.registrationNo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.registrationNo &&
                    Boolean(formik.errors.registrationNo)
                  }
                  helperText={
                    formik.touched.registrationNo &&
                    formik.errors.registrationNo
                  }
                  variant="outlined"
                  inputRef={regNoRef}
                  size="small"
                  sx={inputCustomStyle}
                  inputProps={{
                    maxLength: 10,
                    onKeyPress: (event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.preventDefault();
                        purchaseAmountRef.current.focus();
                      }
                    },
                  }}
                />
              </div>

              <div>
                <TextField
                  //type="number"
                  //inputRef={TestRef}
                  fullWidth
                  inputRef={purchaseAmountRef}
                  id="purchaseAmount"
                  label="Purchase Amount"
                  name="purchaseAmount"
                  //value={testAmount}
                  value={formik.values.shownAmount}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "purchaseAmount",
                      parseInt(e.target.value.replaceAll(",", "")),
                      false
                    );
                    formik.setFieldValue(
                      "shownAmount",
                      parseInt(
                        e.target.value.replaceAll(",", "")
                      ).toLocaleString(),
                      false
                    );
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.purchaseAmount &&
                    Boolean(formik.errors.purchaseAmount)
                  }
                  helperText={
                    formik.touched.purchaseAmount &&
                    formik.errors.purchaseAmount
                  }
                  variant="outlined"
                  size="small"
                  sx={inputCustomStyle}
                  inputProps={{
                    maxLength: 8,
                    onKeyPress: (event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.preventDefault();
                        weightRef.current.focus();
                      }
                    },
                  }}
                />
              </div>

              <div>
                <TextField
                  fullWidth
                  id="weight"
                  inputRef={weightRef}
                  label="Weight"
                  name="weight"
                  value={formik.values.weight}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.weight && Boolean(formik.errors.weight)}
                  helperText={formik.touched.weight && formik.errors.weight}
                  variant="outlined"
                  size="small"
                  sx={inputCustomStyle}
                  inputProps={{
                    maxLength: 20,
                    onKeyPress: (event) => {
                      const { key } = event;
                      if (key === "Enter") {
                        event.preventDefault();
                        routeRef.current.focus();
                      }
                    },
                  }}
                />
              </div>

              <div>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Insurance Details"
                    format="DD-MM-YYYY"
                    inputRef={insuranceDetailsRef}
                    name="insuranceDetails"
                    value={formik.values.insuranceDetails}
                    onChange={(value) =>
                      formik.setFieldValue("insuranceDetails", value, true)
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#1b3bbb",
                        },
                        "& fieldset": {
                          borderColor: "#1b3bbb",
                        },
                        "&:hover fieldset": {
                          borderColor: "#1b3bbb",
                        },
                      },
                      "& label.Mui-focused": {
                        color: "#24388a",
                      },
                      background: "#fff",
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        onKeyPress: (event) => {
                          const { key } = event;
                          if (key === "Enter") {
                            event.preventDefault();
                            vehiclePassingRef.current.focus();
                          }
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>

              <div>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD-MM-YYYY"
                    label="Vehicle Passing"
                    name="vehiclePassing"
                    inputRef={vehiclePassingRef}
                    value={formik.values.vehiclePassing}
                    onChange={(value) =>
                      formik.setFieldValue("vehiclePassing", value, true)
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#1b3bbb",
                        },
                        "& fieldset": {
                          borderColor: "#1b3bbb",
                        },
                        "&:hover fieldset": {
                          borderColor: "#1b3bbb",
                        },
                      },
                      "& label.Mui-focused": {
                        color: "#24388a",
                      },
                      background: "#fff",
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>

              <FormControl
                fullWidth
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#1b3bbb",
                    },
                    "& fieldset": {
                      borderColor: "#1b3bbb",
                    },
                    "&:hover fieldset": {
                      borderColor: "#1b3bbb",
                    },
                  },
                  "& label.Mui-focused": {
                    color: "#24388a",
                  },
                  background: "#fff",
                }}
              >
                <InputLabel id="status-type">Vehicle Status</InputLabel>
                <Select
                  labelId="status-type"
                  inputRef={statusRef}
                  id="status"
                  label="Vehicle Status"
                  name="vehicleStatus"
                  value={formik.values.vehicleStatus}
                  onChange={formik.handleChange}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                >
                  <MenuItem value={0}>Active</MenuItem>
                  <MenuItem value={1}>Inactive</MenuItem>
                </Select>
              </FormControl>

              <div className="flex w-full items-center justify-center">
                <div className="mr-4 text-base font-medium text-navy-600">
                  Color:
                </div>
                <div style={styles.swatch} onClick={handleClick}>
                  <div style={styles.color} />
                </div>
                {displayColorPicker ? (
                  <div style={styles.popover}>
                    <div style={styles.cover} onClick={handleClose} />
                    <SketchPicker
                      color={colorValue}
                      onChange={(value) => {
                        setColorValue(value.hex);
                        formik.setFieldValue("color", value.hex, false);
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="mt-6 flex justify-end">
              <button
                type="submit"
                className="mr-2 transform rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none sm:mr-0"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </Box>
  );
};

export default VehicleForm;
