import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import moment from "moment";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ReactPDFView from "../../ReactPDF/ReactPDF";
import Report from "../../reports";
import { Link } from "react-router-dom";
import API from "API/API";
import AbuSaadLogo from "assets/img/layout/ABUSAADLOGO.png";

const CustomerReport = () => {
  const [logistics, setLogistics] = useState([]);
  const [currentAreas, setCurrentAreas] = useState([]);
  const [reportResult, setReportResult] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [formData, setFormData] = useState({
    filterBy: "",
    status: "",
    area: "",
    vehicle: "",
  });
  const [reportParams, setReportParams] = useState({
    "Customer Filter": "",
    Status: "",
    Area: "",
    Vehicle: "",
  });

  useEffect(() => {
    if (formData.days && formData.filterBy == "Last Delivery") {
      setReportParams({
        "Customer Filter": formData.filterBy,
        Status: formData.status,
        Area: formData?.area?.DELIVERY_AREA || formData?.area,
        Vehicle: formData?.vehicle.REGISTRATION_NO || formData.vehicle,
        Days: formData?.days,
      });
    } else {
      setReportParams({
        "Customer Filter": formData?.filterBy,
        Status: formData?.status,
        Area: formData?.area?.DELIVERY_AREA || formData?.area,
        Vehicle: formData?.vehicle.REGISTRATION_NO || formData.vehicle,
      });
    }
  }, [formData]);

  const FetchLogistic = async () => {
    try {
      const result = await API().GetLogistic();
      if (result.data.status) {
        setLogistics(result.data.data);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const FetchAPI = async () => {
    setButtonDisable(true);
    try {
      const res = await API().CustomerReport(
        formData.filterBy,
        formData?.days || 0,
        formData.status,
        formData.vehicle.FK_VEHICLEID || formData.vehicle,
        formData.area
      );
      if (res.data.status) {
        setReportResult(true);
        setApiData(res.data.data);
      } else {
      }
    } catch (err) {
      console.log(err);
    } finally {
      setButtonDisable(false);
    }
  };

  useEffect(() => {
    FetchLogistic();
  }, []);
  const inputCustomStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#1b3bbb",
      },
      "& fieldset": {
        borderColor: "#1b3bbb",
      },
      "&:hover fieldset": {
        borderColor: "#1b3bbb",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    background: "#fff",
  };
  const dateCustomStyle = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#1b3bbb",
      },
      "& fieldset": {
        borderColor: "#1b3bbb",
      },
      "&:hover fieldset": {
        borderColor: "#1b3bbb",
      },
    },
    "& label.Mui-focused": {
      color: "#24388a",
    },
    background: "#fff",
  };
  return (
    <div className="-mt-5 flex min-h-[95vh] w-full flex-col items-center rounded-b-2xl bg-white px-4">
      <h2 className="text-black flex w-full max-w-5xl py-5 text-3xl font-bold capitalize dark:text-white">
        Customer Report
      </h2>
      <section className="w-full max-w-5xl rounded-t-2xl border border-gray-300 bg-[#EBECF0] p-6 shadow-2xl dark:bg-navy-800 md:px-16 md:py-10">
        <form className="w-full" onSubmit={(e) => e.preventDefault()}>
          <div className="flex w-full flex-col">
            <div className="flex w-full justify-center rounded-xl bg-[#f6f6f6] py-4 px-1 shadow-xl sm:px-4 md:py-5 md:px-10 lg:px-8">
              <div className="grid w-full grid-cols-1 gap-10 px-4 py-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-y-8 lg:px-1">
                <div>
                  <FormControl
                    disabled={reportResult ? true : false}
                    fullWidth
                    size="small"
                    sx={inputCustomStyle}
                  >
                    <InputLabel id="customer-filter">
                      Customer Filter
                    </InputLabel>
                    <Select
                      labelId="customer-filter"
                      id="filterBy"
                      value={formData.filterBy}
                      onChange={(e) => {
                        if (e.target.value == "All") {
                          return setFormData({
                            ...formData,
                            filterBy: e.target.value,
                            status: e.target.value,
                            area: e.target.value,
                            vehicle: e.target.value,
                          });
                        }
                        setFormData({
                          ...formData,
                          filterBy: e.target.value,
                        });
                      }}
                      fullWidth
                      label="Customer Filter"
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      size="small"
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="Last Delivery">Last Delivery</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                {formData.filterBy == "Last Delivery" && (
                  <div>
                    <TextField
                      disabled={reportResult ? true : false}
                      fullWidth
                      id="days"
                      label="Days"
                      value={formData.days}
                      onChange={(e) =>
                        setFormData({ ...formData, days: e.target.value })
                      }
                      variant="outlined"
                      size="small"
                      sx={inputCustomStyle}
                    />
                  </div>
                )}

                <div>
                  <FormControl
                    disabled={reportResult ? true : false}
                    fullWidth
                    size="small"
                    sx={inputCustomStyle}
                  >
                    <InputLabel id="status">Status</InputLabel>
                    <Select
                      labelId="status"
                      id="status"
                      value={formData.status}
                      onChange={(e) =>
                        setFormData({ ...formData, status: e.target.value })
                      }
                      fullWidth
                      label="Sort By"
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      size="small"
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="Delivered">Delivered</MenuItem>
                      <MenuItem value="In Process">In Process</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div>
                  <FormControl
                    disabled={reportResult ? true : false}
                    fullWidth
                    size="small"
                    sx={inputCustomStyle}
                  >
                    <InputLabel id="vehicles">Vehicles</InputLabel>
                    <Select
                      labelId="vehicles"
                      id="vehicles"
                      value={formData.vehicle}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setCurrentAreas(e.target.value?.DELIVERY_AREA);
                        setFormData({
                          ...formData,
                          vehicle: e.target.value,
                        });
                      }}
                      fullWidth
                      label="Vehicles"
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      size="small"
                    >
                      <MenuItem value="All">All</MenuItem>
                      {logistics.map((e, i) => (
                        <MenuItem key={i} value={e}>
                          {e.REGISTRATION_NO}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div>
                  <FormControl
                    disabled={reportResult ? true : false}
                    fullWidth
                    size="small"
                    sx={inputCustomStyle}
                  >
                    <InputLabel id="area">Areas</InputLabel>
                    <Select
                      labelId="area"
                      id="area"
                      value={formData.area}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          area: e.target.value,
                        })
                      }
                      fullWidth
                      label="Areas"
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      size="small"
                    >
                      <MenuItem value="All">All</MenuItem>
                      {formData.vehicle != "All" &&
                        currentAreas.map((e, i) => (
                          <MenuItem key={i} value={e}>
                            {e.DELIVERY_AREA}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <div className="mt-10 flex w-full items-center justify-end">
                {!reportResult && (
                  <button
                    disabled={buttonDisable ? true : false}
                    onClick={FetchAPI}
                    className="transform cursor-pointer rounded-md bg-navy-600 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-navy-700 focus:bg-navy-500 focus:outline-none disabled:bg-opacity-80"
                  >
                    Generate Report
                  </button>
                )}
                {reportResult && (
                  <>
                    <div className="mr-5">
                      <ReactPDFView
                        reportName={"Customer-Report"}
                        sendEmail={true}
                        children={
                          <Report
                            apiData={apiData}
                            reportName={"Customer Report"}
                            paramsData={reportParams}
                          />
                        }
                        buttonText="Send Via Email"
                      />
                    </div>
                    <ReactPDFView
                      reportName={"Customer-Report"}
                      sendEmail={false}
                      children={
                        <Report
                          apiData={apiData}
                          reportName={"Customer Report"}
                          paramsData={reportParams}
                        />
                      }
                      buttonText="Generate PDF"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default CustomerReport;
