import RefillForm from "./components/RefillForm";
import DocketGrid from "./components/RefillGrid";
import { useState, useEffect } from "react";
import { Backdrop } from "@mui/material";
import API from "API/API";
import Loading from "components/loading";
import moment from "moment";
import { MdOutlineRefresh } from "react-icons/md";

const Purchase = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [refills, setRefills] = useState([]);
  const [items, setItems] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [errorState, setErrorState] = useState(false);
  const [vendorRates, setVendorRates] = useState([]);
  const [editingMode, setEditingMode] = useState(false);
  const [formData, setFormData] = useState({
    deliveryDocketNo: "",
    vendorId: "",
    date: moment(new Date()),
    lpoRef: "",
    vehicleNo: "",
    items: [],
  });

  const FetchRefill = async () => {
    try {
      let result1 = new Promise((resolve, reject) => {
        resolve(API().GetDockets());
      });
      let result2 = new Promise((resolve, reject) => {
        resolve(API().GetItems());
      });
      let result3 = new Promise((resolve, reject) => {
        resolve(API().GetVehicle());
      });
      let result4 = new Promise((resolve, reject) => {
        resolve(API().GetVendor());
      });
      let result5 = new Promise((resolve, reject) => {
        resolve(API().GetVendorRates());
      });
      Promise.all([result1, result2, result3, result4, result5])
        .then((e) => {
          if (
            e[0].data.status &&
            e[1].data.status &&
            e[2].data.status &&
            e[3].data.status &&
            e[4].data.status
          ) {
            setRefills(e[0].data.data);
            setItems(e[1].data.data);
            setVehicles(e[2].data.data);
            setVendors(e[3].data.data);
            setVendorRates(e[4].data.data);
          } else {
            setErrorState(true);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setErrorState(true);
          setIsLoading(false);
        });
    } catch (err) {
      setErrorState(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    FetchRefill();
  }, []);

  const handleRowEdit = (row) => {
    setEditingMode(false);
    setFormData({
      deliveryDocketNo: row.deliveryDocketNo,
      vendorId: row.vendorId,
      date: moment(row.date),
      lpoRef: row.lpoRef,
      vehicleNo: row.vehicleNo,
      items: row.items,
    });
    setTimeout(() => {
      setEditingMode(true);
    }, 1000);
  };

  useEffect(() => {}, [formData]);

  const emptyFieldsHandler = () => {
    setFormData({
      deliveryDocketNo: "",
      vendorId: "",
      date: moment(new Date()),
      lpoRef: "",
      vehicleNo: "",
      items: [],
    });
    setEditingMode(false);
  };

  return (
    <>
      {isLoading ? (
        <>
          <Backdrop
            sx={{
              color: "#fff",
              background: "#F4F7FE",
              position: "relative",
              zIndex: 5,
              height: "100vh",
            }}
            open={isLoading}
          >
            <Loading />
          </Backdrop>
        </>
      ) : (
        <>
          {errorState ? (
            <div className="flex h-[84vh] flex-col items-center justify-center">
              <span
                className="text-navy-600"
                onClick={() => {
                  setIsLoading(true);
                  setErrorState(false);
                  FetchRefill();
                }}
              >
                <MdOutlineRefresh className="h-28 w-28 -rotate-90 cursor-pointer hover:animate-spin" />
              </span>
              <p className="text-2xl font-bold text-navy-600">
                Server Error, Please Refresh
              </p>
            </div>
          ) : (
            <div className="-mt-5 flex w-full flex-col items-center rounded-b-2xl bg-white px-4">
              {/* <Form /> */}
              <h2 className="text-black flex w-full max-w-5xl py-5 text-3xl font-bold capitalize dark:text-white">
                Docket
              </h2>
              <RefillForm
                formData={formData}
                vehicleList={vehicles}
                callREfillDB={FetchRefill}
                itemList={items}
                vendorList={vendors}
                editingMode={editingMode}
                disableEditingMode={emptyFieldsHandler}
              />
              <div className="mt-2 w-full max-w-5xl overflow-hidden rounded-b-2xl border border-navy-500  shadow-xl">
                <DocketGrid
                  handleRowEdit={handleRowEdit}
                  vendorList={vendors}
                  vehicleList={vehicles}
                  callREfillDB={FetchRefill}
                  gridData={refills}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default Purchase;
