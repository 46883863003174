import React from "react";
import moment from "moment";

const AdvanceOrderCard = (props) => {
  return (
    <>
      {props?.data?.map((data, index) => (
        <div
          key={index}
          className="grid w-full grid-cols-5  items-start bg-white py-3 pl-3 hover:shadow-2xl dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700"
        >
          <div className="flex items-center gap-3">
            <h5 className="text-xs font-bold text-navy-700 dark:text-white">
              {data.FK_VEHICLEID}
            </h5>
          </div>
          <div className=" flex items-center justify-center text-gray-700 dark:text-white">
            <div className=" flex w-full items-start text-xs font-normal ">
              <p>{data.CUSTOMERNAME}</p>
            </div>
          </div>
          <div className=" flex items-center justify-center text-navy-700 dark:text-white">
            <div className=" flex items-center text-xs font-normal ">
              <p>{data.ADDRESS || "--"}</p>
            </div>
          </div>
          <div className=" flex items-center justify-center text-navy-700 dark:text-white">
            <div className=" flex items-center text-xs font-normal ">
              {moment(data.LASTORDER).format("ll")}
            </div>
          </div>
          <div className=" flex items-center justify-end text-navy-700 dark:text-white">
            <div className=" flex items-center text-xs font-normal ">
              <p>{data.MOBILE_NUMBER}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default AdvanceOrderCard;
